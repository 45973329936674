import { Component, Input, OnInit } from '@angular/core';
import { User } from '@cots/common/resources/user';
import { OrderStatus } from '@cots/order/resources/order';

@Component({
  selector: 'summary-step-data',
  templateUrl: './summary-step-data.component.html',
  styleUrls: ['./summary-step-data.component.less']
})
export class SummaryStepDataComponent implements OnInit {

  @Input() user: User;
  @Input() status: number;

  @Input() getStatusColorClass: (s: number) => string = OrderStatus.getStatusColorClassChip;
  @Input() getStatusText: (s: number) => string = OrderStatus.getStatusText;

  constructor() { }

  ngOnInit(): void {
  }

}
