<div class="position-fixed d-flex" style="bottom: 25px; right: 20px; z-index: 1030">
  <button mat-fab class="bg-danger text-white mr-2" (click)="cancel()" *ngIf="!saving">
    <mat-icon>close</mat-icon>
  </button>
  <button mat-fab color="error" class="extended-fab-button bg-success text-white"
    *ngIf="stepper.selectedIndex == 3 && this.allVerified && !saving" (click)="complete()">
    <mat-icon>check</mat-icon>
    <span class="extended-fab-button__text">Complete</span>
  </button>
</div>


<loading *ngIf="saving" content="Saving..."></loading>

<mat-stepper orientation="vertical" [linear]="true" #stepper (selectionChange)="onStepChange($event)">>
  <mat-step label="Enter PO details" [stepControl]="detailsForm.form">
    <div class="row m-0 w-100" ngForm #detailsForm="ngForm">
      <div class="col-6">
        <mat-card class="border rounded mat-elevation-z0 w-100" style="cursor: unset;"
          (dragenter)="onDragEnter($event)"
          (dragleave)="onDragLeave($event)"
          (dragover)="$event.preventDefault()"
          (drop)="onDrop($event)"
        >
          <mat-card-title>PO details</mat-card-title>
          <div class="mat-card-content">
            <div class="w-100">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Customer</mat-label>
                <input matInput [value]="record.customer.businessName" type="text" placeholder="PO Number" readonly>
              </mat-form-field>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="w-100">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>PO Number</mat-label>
                    <input matInput name="poNumber" [(ngModel)]="poNumber" type="text" required placeholder="PO Number">
                  </mat-form-field>
                </div>
              </div>
              <div class="col-6">
                <mat-form-field appearance="outline" class="w-100"
                  (click)="openFileInput(); fileNameModel?.control?.markAsTouched()"
                  [class.mat-focused]="dragging"
                  >
                  <mat-label>Purchase Order Document</mat-label>
                  <input matInput type="text" [value]="poFile?.name" style="pointer-events: none; opacity: 0;" required
                    placeholder="Purcahse Order Document" #filenameinput ngModel name="filename" #fileNameModel="ngModel"
                    (focus)="filenameinput.blur()">
                  <div [class.invisible]="!poFile" style="    width: 100%;
                                    text-overflow: ellipsis;
                                    overflow: hidden;position: absolute;
                                    left: 0;
                          top: 14px;">
                    {{ poFile?.name }}
                  </div>
                  <button mat-flat-button matSuffix class="bg-primary text-white"
                    style="position: relative;bottom: 7px;">Browse</button>
                </mat-form-field>
                <input type="file" style="display: none" #fileInput (change)="onFileChange()">
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <mat-form-field appearance="outline" class="w-100 hidden-select">
                  <mat-label>Payment Terms</mat-label>
                  <mat-select [value]="123" disabled>
                    <mat-select-trigger class="d-flex align-items-center mr-1">
                      <mat-icon matPrefix>help</mat-icon>
                      Unknown
                    </mat-select-trigger>
                    <mat-option disabled [value]="123" class="text-muted">
                      <mat-icon matPrefix>help</mat-icon>
                      Unknown
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-6 d-flex align-items-center">
                <mat-checkbox>Special Payment Terms (COD etc.)</mat-checkbox>
              </div>
            </div>
          </div>
          <mat-card-title>Ship To Address</mat-card-title>
          <mat-card-content>
            <div class="row" *ngIf="!usingCustomAddress">
              <div class="col-8">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Ship To Address</mat-label>
                  <mat-select class="w-100" placeholder="Ship To Address" name="shipToAddress" [(ngModel)]="shipToAddress"
                    required>
                    <mat-select-trigger>
                      {{ shipToAddress?.nickname || '' }}
                      <span class="ml-2 text-muted small" *ngIf="shipToAddress">
                        {{ shipToAddress.streetAddress }}<span *ngIf="shipToAddress.streetAddress2">, {{
                          shipToAddress.streetAddress2 }}</span>, {{ shipToAddress.city }}, {{ shipToAddress.state }} {{
                        shipToAddress.postalCode }}
                      </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let ad of record.customer.customerAddresses" [value]="ad.address">
                      {{ ad.address.nickname }}
                      <span class="ml-2 text-muted small">
                        {{ ad.address.streetAddress }}<span *ngIf="ad.address.streetAddress2">, {{ ad.address.streetAddress2
                          }}</span>, {{ ad.address.city }}, {{ ad.address.state }} {{ ad.address.postalCode }}
                      </span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-4">
                <button mat-flat-button class="bg-primary text-white" style="height: 67%; margin-top: 3px;"
                  (click)="enableCustomAddress()">
                  <mat-icon matPrefix class="mr-1">edit</mat-icon>
                  Custom Address
                </button>
              </div>
            </div>
            <ng-container *ngIf="!usingCustomAddress">
              <div style="white-space: pre-wrap" *ngIf="shipToAddress">
                <div>{{ record.customer.businessName }}</div>
                <div>{{ shipToAddress.streetAddress }}</div>
                <div>{{ shipToAddress.streetAddress2 }}</div>
                <div>{{ shipToAddress.city }}, {{ shipToAddress.state }} {{ shipToAddress.postalCode }}</div>
                <div [class.invisible]="!shipToAddress.phoneNumber">Tel. {{ shipToAddress.phoneNumber }}</div>
                <div [class.invisible]="!shipToAddress.faxNumber">Fax: {{ shipToAddress.faxNumber }}</div>
              </div>
              <div style="white-space: pre-wrap" *ngIf="!shipToAddress">
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
              </div>
            </ng-container>
            <div class="row p-0" *ngIf="usingCustomAddress">
              <div class="col-7">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Address Line 1</mat-label>
                  <input matInput type="text" name="customStreetAddress" [(ngModel)]="shipToAddress.streetAddress" required
                    autofocus>
                </mat-form-field>
              </div>
              <div class="col-5">
                <button mat-flat-button class="bg-primary text-white" style="height: 74%;" (click)="disableCustomAddress()"
                  tabindex="99">
                  <mat-icon matPrefix class="mr-1">format_list_bulleted</mat-icon>
                  Use Existing Address
                </button>
              </div>
              <div class="col-12">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Address Line 2</mat-label>
                  <input matInput type="text" name="customStreetAddress2" [(ngModel)]="shipToAddress.streetAddress2">
                </mat-form-field>
              </div>
              <div class="col-12">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>City</mat-label>
                  <input matInput type="text" name="customCity" [(ngModel)]="shipToAddress.city" required>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>State</mat-label>
                  <input matInput type="text" name="customState" [(ngModel)]="shipToAddress.state" required>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Postal Code</mat-label>
                  <input matInput type="text" name="customZip" [(ngModel)]="shipToAddress.postalCode" required>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Phone Number</mat-label>
                  <input matInput type="text" name="customPhone" [(ngModel)]="shipToAddress.phoneNumber" required>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Fax Number</mat-label>
                  <input matInput type="text" name="customFax" [(ngModel)]="shipToAddress.faxNumber" required>
                </mat-form-field>
              </div>
            </div>          </mat-card-content>
          <mat-card-actions align="end">
            <button mat-stroked-button matStepperNext [disabled]="detailsForm.form.invalid">Next</button>
          </mat-card-actions>
        </mat-card>
      </div>
      <div class="col-6">
        <div class="border rounded w-100 h-100-t">
          <mat-card-title class="p-3" [class.text-danger]="poTypeModel.touched && poTypeModel.errors?.required">
            PO type
            <span style="font-size: 14px;" *ngIf="poTypeModel.touched && poTypeModel.errors?.required">(PO type is required.)</span>
          </mat-card-title>
          <div class="py-2">
            <mat-selection-list [multiple]="false" class="po-type-list" [(ngModel)]="poType" name="poType" required #poTypeModel="ngModel">
              <mat-list-option [value]="0" *ngIf="posForRelease.length === 0">
                <mat-pseudo-checkbox class="mr-2"
                  [state]="poType === 0 ? 'checked' : 'unchecked'"
                ></mat-pseudo-checkbox>
                Standard PO <span class="mat-muted ml-1">(will close quote)</span>
              </mat-list-option>
              <mat-list-option [value]="1" *ngIf="posForRelease.length === 0">
                <mat-pseudo-checkbox class="mr-2"
                  [state]="poType === 1 ? 'checked' : 'unchecked'"
                ></mat-pseudo-checkbox>

                Blanket Order <span class="mat-muted ml-1">(fixed quantity releases)</span>
              </mat-list-option>
              <mat-list-option [value]="2" *ngIf="posForRelease.length === 0">
                <mat-pseudo-checkbox class="mr-2"
                  [state]="poType === 2 ? 'checked' : 'unchecked'"
                ></mat-pseudo-checkbox>

                LTA<span class="mat-muted ml-1">(variable quantity releases)</span>
              </mat-list-option>
              <mat-list-option [value]="3" *ngIf="posForRelease.length > 0">
                <mat-pseudo-checkbox class="mr-2"
                  [state]="poType === 3 ? 'checked' : 'unchecked'"
                ></mat-pseudo-checkbox>
                <div class="mr-2">Release for:</div>
                <clientside-search
                  [items]="posForRelease" [(ngModel)]="releaseForPO" name="releaseForPO" [dense]="true" [placeholder]="posForRelease.length === 0 ? 'No blanket or LTA orders available.' : 'Select Previous PO'"
                  [getSearchField]="this.getPurchaseOrderNumber.bind(this)"
                  [required]="poType === 3" [disabled]="poType !== 3"
                >
                </clientside-search>
              </mat-list-option>
            </mat-selection-list>
          </div>
        </div>
      </div>
    </div>
  </mat-step>
  <mat-step label="Enter line item information" [completed]="!this.lineItemBuilder?.hasErrors">
    <po-line-item-builder #lineItemBuilder [record]="record" [(poLineItems)]="poLineItems" [poType]="poType" [releaseForPO]="releaseForPO"></po-line-item-builder>
    <div class="w-100 py-2 d-flex align-items-center justify-content-end" align="end">
      <mat-icon class="text-danger" [class.invisible]="!this.lineItemBuilder.hasWarnings"
        matTooltip="Warnings detected. Please check all quoted and ordered items." matTooltipPosition="before">
        report
      </mat-icon>
      <button mat-stroked-button matStepperPrevious class="ml-2">Back</button>
      <button mat-stroked-button matStepperNext class="ml-2" [disabled]="this.lineItemBuilder?.hasErrors">
        Next
      </button>
    </div>
  </mat-step>
  <mat-step label="PO Exceptions checklist">
    <mat-card class="border rounded mat-elevation-z0 w-100 p-0">
      <mat-tab-group class="vertical-tabs w-100" style="height: 100%;">
        <mat-tab *ngFor="let poli of this.poLineItems">
          <ng-template mat-tab-label>
            <mat-icon *ngIf="!poli.lineItemStation" matPrefix class="mr-2">
              settings
            </mat-icon>
            <mat-icon *ngIf="poli.lineItemStation" matPrefix class="mr-2">
              description
            </mat-icon>
            <b>{{
              printName(poli)
              }}</b>
            <span class="badge ml-1 bg-dark text-white">
              {{ getExceptionCount(poli) }}
            </span>
          </ng-template>

          <mat-selection-list (selectionChange)="exceptionChange($event, poli, 'estimatingSheet')">
            <div class="border-bottom mb-2 w-100">

              <table class="w-50 mat-table">
                <tbody>
                  <tr class="mat-row">
                    <td class="font-weight-bold mat-cell border-0 text-muted text-right pr-5">
                      #{{ poli.lineNumber }}.
                    </td>
                    <td class="mat-cell border-0">
                      Qty <b>{{ poli.quantity }}</b>
                    </td>
                    <td class="px-2 font-weight-bold mat-cell border-0">
                      {{
                      printName(poli)
                      }}
                    </td>
                    <td class="mat-cell border-0"> Unit Price <b>{{poli.lineItemUnitPrice | currency }}</b> </td>
                    <td class="mat-cell border-0">Ext. Price <b>{{poli.lineItemUnitPrice * (poli.quantity || 0) |
                        currency }}</b> </td>
                    <td class="mat-cell border-0">
                      Due
                      <b>{{ poli.dueDate | date: 'shortDate' }}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <mat-list-option value="rev" checkboxPosition="before">
              PO Part Rev Matches Estimate
            </mat-list-option>
            <mat-list-option value="pricingLotQuantities" checkboxPosition="before">
              Pricing and Lot Quantities Correct
            </mat-list-option>
            <mat-list-option value="documents" checkboxPosition="before">
              All Documents Available
            </mat-list-option>
            <mat-list-option value="estimatedLeadTimeCorrect" checkboxPosition="before">
              Estimated Lead Time Correct
            </mat-list-option>
            <mat-list-option value="longLeadItems" checkboxPosition="before">
              Long Lead Time Items Identified
            </mat-list-option>
            <mat-list-option value="longLeadMaterial" checkboxPosition="before">
              Long Lead Time Material
            </mat-list-option>
            <mat-list-option value="longLeadHardware" checkboxPosition="before">
              Long Lead Time Hardware
            </mat-list-option>
          </mat-selection-list>
        </mat-tab>
      </mat-tab-group>
    </mat-card>
    <div class="w-100 py-2 d-flex align-items-center justify-content-end" align="end">
      <button mat-stroked-button matStepperPrevious class="ml-2">Back</button>
      <button mat-stroked-button matStepperNext class="ml-2">
        Next
      </button>
    </div>
  </mat-step>
  <mat-step label="Double-check data and confirm" #lastStep>
    <mat-card class="border rounded mat-elevation-z0 w-100 p-0" style="cursor: unset">
      <div class="row w-100 ml-0">
        <div class="col-6 p-3 border-right">
          <mat-card-title class="px-3">PO Information</mat-card-title>
          <div class="row m-0">
            <div class="col-6">
              <mat-form-field appearance="outline" class="w-100 hidden-select">
                <mat-label>Customer</mat-label>
                <input matInput [value]="record.customer.businessName" type="text" disabled>
                <mat-checkbox matSuffix [(ngModel)]="verification.customer" style="position: relative; z-index: 20;">
                </mat-checkbox>
              </mat-form-field>
            </div>
            <!-- TODO -->
            <div class="col-6">
              <mat-form-field appearance="outline" class="w-100 hidden-select">
                <mat-label>Payment Terms</mat-label>
                <input matInput value="Unknown" type="text" disabled>
                <mat-checkbox matSuffix [(ngModel)]="verification.terms" style="position: relative; z-index: 20;">
                </mat-checkbox>
              </mat-form-field>
            </div>
            <div class="col-12">
              <h5>Ship To
                <mat-checkbox [(ngModel)]="verification.address" class="ml-5 mt-1"></mat-checkbox>
              </h5>
              <div style="white-space: pre-wrap; margin-left: 0.75em;" class="small" *ngIf="shipToAddress">
                <div>{{ record.customer.businessName }}</div>
                <div>{{ shipToAddress.streetAddress }}</div>
                <div>{{ shipToAddress.streetAddress2 }}</div>
                <div>{{ shipToAddress.city }}, {{ shipToAddress.state }} {{ shipToAddress.postalCode }}</div>
                <div [class.invisible]="!shipToAddress.phoneNumber">Tel. {{ shipToAddress.phoneNumber }}</div>
                <div [class.invisible]="!shipToAddress.faxNumber">Fax: {{ shipToAddress.faxNumber }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 p-0 border-right">
          <mat-card-title class="px-3 pt-3">PO Line Items</mat-card-title>
          <table mat-table [dataSource]="poLineItems" #poLineItemsTable="matTable" class="w-100" ngForm>

            <ng-container matColumnDef="lineNumber">
              <th mat-header-cell *matHeaderCellDef>Line Item #</th>
              <td mat-cell *matCellDef="let item" class="font-weight-bold text-muted text-right pr-5">
                {{ item.lineNumber }}.
              </td>
            </ng-container>

            <ng-container matColumnDef="quantity">
              <th mat-header-cell *matHeaderCellDef>Quantity</th>
              <td mat-cell *matCellDef="let item" class="font-weight-bold">
                {{ item.quantity }}
              </td>
            </ng-container>

            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef>Item</th>
              <td mat-cell *matCellDef="let item" class="font-weight-bold">
                {{
                printName(item)
                }}
              </td>
            </ng-container>

            <ng-container matColumnDef="unitPrice">
              <th mat-header-cell *matHeaderCellDef>Unit Price</th>
              <td mat-cell *matCellDef="let item"> {{item.unitPrice | currency }} </td>
            </ng-container>

            <ng-container matColumnDef="extPrice">
              <th mat-header-cell *matHeaderCellDef>Ext. Price</th>
              <td mat-cell *matCellDef="let item">{{item.unitPrice * (item.quantity || 0) | currency }} </td>
            </ng-container>

            <ng-container matColumnDef="dueDate">
              <th mat-header-cell *matHeaderCellDef>Due Date</th>
              <td mat-cell *matCellDef="let item" class="font-weight-bold">
                {{ item.dueDate | date: 'shortDate' }}
              </td>
            </ng-container>

            <ng-container matColumnDef="verify">
              <th mat-header-cell *matHeaderCellDef style="width: 10%">&nbsp;</th>
              <td mat-cell *matCellDef="let item">
                <mat-checkbox [checked]="verification[item.poLineItemGuid]"
                  (change)="verification[item.poLineItemGuid] = $event.checked" style="position: relative; top: 4px;">
                </mat-checkbox>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="verifyLineItemColumns"></tr>
            <tr mat-row *matRowDef="let item; columns: verifyLineItemColumns;"></tr>
          </table>
        </div>
      </div>
    </mat-card>
  </mat-step>
</mat-stepper>

<ng-template #overrideLeadTimeDialog let-data>
  <h2 matDialogTitle>Override Lead Time Restriction</h2>
  <mat-card-subtitle>{{ data.product.partNumber }} Rev. {{ data.product.revision }}</mat-card-subtitle>
  <mat-dialog-content>
    <p>Please provide notes/reasoning for overriding lead time restrictions on this part..</p>
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Notes/Reasoning</mat-label>
      <textarea matInput [(ngModel)]="data.note" required style="resize: none" rows="3"></textarea>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-stroked-button matDialogClose>Cancel</button>
    <button mat-stroked-button class="border-primary ml-2" [disabled]="!data.note"
      [matDialogClose]="data.note">Confirm</button>
  </mat-dialog-actions>
</ng-template>