import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Optional, Output, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { UtilityService } from '../../../../../common/services/utility.service';
import { MaterialBid } from '../../../../../purchasing/resources/materialBid';
import { UserService } from '../../../../../common/services/user.service';
import { Order } from '../../../../resources/order';
import { OrderDetailService } from '../../order-detail.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import { MaterialBidService } from '../../../../../purchasing/services/material-bid.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { User } from '@sentry/angular-ivy';
1
@Component({
  selector: 'product-quoting',
  templateUrl: './product-quoting.component.html',
  styleUrls: ['./product-quoting.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProductQuotingComponent),
      multi: true
    }
  ]
})
export class ProductQuotingComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input() order: Order;
  @Input() materialId: string = null;
  @Input() stationId: string = null;
  @Input() purchasedItemId: string = null;
  @Input() editable: boolean = false;

  constructor(public userService: UserService, private bidService: MaterialBidService, @Optional() private service: OrderDetailService) { }

  public get shouldShowHistory() {
    return (this.materialId && this.materialId !== UtilityService.emptyGuid) || (this.stationId && this.stationId !== UtilityService.emptyGuid) || (this.purchasedItemId && this.purchasedItemId !== UtilityService.emptyGuid)
  }

  public get itemType() {
    if (this.materialId) return 'Material';
    if (this.stationId) return 'Station';
    if (this.purchasedItemId) return 'Purchased item';
  }

  public value: string;
  private selectedQuoteId$ = new BehaviorSubject<string | null>(null);
  writeValue(item: string): void {
    this.selectedQuoteId$.next(item);
    this.value = item;
  }

  private onChange: (value: string) => void;
  private onTouched: () => void;

  registerOnChange(fn: any): void {
    this.onChange = (value) =>  { this.selectedQuoteId$.next(value); fn(value) };
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  public setSelection(bid: MaterialBid): void {
    this.writeValue(bid.materialBidId);
    this.onChange(bid.materialBidId);
  }

  public isPurchasingManager(): boolean {
    return this.userService.canAccess("PurchasingManager");
  }

  public get filterId() {
    return this.materialId ?? this.purchasedItemId ?? this.stationId;
  }

  private collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
  public showExpired = new BehaviorSubject(false);
  private getQuoteObservable(): Observable<MaterialBid[]>{
    let itemQuotesObservable: Observable<MaterialBid[]>;
    if (!this.service) {
      itemQuotesObservable = this.bidService.searchItem(this.filterId).pipe(map(s => s.results));
    } else {
      itemQuotesObservable = this.service.getItemQuotesObservable(this.filterId);
    }
    return combineLatest([
      itemQuotesObservable,
      this.showExpired
    ]).pipe(
      map(([quotes, showExpired]) => {
        if (!showExpired) return quotes.filter(q => !this.quoteIsExpired(q));
        else return quotes;
      }),
      map((quotes) => {
        quotes.sort((a, b) => this.collator.compare(a.vendor.name, b.vendor.name));
        return quotes;
      })
    )
  }

  public showExpiredQuotes() {
    this.showExpired.next(true);
  }

  public quoteIsExpired(q: MaterialBid) {
    // 30 days
    return q.answered && ((new Date().getTime()) - new Date(q.answered).getTime()) > (1000 * 60 * 60 * 24 * 15)
  }

  public selectedItems: string[] = [];

  public selectedQuote$: Observable<MaterialBid | null>
  public data$: Observable<MaterialBid[]>
  ngOnInit(): void {
    this.setObservables();
  }
  setObservables(): void {
    // this.selectedItems = [this.selectionId];
    this.data$ = this.getQuoteObservable();
    this.selectedQuote$ = combineLatest([this.data$, this.selectedQuoteId$])
      .pipe(map(([quotes, selectedId]) => {
        if (!selectedId || !quotes) return null;
        return quotes.find(q => q.materialBidId === selectedId);
      }), shareReplay(1))
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.order && !changes.order.isFirstChange() && changes.order.currentValue !== changes.order.previousValue)
      ||
      (changes.materialId && !changes.materialId.isFirstChange() && changes.materialId.currentValue !== changes.materialId.previousValue)
      ||
      (changes.stationId && !changes.stationId.isFirstChange() && changes.stationId.currentValue !== changes.stationId.previousValue)
      ||
      (changes.purchasedItemId && !changes.purchasedItemId.isFirstChange() && changes.purchasedItemId.currentValue !== changes.purchasedItemId.previousValue)
      ) {
      this.setObservables();
    }
  }

  @Output() quoteSelected = new EventEmitter<MaterialBid>();
  public async selectQuote(id: string) {
    if (!this.editable) return;
    this.writeValue(id);
    this.onChange(id);
    const quoteList = await this.getQuoteObservable().pipe(take(1)).toPromise();
    const quote = quoteList.find(q => q.materialBidId === id);
    this.quoteSelected.emit(quote);
  }

  public selectedTab = 0;
  public newQuote: MaterialBid;
  @ViewChild('rootEl') rootEl: ElementRef<HTMLDivElement>;
  public createNew() {
    this.newQuote = <MaterialBid>{
      materialBidId: UtilityService.emptyGuid,
      note: '',
      isPoPseudoBid: false,
      isVerbal: false,
      materialBidDocuments: []
    }
    if (this.materialId) this.newQuote.materialId = this.materialId;
    if (this.stationId) this.newQuote.stationId = this.stationId;
    if (this.purchasedItemId) this.newQuote.purchasedItemId = this.purchasedItemId;
    this.selectedTab = 1;
    if (this.rootEl.nativeElement) this.rootEl.nativeElement.scrollIntoView();
  }

  @Output() addQuote = new EventEmitter<MaterialBid>();
  public loading = false;
  public async quoteDone(quote: MaterialBid) {
    quote.materialBidId = UtilityService.newGuid();
    quote.acceptedBy = this.userService.userData?.userId;
    quote.answered = new Date();
    if (this.service) {
      quote.materialBidId = UtilityService.newGuid();
      this.service.addQuote(quote);
      this.selectQuote(quote.materialBidId);
      this.selectedTab = 0;
    }
    else {
      quote.materialBidId = UtilityService.emptyGuid;
      this.loading = true;
      this.selectedTab = 0;
      const returnBid = await this.bidService.save(quote).toPromise();
      this.addQuote.emit(returnBid);
      this.data$ = this.getQuoteObservable();
      this.selectQuote(returnBid.materialBidId);
      this.loading = false;
    }
    this.newQuote = null;
  }

  public quoteCancel() {
    this.selectedTab = 0;
    this.newQuote = null;
  }

  public menuQuote: MaterialBid;
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  public menuPosition: { x: number; y: number } = { x: 0, y: 0 }
  public openMenu(event: MouseEvent, item: MaterialBid) {
    event.preventDefault();
    this.menuQuote = item;
    this.menuPosition = {
      x: event.clientX ?? 0,
      y: event.clientY ?? 0
    }
    this.menuTrigger.openMenu();
  }
  
  @Output() quoteOpened = new EventEmitter<{ itemId: string, quote: MaterialBid, callback: () => {} }>();
  public viewQuote(quote: MaterialBid) {
    const callback = () => {
      this.setObservables();
    };
    this.quoteOpened.emit({ itemId: this.filterId, quote, callback: callback.bind(this) });
  }

  @Output() quoteHistoryOpened = new EventEmitter<string>();
  public openQuoteHistory() {
    this.quoteHistoryOpened.emit(this.filterId);
  }
  
  public getUserName(userId: string, list: User[]) {
    if (!list) return 'Unknown User';
    return list.find(u => u.userId === userId)?.fullName ?? 'Unknown User';
  }
}
