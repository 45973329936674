
<summary-step-data [user]="{ fullName: data.salesOrder?.rootWorkOrder?.contractReviewTicket?.assignedUser_Name }" [status]="data.salesOrder?.rootWorkOrder?.contractReviewTicket?.status"></summary-step-data>
<!-- <div class="mat-small-header mb-2 px-3">Contract Review Progress</div>
<mat-list class="pt-0 border-top list pb-2">
  <mat-list-item *ngFor="let wo of allWorkOrders" class="border-bottom item-outer">
    <div class="w-100">
      <div class="w-100 d-flex align-items-center item">
        <b>Product</b>
        <div>
            {{ getAnsweredForProduct(wo.productId) }} / {{ getTotalForProduct(wo.productId) }}
        </div>
      </div>
    </div>
  </mat-list-item>
</mat-list>
 -->