<div class="row m-0 mb-3 pb-3" ngForm #form="ngForm">
    <div class="col-4">
        <product-quoting *ngIf="service.postChangesOrder$ | async as order" [editable]="editing" [order]="order"
            [materialId]="product.materialId" [isNewMaterial]="newMaterialType === 'new' && materialSelect?.dirty"
            [ngModel]="product.selectedMaterialQuote" name="selectedMaterialQuote"
            (quoteSelected)="setMaterialPrice(product, $event)"
            (quoteOpened)="this.service.openSidenav('quoteDetail', $event)"
            (quoteHistoryOpened)="this.service.openSidenav('quoteHistory', $event)"
            ></product-quoting>
    </div>
    <div class="col-8">
        <div class="row m-0">
            <div class="col-9 mb-2">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Cost Calculation Type</mat-label>
                    <mat-select [ngModel]="product.materialCostIsLotBased" name="materialCostIsLotBased">
                        <mat-option [value]="true">Lot-based (full price of all lots used)</mat-option>
                        <mat-option [value]="false">Amortized (proportional to usage)</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field appearance="outline" class="w-100" floatLabel="always">
                    <mat-label>Material Lot Cost</mat-label>
                    <div matPrefix class="text-muted">$</div>
                    <input matInput readonly placeholder="--" type="text" max="100" [ngModel]="product.materialLotCost"
                        name="materialLotCost" />
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field appearance="outline" class="w-100" floatLabel="always"
                    [hidden]="!editing || !product.partsPerMaterialOverride">
                    <mat-label>Blanks per Unit</mat-label>
                    <input matInput type="number" min="1" step="1" [ngModel]="product.partsPerMaterial"
                        name="partsPerMaterial">
                    <button matSuffix mat-icon-button *ngIf="editing" (click)="togglePartsPerMaterialOverride($event, product)"
                        matTooltip="Remove Override" matTooltipPosition="after">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100" floatLabel="always"
                    *ngIf="!editing || !product.partsPerMaterialOverride">
                    <mat-label>Blanks per Unit</mat-label>
                    <input matInput type="number" step="0.01" readonly
                        [ngModel]="product.partsPerMaterialOverride ? product.partsPerMaterial : getPiecesFromLot(product)"
                        [ngModelOptions]="{standalone: true}">
                    <button matSuffix mat-icon-button *ngIf="editing" (click)="togglePartsPerMaterialOverride($event, product)"
                        matTooltip="Override" matTooltipPosition="after">
                        <mat-icon>edit</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div class="col-5 mb-2">
                <mat-form-field appearance="outline" class="w-100" floatLabel="always">
                    <mat-label>
                        Total Material Cost <span *ngIf="!product.parentAssemblyId">(for primary qty)</span>
                    </mat-label>
                    <div matPrefix class="text-muted">$</div>
                    <input matInput type="text" readonly [ngModel]="(getMaterialCost(product) | number:'1.2-2')"
                        [ngModelOptions]="{standalone: true}">
                    <mat-hint align="end" *ngIf="this.product.materialCostIsLotBased">
                        (<b>{{ product.materialLotCost * materialMarkupFactor | currency }}</b> per every <b>{{
                            piecesFromLotAfterOverride }} parts</b>)
                    </mat-hint>
                    <mat-hint align="end" *ngIf="!this.product.materialCostIsLotBased">
                        (<b>{{ (product.materialLotCost / piecesFromLotAfterOverride ) * materialMarkupFactor | currency
                            }}</b>
                        per part)
                    </mat-hint>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Material Markup</mat-label>
                    <input matInput type="number" step="1" [ngModel]="product.materialMarkup" name="materialMarkup"
                        [readonly]="!editing">
                    <div matSuffix class="text-muted">%</div>
                </mat-form-field>
            </div>
            <div class="col-12 mb-2">
                <mat-form-field appearance="outline" class="w-100" floatLabel="always">
                    <mat-label>Raw Weight</mat-label>
                    <input matInput readonly type="text" [ngModel]="getRawWeightDisplay(product)"
                        [ngModelOptions]="{standalone: true}" placeholder="-- Not Enough Data --" />
                </mat-form-field>
            </div>
            <ng-container *ngFor="let mtd of product.material?.materialType.materialTypeDimensions; trackBy: mtdTrackByFn">
                <div class="col-5">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Blank {{ mtd.dimensionType.label }}</mat-label>
                        <input matInput type="number" min="0.001" step="0.001" [readonly]="!editing"
                            [ngModel]="getBlankDimension(product, mtd.materialTypeDimensionId)"
                            [name]="mtd.materialTypeDimensionId + '-blank'"
                            (ngModelChange)="setBlankDimension(product, mtd.materialTypeDimensionId, $event)" />
                        <div class="mat-small-header" matSuffix>
                            {{ mtd.dimensionUnit.abbreviation }}
                        </div>
                    </mat-form-field>
                </div>
                <div class="col-2 d-flex">
                    <i class="fas fa-arrow-right text-muted mx-2 align-self-center mb-2"></i>
                </div>
                <div class="col-5 mb-2">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Finished {{ mtd.dimensionType.label }}</mat-label>
                        <input matInput type="number" min="0.001" step="0.001" [readonly]="!editing"
                            [ngModel]="getFinishedDimension(product, mtd.materialTypeDimensionId)"
                            [name]="mtd.materialTypeDimensionId + '-finished'"
                            [max]="getBlankDimension(product, mtd.materialTypeDimensionId)"
                            (ngModelChange)="setFinishedDimension(product, mtd.materialTypeDimensionId, $event)" />
                        <div class="mat-small-header" matSuffix>
                            {{ mtd.dimensionUnit.abbreviation }}
                        </div>
                    </mat-form-field>
                </div>
            </ng-container>
            <div class="col-5 mb-2">
                <mat-form-field appearance="outline" class="w-100" floatLabel="always">
                    <mat-label>Blank Weight</mat-label>
                    <input matInput readonly type="text" [ngModel]="getBlankWeightDisplay(product)"
                        [ngModelOptions]="{standalone: true}" placeholder="-- Not Enough Data --" />
                </mat-form-field>
            </div>
            <div class="col-2 d-flex">
                <i class="fas fa-arrow-right text-muted mx-2 align-self-center mb-2"></i>
            </div>
            <div class="col-5 mb-2">
                <mat-form-field appearance="outline" class="w-100" floatLabel="always">
                    <mat-label>Finished Weight</mat-label>
                    <input matInput readonly type="text" [ngModel]="getFinishedWeightDisplay(product)"
                        [ngModelOptions]="{standalone: true}" placeholder="-- Not Enough Data --" />
                </mat-form-field>
            </div>
            <div class="col-12">
                <div class="border rounded d-flex flex-column">
                    <div class="p-3 border-bottom mb-2 d-flex align-items-center">
                        <div style="font-size: 1.25rem" class="font-weight-normal">Difficulty</div>
                        <button mat-stroked-button class="button-text ml-auto" (click)="resetComplexity(product)"
                            *ngIf="editing" [class.invisible]="!product.complexity">
                            <mat-icon matPrefix>undo</mat-icon>
                            Remove Override
                        </button>
                        <button mat-stroked-button class="button-text ml-auto" (click)="resetComplexity(product)"
                            *ngIf="!editing" disabled [class.invisible]="!product.complexity">
                            <mat-icon matPrefix>warning</mat-icon>
                            Overriden
                        </button>
                    </div>
                    <div class="p-3 text-center w-100 d-flex align-items-center">
                        <span class="small text-muted">Easy</span>
                        <mat-slider tickInterval="1" min="0" max="9" step="1" [disabled]="!editing"
                            class="flex-grow-1 mx-2" [ngClass]="'level-'+getComplexity(product)"
                            [ngModel]="getComplexity(product)" name="complexity"></mat-slider>
                        <span class="small text-muted">Hard</span>
                    </div>
                    <div class="p-3 d-flex w-100">
                        <div class="small text-muted">
                            {{getMaterialRemoved(product) | number:'1.0-2'}} % Material Removed
                        </div>
                        <div class="ml-auto text-muted small text-right pt-3">
                            <div class="font-weight-bold">Average Removal Time</div>
                            <div class="pl-2 pt-1">
                                Lathe: <span class="font-weight-bold">XX min</span> (YY in<sup>3</sup>/min)
                            </div>
                            <div class="pl-2">
                                Router: <span class="font-weight-bold">XX min</span> (YY in<sup>3</sup>/min)
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-2">
                <div class="mat-dialog-title">Related Microtickets for Material</div>
            </div>
            <div class="col-12 mt-3 mb-1">
                <item-rfq-request-status
                    [record]="service.postChangesOrder$ | async" [product]="product"
                    [material]="product.material"
                    [purchasingRfqRequest]="product.materialPurchasingRfqRequest"
                    (requestCreated)="onPurchasingRfqRequestCreated($event)"
                ></item-rfq-request-status>
            </div>
            <mat-divider class="mb-4 mt-2 mx-3 w-100"></mat-divider>
            <div class="col-12 mt-1">
                <microticket-subitem-viewer *ngLet="service.postChangesOrder$ | async as record"
                    [relatedTicketId]="record.salesProcessId"
                    [relatedTicketName]="service.getNameForMicrotickets(record)" [relatedTicketType]="'SalesProcess'"
                    [filterKey]="service.getMicroticketFilterKey(record)"
                    [subItem]="service.generateSubItemNavigationId(product.productId, 'material')"
                    (loadingChange)="service.loading = $event"
                    [microTickets]="service.sharedMicroticketSubject"></microticket-subitem-viewer>
            </div>
        </div>
    </div>
</div>