<div class="position-relative rounded" style="
  overflow: hidden;
  overflow-y: scroll;
  height: 90vh;
  display: flex;
  flex-direction: column;
">
  <mat-progress-bar class="w-100 progress-bar" style="position: absolute; top: 0; z-index: 999;" [class.progress-bar-hidden]="!loading" mode="indeterminate"></mat-progress-bar>
  <div id="filter-bar">
    <div class="row">
      <div class="col-8">
        <mat-form-field appearance="outline" class="mat-input-no-message dense-field" id="search-field" #searchField>
          <mat-label>Text filter</mat-label>
          <mat-icon matPrefix class="text-muted">search</mat-icon>
          <input matInput type="text" [(ngModel)]="searchText" (keyup.enter)="onSearchChange()" #searchInput />
        </mat-form-field>
        <mat-form-field appearance="outline" class="mat-input-no-message" id="daterange-field" #rangeField>
          <mat-label>Date Range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate [(ngModel)]="since" (keyup.enter)="onSearchChange()" placeholder="Start date">
            <input matEndDate [(ngModel)]="until" (keyup.enter)="onSearchChange()" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <mat-checkbox
          #purchasedOnlyCheckbox
          id="purchased-only"
          [checked]="purchasedOnly"
        >
          Awarded only
        </mat-checkbox>
      </div>
      <div class="col-4 filter-buttons">
        <button
          (click)="onSearchChange()"
          mat-flat-button
          [class.bg-primary]="!loading"
          [class.text-white]="!loading"
          class="button-text"
          [disabled]="loading"
        >
          Apply
        </button>
        <button
          mat-flat-button
          (click)="clearSearch()"
          [class.bg-primary]="!loading"
          [class.text-white]="!loading"
          class="button-text"
          [disabled]="loading || (searchText === '' && since === null && until === null && purchasedOnly)"
        >
            Clear
        </button>
        <button
          mat-flat-button
          [class.bg-primary]="!loading"
          [class.text-white]="!loading"
          [disabled]="loading"
          class="button-text"
          (click)="export()"
        >
          Export
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <mat-table
      #dataTable
      [dataSource]="dataSource"
      matSort
      class="w-100 flex-grow-1"
      [class.loading]="loading"
    >
      <mat-header-row *matHeaderRowDef="rowDisplayedColumns"></mat-header-row>

      <mat-row
        *matRowDef="let row; columns: ['groupHeader']; when: rowIsHeader"
        class="oor-row"
      ></mat-row>

      <mat-row
        *matRowDef="let row; columns: rowDisplayedColumns;"
        (click)="newTab('/quoting/' + row.quoteId)"
        class="oor-row oospr-data-row"
      ></mat-row>

      <ng-container matColumnDef="groupHeader">
        <mat-cell *matCellDef="let row" class="oospr-header-row">
          <div>
            <b>{{ row.groupName }}</b>
          </div><div>
            <b>{{ row.lines }}</b> orders
          </div><div>
            <b>{{ row.total | currency }}</b>
          </div>
        </mat-cell>
      </ng-container>

      <div class="mat-row" *matNoDataRow>
        <div class="mat-cell"
          [attr.colspan]="rowDisplayedColumns.length"
          class="text-muted small font-italic text-center"
          *ngIf="!loading"
        >
          No finished quotes matching the parameters were found.
        </div>
      </div>

      <ng-container matColumnDef="customer">
        <mat-header-cell *matHeaderCellDef mat-sort-header="customer" style="flex-basis: 8%"> Customer </mat-header-cell>
        <mat-cell *matCellDef="let row" style="flex-basis: 8%">
          {{ row.customer }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="requiredDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="requiredDate" style="flex-basis: 3%"> Due Date </mat-header-cell>
        <mat-cell *matCellDef="let row" style="flex-basis: 3%">
          <ng-container *ngIf="hasValidDate(row.requiredDate); else maxDate">
            {{ row.requiredDate | date:'dd-MMM-yy' }}
          </ng-container>
          <ng-template #maxDate>
            ---
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="partNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header="partNumber" style="flex-basis: 10%; padding-left: 1em;"> Part # </mat-header-cell>
        <mat-cell *matCellDef="let row" style="flex-basis: 10%; padding-left: 1em;">
          <ng-container *ngIf="row.station == null; else withStation">
            {{ row.partNumber }}
          </ng-container>
          <ng-template #withStation>
            {{ row.station }} for {{ row.partNumber }}
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="contact">
        <mat-header-cell *matHeaderCellDef style="flex-basis: 5%; padding-left: 1em;"> Contact </mat-header-cell>
        <mat-cell *matCellDef="let row" style="flex-basis: 5%; padding-left: 1em;">
          {{ row.contact }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header="status" style="flex-basis: 10%"> Status </mat-header-cell>
        <mat-cell *matCellDef="let row" style="flex-basis: 10%">
          {{ row.status }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="orderNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header="orderNumber" style="flex-basis: 5%; padding-left: 1em;"> Order </mat-header-cell>
        <mat-cell *matCellDef="let row" style="flex-basis: 5%; padding-left: 1em;">
          {{ row.orderNumber }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="customerRfqNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header="customerRfqNumber" style="flex-basis: 10%; padding-left: 1em;"> CustRFQ </mat-header-cell>
        <mat-cell *matCellDef="let row" style="flex-basis: 10%; padding-left: 1em;">
          {{ row.customerRfqNumber }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="customerPurchaseOrderNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header="customerPurchaseOrderNumber" style="flex-basis: 10%; padding-left: 1em;"> CustPO </mat-header-cell>
        <mat-cell *matCellDef="let row" style="flex-basis: 10%; padding-left: 1em;">
          {{ row.customerPurchaseOrderNumber ?? '---' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="finalExtPrice">
        <mat-header-cell *matHeaderCellDef mat-sort-header="finalExtPrice" style="flex-basis: 5%; padding-left: 1em;"> Ext Price </mat-header-cell>
        <mat-cell *matCellDef="let row" style="flex-basis: 5%; padding-left: 1em;">
          {{ row.finalExtPrice | currency }}
        </mat-cell>
      </ng-container>

    </mat-table>
  </div>

  <mat-paginator
    [pageSizeOptions]="[15]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
    style="position: sticky; bottom: -1px;"
  ></mat-paginator>
</div>