<ng-container *ngLet="service.selectedProduct$ | async as product">
<mat-progress-bar class="w-100 progress-bar" mode="indeterminate" *ngIf="matLoading"></mat-progress-bar>
<div class="row m-0 pt-3" [class.loading-block]="matLoading">
  <ng-container *ngIf="service.postChangesOrder$ | async as record">
  <div class="col-12 d-flex pb-2 border-bottom mb-2" *ngIf="service.shouldShowIndicators(record)">
    <div class="ml-auto">
      <estimating-progress-status label="Material Status" [status]="product.materialStatus" (statusChange)="service.setEstimatingTaskStatus(product.productId, 'material', $event)" [disabled]="!editing"></estimating-progress-status>
    </div>
  </div>
  </ng-container>
  <div class="col-12 mb-3" [class.mt-3]="service.solo">
    <div class="w-100 mb-3 d-flex align-items-center">
      <mat-radio-group *ngIf="editing" [ngModel]="newMaterialType" name="newMaterialType" (ngModelChange)="onNewMaterialTypeChange(product, $event)">
        <mat-radio-button class="mat-radio-no-margin mr-1" value="new">Build Material</mat-radio-button>
        <mat-radio-button class="mat-radio-no-margin" value="search">Search Existing Materials</mat-radio-button>
      </mat-radio-group>
      <button 
        mat-flat-button 
        *ngIf="newMaterialType === 'new' && editing && !product.material"
        class="ml-auto button-text"
        color="primary"
        [disabled]="!materialSelect?.materialFullyInput || !materialSelect?.materialDensity || !materialSelect?.dimensionsValid"
        (click)="submitMaterial(product, materialSelect)">
        Submit Material
      </button>
      <button 
        mat-flat-button 
        *ngIf="editing && product.material"
        class="ml-auto button-text"
        color="accent"
        (click)="editMaterial(product)">
        Edit Material
      </button>
      <button 
        mat-flat-button 
        *ngIf="editing && product.material"
        class="ml-2 button-text"
        color="warn"
        (click)="clearMaterial(product)">
        Clear Material
      </button>
    </div>
    <ng-container *ngIf="newMaterialType === 'new' || !editing">
      <material-select [disabled]="!editing || product.material"
        [material]="product.material" #materialSelect
      >
      </material-select>
    </ng-container>
    <material-simple-search [class.d-none]="newMaterialType !== 'search' || !editing"
      [ngModel]="product.material" name="material" (ngModelChange)="onMaterialSearchSelect(product, $event)"
      name="materialSearch"></material-simple-search>
  </div>
  <div class="col-12 mt-2" *ngIf="!product.material">
    <div class="mat-dialog-title">Related Microtickets for Material</div>
  </div>
  <div class="col-12" *ngIf="!product.material">
    <microticket-subitem-viewer
      *ngLet="service.postChangesOrder$ | async as record"
      [relatedTicketId]="record.salesProcessId"
      [relatedTicketName]="service.getNameForMicrotickets(record)"
      [relatedTicketType]="'SalesProcess'"
      [filterKey]="service.getMicroticketFilterKey(record)"
      [subItem]="service.generateSubItemNavigationId(product.productId, 'material')"
      (loadingChange)="service.loading = $event"
      [microTickets]="service.sharedMicroticketSubject"
    ></microticket-subitem-viewer>
  </div>
</div>
<ng-container *ngIf="product.material">
  <product-material-form [class.loading-block]="matLoading" *ngFor="let i of [product.productId + product.materialId]" [product]="product"></product-material-form>
</ng-container>
