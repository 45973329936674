import { trigger, transition, style, animate } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SummaryOverview, SummaryStage, SummaryStageProgress, getAllSummaryWorkOrders, getLatestVisibleStage, getProgressScore, getSummaryStageProgress } from '@cots/report/resources/summary-overview';
import { assertUnreachable } from 'util/assertUnreachable';
import { OrderService } from '@cots/order/services/order.service';
import { MatSort } from '@angular/material/sort';
import { NavigationService } from '@cots/common/services/navigation.service';
import { keywordMatch } from 'util/keywordMatch';

type Data = SummaryOverview | { groupName: string, groupId: string };
function isOverview(data: Data): data is SummaryOverview {
  return data.hasOwnProperty('salesProcessId');
}

@Component({
  selector: 'master-summary-report',
  templateUrl: './master-summary-report.component.html',
  styleUrls: ['./master-summary-report.component.less'],
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ 'max-height': 0 }),
            animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)', 
                    style({ 'max-height': '65vh' }))
          ]
        ),
        // transition(
        //   ':leave', 
        //   [
        //     style({ height: '250px' }),
        //     animate('250ms cubic-bezier(0.4, 0.0, 0.2, 1)', 
        //             style({ height: 0 }))
        //   ]
        // )
      ]
    ),
    trigger(
      'triangleInOutAnimation', 
      [
        // transition(
        //   ':enter', 
        //   [
        //     style({ top: 0, 'clip-path': 'polygon(0 1%, 100% 20%, 100% 81%, 0% 81%)' }),
        //     animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)'), 
        //     style({ top: '-17px', 'clip-path': 'polygon(0 1%, 100% 20%, 100% 62%, 0% 62%)' }),
        //   ]
        // ),
        // transition(
        //   ':leave', 
        //   [
        //     style({ top: '17px', 'clip-path': 'polygon(0 1%, 100% 20%, 100% 62%, 0% 62%)' }),
        //     animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)'), 
        //     style({ top: 0, 'clip-path': 'polygon(0 1%, 100% 20%, 100% 81%, 0% 81%)' }),
        //   ]
        // )
      ]
    ),
  ]
})
export class MasterSummaryReportComponent implements OnInit {

  constructor(private service: OrderService, private cdr: ChangeDetectorRef, navService: NavigationService) {
    navService.setPageTitle("Reports");
    navService.pushBreadcrumb('Master Summary Report');
  }

  public baseDisplayedColumns = [
    'number',
    'customer',
    'dueDate',
    'currentUser',
    'sales',
    'estimating',
    'quoting',
    'contractReview',
    'preplanning',
    'purchasing',
    'planning',
    'production',
    'inspection',
    'shipping',
  ]
  public get displayedColumns() {
    switch (this.groupBy) {
      case 'customer':
        return this.baseDisplayedColumns.filter(c => c !== 'customer');
      case 'dueDate':
        return this.baseDisplayedColumns.filter(c => c !== 'dueDate');
    }
  }
  public get rowDisplayedColumns() {
    return [...this.displayedColumns, 'expansionPanel']
  }


  public loading = false;
  public dataSource = new MatTableDataSource<Data>();

  private getDateId(date: Date) {
    return (date.getMonth() + 1) + 
    "/" +  date.getDate() +
    "/" +  date.getFullYear();
  }

  public groupBy: 'dueDate' | 'customer' = 'customer';

  public groupedData: {
    groupName: string;
    groupId: string;
    innerData: SummaryOverview[]
  }[] = [];
  private getGroupId(data: SummaryOverview) {
    switch (this.groupBy) {
      case 'dueDate':
        return this.getDateId(new Date(data.dueDate));
      case 'customer':
        return data.customer.customerId;
    }
  }
  private getGroupName(data: SummaryOverview) {
    switch (this.groupBy) {
      case 'dueDate':
        return new Date(data.dueDate).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
      case 'customer':
        return data.customer.businessName;
    }
  }
  public groupData(data: SummaryOverview[]) {
    return data.reduce((acc, x) => {
      const groupId = this.getGroupId(x);
      const existingGroup = acc.find(g => g.groupId === groupId);
      if (existingGroup) existingGroup.innerData.push(x);
      else acc.push({
        groupId,
        groupName: this.getGroupName(x),
        innerData: [x]
      });
      return acc;
    }, [] as typeof this['groupedData']);
  }
  public transformGroupedData(data: typeof this['groupedData']): Data[] {
    return data.flatMap(gd => [{ groupId: gd.groupId, groupName: gd.groupName }, ...gd.innerData]);
  }

  public groupSelectionModel = new SelectionModel<string>(true);
  public toggleGroup(groupId: string) {
    this.groupSelectionModel.toggle(groupId);
    this.dataSource._updateChangeSubscription();
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  ngOnInit(): void {
  }

  private innerProductMatch(item: SummaryOverview, searchString: string): SummaryStage | null {
    const stage = getLatestVisibleStage(item);
    switch (stage) {
      case 'sales':
        if (item.rfq?.products?.some(p => keywordMatch(searchString, `${p.partNumber} Rev. ${p.revision}`))) return stage;
        else return null;
      case 'estimating':
        if (item.estimate?.products?.some(p => keywordMatch(searchString, `${p.partNumber} Rev. ${p.revision}`))) return stage;
        else return null;
      case 'quoting':
        return null;
      case 'contractReview':
        return null;
      case 'preplanning':
      case 'purchasing':
      case 'planning':
      case 'production':
      case 'inspection':
        const wos = getAllSummaryWorkOrders(item);
        if (wos.some(wo => keywordMatch(searchString, wo.workOrderNumber))) return stage;
        else return null;
      case 'shipping':
        break;
    }
  }

  private filterMatch(item: SummaryOverview, searchString: string): boolean {
    if (keywordMatch(searchString, item.customer?.businessName ?? '')) return true;
    const user = this.getCurrentUser(item);
    if (user && keywordMatch(searchString, user)) return true;
    const number = this.getItemNumber(item);
    if (keywordMatch(searchString, number)) return true;
    if (this.innerProductMatch(item, searchString) !== null) return true;
    if (item.salesOrder) {
      const wos = getAllSummaryWorkOrders(item);
      if (wos.some(wo => 
        keywordMatch(searchString, wo.workOrderNumber)
      )) return true;
    }
    return false;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource._orderData = (data: typeof this['groupedData']) => {
      if (!this.sort) return data;
      let sorted = data.map(d => ( {
        ...d,
        innerData: d.innerData.sort((aItem, bItem) => {
          switch (this.sort.active) {
            case 'number':
              let o = this.woIdCollator.compare(this.getItemNumber(aItem), this.getItemNumber(bItem));
              if (this.sort.direction === 'desc') { o = o * -1 };
              return o;
            case 'user':
              let o2 = this.woIdCollator.compare(this.getCurrentUser(aItem) ?? '', this.getCurrentUser(bItem) ?? '');
              if (this.sort.direction === 'desc') { o2 = o2 * -1 };
              return o2;
            case 'progress':
              const aScore = getProgressScore(aItem);
              const bScore = getProgressScore(bItem);
              if (this.sort.direction === 'asc') { return aScore - bScore }
              else return bScore - aScore;
            default:
              break;
          }
        })
      } ))
      return sorted;
    }
    this.dataSource.paginator = this.paginator;
    this.dataSource._filterData = (data: typeof this['groupedData']) => {
      return data.map((group) => {
        return { ...group, innerData: group.innerData.filter(d => {
          return !this.searchText.trim() || this.filterMatch(d, this.searchText);
        }) };
      })
    }
    this.dataSource._pageData = (data: typeof this['groupedData']) => {
      const collapsed = data.flatMap((group) => {
        if (group.innerData.length === 0) return [];
        const isCollapsed = this.groupSelectionModel.isSelected(group.groupId);
        const collapsedData = !isCollapsed ? group.innerData : [];
        return [
          { groupId: group.groupId, groupName: group.groupName, isCollapsed },
          ...collapsedData
        ]
      });
      this.dataSource._updatePaginator(collapsed.length);
      const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
      
      const groups = collapsed.filter(i => !isOverview(i)) as { groupId: string, groupName: string, isCollapsed: boolean }[];
      let sliced = collapsed.slice(startIndex, startIndex + this.paginator.pageSize);
      if (sliced.length > 0 && isOverview(sliced[0])) {
        const key = this.getGroupId(sliced[0]);
        const group = groups.find(g => g.groupId === key);
        sliced = [group, ...sliced];
      }
      return sliced;
      ;
    };
    this.getDetail();
  }
  public originalData: SummaryOverview[];
  public async getDetail() {
    this.loading = true;
    const d = await this.service.getMasterSummary().toPromise();
    this.loading = false;
    this.originalData = d;
    this.dataSource.data = this.groupData(d);
  }

  public rowIsGroupHeader(_: number, row: Data) {
    return !isOverview(row);
  } 

  public searchText = '';
  public highlightFilter: string[] = [];
  onSearchChange() {
    this.dataSource.filter = this.searchText;
    this.highlightFilter = this.searchText.split(' ');
    this.expansionModel.clear();
    if (!!this.searchText.trim()) {
      const ordersWithMatchingParts = this.originalData.map(d => {
        const stage = this.innerProductMatch(d, this.searchText);
        if (stage === null) return false;
        else return [d, stage];
      }).filter(i => i !== false) as [SummaryOverview, SummaryStage][];
      for (const [item, stage] of ordersWithMatchingParts) {
        this.selectSection(item, stage, true);
      }
    }
  }
  clearSearch() {
    this.searchText = '';
    this.dataSource.filter = '';
  }

  public expansionModel = new SelectionModel<string>(true);
  // public selectedIndex = 0;
  public selectedIndexMap: { [key: string]: number } = {};
  public triangleOffsetMap: { [key: string]: number } = {};
  private tabs = [
    'sales',
    'estimating',
    'quoting',
    'contractReview',
    'preplanning',
    'purchasing',
    'planning',
    'production',
    'inspection',
    'shipping',
  ] as const;
  public getKey(item: SummaryOverview) {
    return item.workOrderId ?? item.salesProcessId;
  }
  public selectSection(item: Data, tab: SummaryStage, forceOpen = false) {
    if (!isOverview(item)) return;
    if ((
      (tab === 'preplanning' || tab === 'purchasing' || tab === 'planning' || tab === 'production' || tab === 'inspection')
      &&
      getSummaryStageProgress(item, 'preplanning') === SummaryStageProgress.BLOCKED
      ) || getSummaryStageProgress(item, tab) === SummaryStageProgress.BLOCKED) {
      return;
    }
    const key = this.getKey(item);
    let targetIndex: number;
    const triangleOffset = this.tabs.findIndex(t => t === tab);
    if (tab === 'preplanning' || tab === 'purchasing' || tab === 'planning' || tab === 'production' || tab === 'inspection') {
      targetIndex = 4;
    } else if (tab === 'shipping') {
      targetIndex = 5;
    } else {
      targetIndex = triangleOffset;
    }
    if (!forceOpen && (this.expansionModel.isSelected(key) && this.triangleOffsetMap[key] === triangleOffset)) {
      this.expansionModel.clear();
      this.selectedIndexMap[key] = 0;
      this.triangleOffsetMap[key] = 0;
    } else {
      if (!forceOpen) this.expansionModel.clear();
      this.expansionModel.select(key);
      this.selectedIndexMap[key] = targetIndex;
      this.triangleOffsetMap[key] = triangleOffset;
    }
  }

  public getDueDate(item: SummaryOverview) {
    // TODO
    return new Date();
  }

  public getCheckboxColorClass(item: SummaryOverview, stage: SummaryStage) {
    const progress = getSummaryStageProgress(item, stage);
    switch (progress) {
      case SummaryStageProgress.BLOCKED:
        return '';
      case SummaryStageProgress.PENDING:
        return 'bg-danger';
      case SummaryStageProgress.WORK_STARTED:
        return 'bg-warning';
      case SummaryStageProgress.FINISHED:
        return 'bg-success';
      default:
        assertUnreachable(progress);
    }
  }

  private woIdCollator = new Intl.Collator('en', { numeric: true });
  public getItemNumber(item: SummaryOverview) {
    // ideally this will be a fixed number in the SalesProcess object in the future
    if (!item.rfq?.orderNumber) return 'NO NUMBER';
    const baseNumber = item.rfq.orderNumber.replace(/\w{3}\-0+/, '');
    if (item.workOrderId) {
      // autogenerate a consistent number for each work order as a temporary measure
      // this will stop working if we stop getting every single item all at once
      const allWorkOrders = this.originalData
        .filter((i: SummaryOverview) => i.salesProcessId === item.salesProcessId)
        .map((o: SummaryOverview) => o.workOrderId)
        .sort((a, b) => this.woIdCollator.compare(a, b));
      const subNumber = allWorkOrders.findIndex(id => id === item.workOrderId) + 1;
      return `${baseNumber}-W${subNumber.toString().padStart(4, '0')}`;
    } else {
      return baseNumber;
    }
  }

  public getCurrentUser(item: SummaryOverview): string | null {
    if (getSummaryStageProgress(item, 'sales') !== SummaryStageProgress.FINISHED) {
      return item.rfq?.user?.fullName ?? null;
    } else if (getSummaryStageProgress(item, 'estimating') !== SummaryStageProgress.FINISHED) {
      return item.estimate?.user?.fullName ?? null;
    } else if (getSummaryStageProgress(item, 'quoting') !== SummaryStageProgress.FINISHED) {
      return item.quote?.user?.fullName ?? null;
    } else if (getSummaryStageProgress(item, 'contractReview') !== SummaryStageProgress.FINISHED) {
      return item.salesOrder?.rootWorkOrder?.contractReviewTicket?.assignedUser_Name ?? null;
    } else {
      // TODO
      return null;
    }
  }

  public getProgress = getSummaryStageProgress;

  public getProgressValues(item: SummaryOverview, stage: SummaryStage) {
    const progress = getSummaryStageProgress(item, stage);
    return {
      blocked: progress === SummaryStageProgress.BLOCKED,
      finished: progress === SummaryStageProgress.FINISHED,
    }
  }

}
