import { ZodError, z } from 'zod';
import { Product } from '../../resources/product';
import { Order } from '../../resources/order';
import { WorkflowStep } from '@cots/order/resources/workflow';

/* Order validation */

const OrderValidationSchema = z.object({
    customer: z.any({ required_error: "Customer is required" })
});

export function validateOrder(order: Order) {
    const { success, error } = OrderValidationSchema.safeParse(order);
    if (!success) return error;
    else return null;
}

export type OrderValidationError = ReturnType<typeof validateOrder>;

/* Product validation */


const productQuantityData = z.union([
    z.object({
        // if parentAssemblyId is null
        parentAssemblyId: z.null().or(z.undefined()),
        // then quantitiesMap should fit schema
        quantitiesMap: z.object({
            markup: z
                .number({ required_error: "Quantity markup must be input" })
                .nonnegative("Quantity markup cannot be negative")
                // backend takes care of null values and making this required causes weirdness with zod union errors when it's null
                // just make it optional
                .optional()
        }).array().nonempty("Part needs at least one quantity"),
    }),
    z.object({
        // if parentAssemblyId is not null
        parentAssemblyId: z.string(),
        // we should have a quantityAsChild
        quantityAsChild: z.number().int().nonnegative(),
    })
], { errorMap: () => ({ message: "Subassembly needs a valid quantity" }) });

const ProductValidationSchema = z.object({
    // Validation that should apply to all products goes here
    workflow: z.object({
        workflowSteps: z.union([
            z.object({ outsourceMarkup: z.number(), }).and(
                z.union([
                    z.object({ requiresNoSpecifications: z.literal(false), outsideProcessSpecifications: z.string().array().nonempty("OSP must have specifications") }),
                    z.object({ requiresNoSpecifications: z.literal(true), }),
                ])
            ),
            z.object({ outsourceMarkup: z.null().or(z.undefined()) }),
        ]).and(z.object({
            workflowStepId: z.string()
        })).array(),
    })
}).and(productQuantityData);

export function validateProduct(prod: Product) {
    const { success, error } = ProductValidationSchema.safeParse(prod);
    if (!success) return error;
    else return null;
}

export type ProductValidationError = ReturnType<typeof validateProduct>;