<ng-container ngForm #form="ngForm">
  <div>
    <div class="border rounded p-2 mb-2 mx-3 mt-2 d-flex align-items-center justify-content-center">
      <mat-icon svgIcon="cog" [class.text-muted]="step.runIsPerPart" class="mr-1"></mat-icon>
      <div class="small font-weight-medium" [class.text-muted]="step.runIsPerPart">
        Per Job
      </div>
      <mat-slide-toggle [class.mat-slide-toggle-readonly]="!editing" [(ngModel)]="step.runIsPerPart" name="runIsPerPart"
        class="mx-2 mat-slide-toggle-impartial"></mat-slide-toggle>
      <div class="small font-weight-medium" [class.text-muted]="!step.runIsPerPart">Per Part</div>
    </div>
  </div>
  <div>
    <div class="border rounded p-2 mb-2 mx-3 mt-2 d-flex align-items-center justify-content-center">
      <mat-checkbox [class.mat-checkbox-readonly]="!editing" [(ngModel)]="step.isStandalone" name="isStandalone"
        class="mr-2 mat-checkbox-no-margin"></mat-checkbox>
      <div class="small font-weight-medium">
        Standalone Step
      </div>
    </div>
  </div>
  <div class="col-12 mb-2">
    <div class="border rounded p-2 mb-2">
      <div class="text-center position-relative">
        <div class="font-weight-medium">Sequencing</div>
      </div>
      <ng-container>
        <div class="px-2">
          <mat-checkbox class="small mt-2" [(ngModel)]="step.isAssembly" name="isAssembly"
            [class.mat-checkbox-readonly]="!canEditWorkflowStep">
            Assembly step
          </mat-checkbox>
          <mat-checkbox class="small mt-2" [(ngModel)]="step.isAdministrative" name="isAdministrative"
            [class.mat-checkbox-readonly]="!canEditWorkflowStep">
            Administrative/Planning step
          </mat-checkbox>
          <mat-form-field class="w-100 mt-2" appearance="outline">
            <mat-label>Start offset hours</mat-label>
            <input type="number" matInput [(ngModel)]="step.startOffsetHours" name="startOffsetHours" [readonly]="!canEditWorkflowStep" />
            <span matSuffix>
              hr
            </span>
          </mat-form-field>
          <mat-form-field class="w-100 mt-2" appearance="outline">
            <mat-label>Batch Size</mat-label>
            <input type="number" matInput [(ngModel)]="step.productionBatchSize" name="productionBatchSize"
              [readonly]="!canEditWorkflowStep" />
          </mat-form-field>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf="!(getStation(step)?.isOutsourceStep)">
    <div class="row m-0 mt-2">
      <div class="col-12">
        <div class="border rounded p-2 mb-2">
          <div class="text-center position-relative">
            <div class="font-weight-medium">Setup</div>
            <mat-checkbox class="mat-checkbox-no-margin" style="position: absolute; right: 0; top: -2px"
              [(ngModel)]="step.hasSetup" name="hasSetup"
              [class.mat-checkbox-readonly]="!canEditWorkflowStep"></mat-checkbox>
          </div>
          <div class="w-100 mt-2" [class.d-none]="!step.hasSetup">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Initial Setup Time</mat-label>
              <input type="number" matInput [(ngModel)]="step.setupTime" name="setupTime"
                [readonly]="!canEditWorkflowStep" />
              <span matSuffix>
                hr
              </span>
            </mat-form-field>
            <mat-form-field class="w-100" appearance="outline" [class.d-none]="!step.runIsPerPart">
              <mat-label>Per-Piece Setup Time</mat-label>
              <input type="number" matInput [(ngModel)]="step.perPieceSetupTime" name="perPieceSetupTime"
                [readonly]="!canEditWorkflowStep" />
              <span matSuffix>
                min
              </span>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Run Time</mat-label>
              <input type="number" matInput [(ngModel)]="step.runTime" name="runTime"
                [readonly]="!canEditWorkflowStep" />
              <span matSuffix>
                {{step.runIsPerPart ? 'min':'hr'}}
              </span>
            </mat-form-field>
          </div>
          <div class="col-12 mb-2">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Run Rate</mat-label>
              <span matPrefix>
                $
              </span>
              <input type="number" matInput [(ngModel)]="step.runPrice" name="runPrice"
                [readonly]="!canEditWorkflowStep" />
            </mat-form-field>
          </div>
          <div class="col-12">
            <div class="border rounded p-2 mb-2">
              <div class="text-center position-relative">
                <div class="font-weight-medium">Programming</div>
                <mat-checkbox class="mat-checkbox-no-margin" style="position: absolute; right: 0; top: -2px"
                  [(ngModel)]="step.hasProgramming" name="hasProgramming"
                  [class.mat-checkbox-readonly]="!canEditWorkflowStep"></mat-checkbox>
              </div>
                <div class="px-2" [class.d-none]="!step.hasProgramming">
                  <mat-form-field class="w-100 dense-field mt-1 mat-input-no-message" appearance="outline">
                    <mat-label>Run Time</mat-label>
                    <input type="number" matInput [(ngModel)]="step.programmingTime" name="programmingTime"
                      [readonly]="!canEditWorkflowStep" />
                    <span matSuffix>
                      hr
                    </span>
                  </mat-form-field>
                </div>
                <div class="px-2 mb-1" [class.d-none]="!step.hasProgramming">
                  <mat-form-field class="w-100 dense-field mt-1 mat-input-no-message" appearance="outline">
                    <mat-label>Run Rate</mat-label>
                    <span matPrefix>
                      $
                    </span>
                    <input type="number" matInput [(ngModel)]="step.programmingRate" name="programmingRate"
                      [readonly]="!canEditWorkflowStep" />
                  </mat-form-field>
                </div>
                <div class="px-2 mt-1 text-right" [class.d-none]="!step.hasProgramming">
                  <span class="font-weight-bold small">Total:{{ (step.programmingRate || 0) * (step.programmingTime ||
                    0) | currency }}</span>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="border rounded mb-2 pt-1 d-flex flex-column">
          <div class="text-center position-relative px-2 mb-1">
            <div class="font-weight-medium">Inspection</div>
          </div>
          <div class="px-2">
            <mat-checkbox class="small" [(ngModel)]="step.inspectionIsCMM" name="inspectionIsCMM"
              [class.mat-checkbox-readonly]="!canEditWorkflowStep">
              Inspection Uses CMM
            </mat-checkbox>
          </div>
          <div class="border-bottom mb-1"></div>
          <div class="text-center position-relative px-2 mb-1">
            <div class="font-weight-medium">First Part Inspection</div>
            <mat-checkbox class="mat-checkbox-no-margin mr-2" style="position: absolute; right: 0; top: -2px"
              [(ngModel)]="step.hasFirstPartInspection" name="hasFirstPartInspection"
              [class.mat-checkbox-readonly]="!canEditWorkflowStep"></mat-checkbox>
          </div>
            <div class="px-2" [class.d-none]="!step.hasFirstPartInspection">
              <mat-form-field class="w-100 dense-field mt-1 mat-input-no-message" appearance="outline">
                <mat-label>Run Time</mat-label>
                <input type="number" matInput [(ngModel)]="step.firstPartInspectionTime" name="firstPartInspectionTime"
                  [readonly]="!canEditWorkflowStep" />
                <span matSuffix>
                  min
                </span>
              </mat-form-field>
            </div>
            <div class="px-2 mb-1" [class.d-none]="!step.hasFirstPartInspection">
              <mat-form-field class="w-100 dense-field mt-1 mat-input-no-message" appearance="outline">
                <mat-label>Run Rate</mat-label>
                <span matPrefix>
                  $
                </span>
                <input type="number" matInput [(ngModel)]="step.firstPartInspectionRate" name="firstPartInspectionRate"
                  [readonly]="!canEditWorkflowStep" />
              </mat-form-field>
            </div>
            <div class="px-2 mt-1 text-right" *ngIf="step.hasFirstPartInspection || step.hasInspection">
              <span class="font-weight-bold small">Total: {{ (this.firstPartInspectionCost) | currency }}</span>
            </div>
          <div class="border-bottom mb-1"></div>
          <div class="text-center position-relative px-2 mb-1">
            <div class="font-weight-medium">Full Inspection</div>
            <mat-checkbox class="mat-checkbox-no-margin mr-2" style="position: absolute; right: 0; top: -2px"
              [(ngModel)]="step.hasInspection" name="hasInspection"
              [class.mat-checkbox-readonly]="!canEditWorkflowStep"></mat-checkbox>
          </div>
            <div class="px-2 row m-0" [class.d-none]="!step.hasInspection">
              <div class="col-4 p-0">
                <mat-slide-toggle [(ngModel)]="step.inspectionIsBatched" name="inspectionIsBatched"
                  [class.mat-checkbox-readonly]="!canEditWorkflowStep">
                  Batch
                </mat-slide-toggle>
              </div>
              <div class="col-8 p-0">
                <mat-form-field class="w-100 dense-field mt-1 mat-input-no-message" appearance="outline"
                  [class.d-none]="!step.inspectionIsBatched">
                  <span matPrefix>
                    every
                  </span>
                  <input type="number" class="text-right pr-2" matInput [(ngModel)]="step.inspectionBatchSize"
                    name="inspectionBatchSize" [readonly]="!canEditWorkflowStep" />
                  <span matSuffix>
                    parts
                  </span>
                </mat-form-field>
              </div>
            </div>
            <div class="px-2" [class.d-none]="!step.hasInspection">
              <mat-form-field class="w-100 dense-field mt-1 mat-input-no-message" appearance="outline">
                <mat-label>Run Time</mat-label>
                <input type="number" matInput [(ngModel)]="step.inspectionTime" name="inspectionTime"
                  [readonly]="!canEditWorkflowStep" />
                <span matSuffix>
                  min
                </span>
              </mat-form-field>
            </div>
            <div class="px-2 mb-1" [class.d-none]="!step.hasInspection">
              <mat-form-field class="w-100 dense-field mt-1 mat-input-no-message" appearance="outline">
                <mat-label>Run Rate</mat-label>
                <span matPrefix>
                  $
                </span>
                <input type="number" matInput [(ngModel)]="step.inspectionRate" name="inspectionRate"
                  [readonly]="!canEditWorkflowStep" />
              </mat-form-field>
            </div>
            <div class="px-2 mt-1 text-right" *ngIf="step.hasFirstPartInspection || step.hasInspection">
              <span class="font-weight-bold small">Total: {{ (this.fullInspectionCost) | currency }}</span>
            </div>
          <div class="py-1 px-2 mt-1 border-top text-right" *ngIf="step.hasFirstPartInspection || step.hasInspection">
            <span class="font-weight-bold small">Grand Total: {{ (this.firstPartInspectionCost +
              this.fullInspectionCost) | currency }}</span>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="p-3 d-flex flex-column" *ngIf="getStation(step)?.isOutsourceStep">
    <div class="mb-2">
      <mat-checkbox 
        [(ngModel)]="step.requiresNoSpecifications"
        (ngModelChange)="$event && step.outsideProcessSpecifications = []"
        name="requiresNoSpecifications"
        [class.mat-checkbox-readonly]="!canEditWorkflowStep">
        No Specs Required
      </mat-checkbox>
    </div>
    <div class="mb-2">
      <specifications-input [editable]="canEditWorkflowStep" [stationId]="step.stationId"
        [disabled]="step.requiresNoSpecifications"
        [(ngModel)]="step.outsideProcessSpecifications" name="outsideProcessSpecifications"
        (createSpec)="onCreateSpec($event)" [unsavedSpecs]="unsavedSpecs"></specifications-input>
    </div>
    <div class="mb-2">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Outsource Markup</mat-label>
        <input type="number" matInput [(ngModel)]="step.outsourceMarkup" name="outsourceMarkup" [readonly]="!editing" />
        <span matSuffix>
          %
        </span>
      </mat-form-field>
    </div>
    <div class="mb-2">
      <!-- // TODO: adjust this to work in planning -->
      <item-rfq-request-status
          [record]="record" [product]="product"
          [workflowStep]="step"
          [purchasingRfqRequest]="step.purchasingRfqRequest"
          (requestCreated)="rfqRequestCreated.emit($event)"
      ></item-rfq-request-status>
    </div>
    <div>
      <product-quoting [order]="record" [stationId]="getStation(step)?.stationId" [(ngModel)]="step.selectedQuote"
        name="selectedQuote" (quoteSelected)="setOutsourcedStepPrice($event)" [editable]="editing"
        (addQuote)="this.addQuote.emit($event)"
        (quoteOpened)="this.quoteOpened.emit($event)"
        (quoteHistoryOpened)="this.quoteHistoryOpened.emit($event)"
        ></product-quoting>
    </div>
      <div class="col-12 mt-2">
        <div class="border rounded mb-2 pt-1 d-flex flex-column">
          <div class="text-center position-relative px-2 mb-1">
            <div class="font-weight-medium">Inspection</div>
          </div>
          <div class="px-2">
            <mat-checkbox class="small" [(ngModel)]="step.inspectionIsCMM" name="inspectionIsCMM"
              [class.mat-checkbox-readonly]="!canEditWorkflowStep">
              Inspection Uses CMM
            </mat-checkbox>
          </div>
          <div class="border-bottom mb-1"></div>
          <div class="text-center position-relative px-2 mb-1">
            <div class="font-weight-medium">First Part Inspection</div>
            <mat-checkbox class="mat-checkbox-no-margin mr-2" style="position: absolute; right: 0; top: -2px"
              [(ngModel)]="step.hasFirstPartInspection" name="hasFirstPartInspection"
              [class.mat-checkbox-readonly]="!canEditWorkflowStep"></mat-checkbox>
          </div>
            <div class="px-2" [class.d-none]="!step.hasFirstPartInspection">
              <mat-form-field class="w-100 dense-field mt-1 mat-input-no-message" appearance="outline">
                <mat-label>Run Time</mat-label>
                <input type="number" matInput [(ngModel)]="step.firstPartInspectionTime" name="firstPartInspectionTime"
                  [readonly]="!canEditWorkflowStep" />
                <span matSuffix>
                  min
                </span>
              </mat-form-field>
            </div>
            <div class="px-2 mb-1" [class.d-none]="!step.hasFirstPartInspection">
              <mat-form-field class="w-100 dense-field mt-1 mat-input-no-message" appearance="outline">
                <mat-label>Run Rate</mat-label>
                <span matPrefix>
                  $
                </span>
                <input type="number" matInput [(ngModel)]="step.firstPartInspectionRate" name="firstPartInspectionRate"
                  [readonly]="!canEditWorkflowStep" />
              </mat-form-field>
            </div>
            <div class="px-2 mt-1 text-right" *ngIf="step.hasFirstPartInspection || step.hasInspection">
              <span class="font-weight-bold small">Total: {{ (this.firstPartInspectionCost) | currency }}</span>
            </div>
          <div class="border-bottom mb-1"></div>
          <div class="text-center position-relative px-2 mb-1">
            <div class="font-weight-medium">Full Inspection</div>
            <mat-checkbox class="mat-checkbox-no-margin mr-2" style="position: absolute; right: 0; top: -2px"
              [(ngModel)]="step.hasInspection" name="hasInspection"
              [class.mat-checkbox-readonly]="!canEditWorkflowStep"></mat-checkbox>
          </div>
            <div class="px-2 row m-0" [class.d-none]="!step.hasInspection">
              <div class="col-4 p-0">
                <mat-slide-toggle [(ngModel)]="step.inspectionIsBatched" name="inspectionIsBatched"
                  [class.mat-checkbox-readonly]="!canEditWorkflowStep">
                  Batch
                </mat-slide-toggle>
              </div>
              <div class="col-8 p-0">
                <mat-form-field class="w-100 dense-field mt-1 mat-input-no-message" appearance="outline"
                  [class.d-none]="!step.inspectionIsBatched">
                  <span matPrefix>
                    every
                  </span>
                  <input type="number" class="text-right pr-2" matInput [(ngModel)]="step.inspectionBatchSize"
                    name="inspectionBatchSize" [readonly]="!canEditWorkflowStep" />
                  <span matSuffix>
                    parts
                  </span>
                </mat-form-field>
              </div>
            </div>
            <div class="px-2" [class.d-none]="!step.hasInspection">
              <mat-form-field class="w-100 dense-field mt-1 mat-input-no-message" appearance="outline">
                <mat-label>Run Time</mat-label>
                <input type="number" matInput [(ngModel)]="step.inspectionTime" name="inspectionTime"
                  [readonly]="!canEditWorkflowStep" />
                <span matSuffix>
                  min
                </span>
              </mat-form-field>
            </div>
            <div class="px-2 mb-1" [class.d-none]="!step.hasInspection">
              <mat-form-field class="w-100 dense-field mt-1 mat-input-no-message" appearance="outline">
                <mat-label>Run Rate</mat-label>
                <span matPrefix>
                  $
                </span>
                <input type="number" matInput [(ngModel)]="step.inspectionRate" name="inspectionRate"
                  [readonly]="!canEditWorkflowStep" />
              </mat-form-field>
            </div>
            <div class="px-2 mt-1 text-right" *ngIf="step.hasFirstPartInspection || step.hasInspection">
              <span class="font-weight-bold small">Total: {{ (this.fullInspectionCost) | currency }}</span>
            </div>
          <div class="py-1 px-2 mt-1 border-top text-right" *ngIf="step.hasFirstPartInspection || step.hasInspection">
            <span class="font-weight-bold small">Grand Total: {{ (this.firstPartInspectionCost +
              this.fullInspectionCost) | currency }}</span>
          </div>
        </div>
      </div>  </div>
  <div class="col-12">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Station/Process Notes</mat-label>
      <textarea matInput rows="6" [readonly]="!canEditWorkflowStep" [(ngModel)]="step.description"
        name="description"></textarea>
    </mat-form-field>
  </div>
  <div class="col-12" *ngIf="step.hasInspection || step.hasFirstPartInspection">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Inspection Notes</mat-label>
      <textarea matInput rows="6" [readonly]="!canEditWorkflowStep" [(ngModel)]="step.inspectionNotes"
        name="inspectionNotes"></textarea>
    </mat-form-field>
  </div>
</ng-container>
