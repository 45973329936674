import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ReportService } from '@cots/report/services/report.service';
import { NavigationService } from '../../../common/services/navigation.service';
import { exportCsv } from 'util/createCsv';

type RowData = {
  isHeader: false;
  quoteId: string;
  extPrice: number;
  customerPurchaseOrderExtPrice: number;
  finalExtPrice: number;
  customer: string;
  partNumber: string;
  status: string;
  requiredDate: Date;
  orderNumber: string;
  customerRfqNumber: string;
  contact: string;
  customerPurchaseOrderNumber: string;
  station: string;
}

type HeaderData = {
  isHeader: true;
  groupName: string;
  total: number;
  lines: number;
  rows: RowData[];
}

type Data = HeaderData | RowData;

type SearchFilter = {
  text: string;
  since: Date;
  until: Date;
  purchasedOnly: boolean;
  status: string[];
}

@Component({
  selector: 'finished-quote-report',
  templateUrl: './finished-quote-report.component.html',
  styleUrls: ['./finished-quote-report.component.less'],
})

export class FinishedQuoteReportComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('purchasedOnlyCheckbox') purchasedOnlyCheckbox: ElementRef;
  private minDate = new Date(-8640000000000000);
  private maxDate = new Date(8640000000000000);
  public since: Date = null;
  public until: Date = null;
  public statusSelect: string[] = ['Awaiting PO', 'PO Awarded'];
  public purchasedOnly: boolean = true;
  private now: Date = new Date();
  private lastFilteredData: RowData[];

  constructor(
    navService: NavigationService,
    private service: ReportService
  ) {
    navService.setPageTitle("Reports");
    navService.pushBreadcrumb('Finished Quotes');
  }

  public loading = false;
  public dataSource = new MatTableDataSource<Data>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.dataSource._pageData = this.dataToPage;
    this.dataSource.filterPredicate = this.search;
    this.sort.sort({ id: 'customer', start: 'asc', disableClear: false });
    this.getDetail();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public getDetail() {
    this.loading = true;
    this.service.getFinishedQuoteData().subscribe(data => {
      this.dataSource.data = this.dataToRows(data);
      this.onSearchChange();
      this.loading = false;
    })
  }

  dataToRows(apiData: RowData[]): Data[] {
    return apiData.map(d => ({
      isHeader: false,
      ...d,
      finalExtPrice: this.getExtPrice(d),
      requiredDate: d.requiredDate == null ? this.maxDate : new Date(d.requiredDate),
    }));
  }

  dataToPage = (rowData: RowData[]) => {
    this.lastFilteredData = rowData;

    if (rowData.length === 0)
      return []

    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    const endIndex = startIndex + this.paginator.pageSize;

    const pageData = rowData.slice(startIndex, endIndex).length > 0 ? rowData.slice(startIndex, endIndex) : rowData.slice(0, this.paginator.pageSize);

    let foundStart = false;
    let currentIndex = startIndex;
    let currentHeader: HeaderData = {
      isHeader: true,
      groupName: pageData[0].orderNumber,
      total: 0,
      lines: 0,
      rows: [],
    };

    // Work backwards from startIndex until finding one with a different order, adding values to the header row
    // This makes the first header include previous page data that fits the category but is cut off
    while (!foundStart && currentIndex > 0) {
      currentIndex -= 1;
      const currentRow = rowData[currentIndex];
      const thisOrder = currentRow.orderNumber;

      if (thisOrder === currentHeader.groupName) {
        currentHeader.lines += 1;
        currentHeader.total += currentRow.finalExtPrice;
        currentHeader.rows.push(currentRow);
      } else {
        foundStart = true;
      }
    }

    // Start real return object here, starting with the top header
    let finalRows: Data[] = [currentHeader];

    for (const row of pageData) {
      const thisOrder = row.orderNumber;

      // If a different date, set a new header
      if (thisOrder !== currentHeader.groupName) {
        currentHeader = {
          isHeader: true,
          groupName: thisOrder,
          total: 0,
          lines: 0,
          rows: [],
        }
        finalRows.push(currentHeader);
      }

      // Add stats and actual row to the most recent header
      currentHeader.lines += 1;
      currentHeader.total += row.finalExtPrice;
      currentHeader.rows.push(row);
      finalRows.push(row);
    }

    // Continue past the end until the date changes to add header info off the current page
    foundStart = false;
    currentIndex = endIndex - 1;
    while (!foundStart && currentIndex < (rowData.length - 1)) {
      currentIndex += 1;
      const currentRow = rowData[currentIndex];
      const thisOrder = currentRow.orderNumber;

      if (thisOrder === currentHeader.groupName) {
        currentHeader.lines += 1;
        currentHeader.total += currentRow.finalExtPrice;
        currentHeader.rows.push(currentRow);
      } else {
        foundStart = true;
      }
    }

    return finalRows;
  }

  // Search data
  public searchText = '';
  search = (data: Data, filterJson: string) => {
    const filter: SearchFilter = JSON.parse(filterJson);

    // Don't include empty headers
    if (data.isHeader && data.rows.length === 0)
      return false;

    // Don't include headers if all POs are filtered
    if (data.isHeader) {
      let rowMatch = false;
      for (const row of data.rows)
        if (filter.status.includes(row.status) && (!filter.purchasedOnly || row.customerPurchaseOrderNumber != null)) {
          rowMatch = true;
          break;
        }

      if (!rowMatch)
        return false;
    } else {
      if (data.isHeader === false && (
        // Exclude data that doesn't match the status selections
        !filter.status.includes(data.status) ||
        // Exclude data if purchasedOnly is checked and there's no PO
        (filter.purchasedOnly && data.customerPurchaseOrderNumber == null)
      ))
        return false;
    }

    filter.since = new Date(filter.since ?? this.minDate);
    filter.until = new Date(filter.until ?? this.maxDate);

    if (!this.isInDateRange(data, filter.since, filter.until))
      return false;

    const rowText = JSON.stringify(data).toLowerCase();
    const tokens = filter.text.split(' ');
    return tokens.every(token => rowText.includes(token.toLowerCase()));
  }

  isInDateRange(data: Data, since: Date, until: Date): boolean {
    if (data.isHeader && data.rows.length > 0) {

      for (const row of data.rows)
        if (row.requiredDate >= since && row.requiredDate <= until)
          return true;

      return false;
    } else if (data.isHeader === false) { // why this makes typescript behave and not (!data.isHeader) i do not know
      return (
        data.requiredDate >= since &&
        data.requiredDate <= until
      );
    }
  }

  onSearchChange() {
    this.paginator.pageIndex = 0;
    this.dataSource.filter = JSON.stringify({
      text: this.searchText,
      since: this.since,
      until: this.until,
      status: this.statusSelect,
      purchasedOnly: this.purchasedOnlyCheckbox['checked'],
    });
  }

  clearSearch() {
    this.searchText = '';
    this.since = null;
    this.until = null;
    this.dataSource.filter = '';
    this.purchasedOnlyCheckbox['checked'] = true;
  }

  // Utility
  public get rowDisplayedColumns() {
    return [
      'customer',
      'orderNumber',
      'customerRfqNumber',
      'customerPurchaseOrderNumber',
      'requiredDate',
      'contact',
      'partNumber',
      'finalExtPrice',
      'status',
    ];
  }

  public rowIsHeader(_: number, row: Data) {
    return row && row.isHeader;
  }

  public export() {
      const now = this.now;
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, "0");
      const day = (now.getDay() + 1).toString().padStart(2, "0");
  
      const dateString = `${year}_${month}_${day}`;
      const title = `FinishedQuoteReport_${dateString}`;
  
      const headers = [
        'Customer',
        'Order',
        'Customer RFQ',
        'Customer PO Number',
        'Due Date',
        'Buyer',
        'Part Number',
        'Ext Price',
        'Status',
      ];
  
      const data = [];
  
      for (let i = 0; i < this.lastFilteredData.length; i++) {
        const row = this.lastFilteredData[i];
        data.push([
          row.customer,
          row.orderNumber,
          row.customerRfqNumber,
          row.customerPurchaseOrderNumber,
          row.requiredDate !== this.minDate ? row.requiredDate : "",
          row.contact,
          row.station ? `${row.station} for ${row.partNumber}` : row.partNumber,
          row.finalExtPrice,
          row.status,
        ])
      }
  
      exportCsv(title, data, headers);
    }

    newTab(url: string) {
      window.open(url, '_blank');
    }

    hasValidDate(check: Date) {
      return check !== this.maxDate && check !== this.minDate;
    }

    getExtPrice(row: RowData) {
      return row.customerPurchaseOrderExtPrice === null ? row.extPrice : row.customerPurchaseOrderExtPrice;
    }

}
