import { User } from "@cots/common/resources/user";
import { Customer } from "@cots/customer/resources/customer";
import { EstimatingTaskStatus, OrderSegmentProductReviewStatus, OrderStatus } from "@cots/order/resources/order";
import { WorkflowStepPlanningStatus } from "@cots/order/resources/workflow";
import { WorkOrderOverview } from "@cots/planning/resources/work-order";
import { ContractReviewAnswersMap } from "@cots/quality/resources/quality-ticket";
import { assertUnreachable } from "util/assertUnreachable";

export interface SummaryOverview {
    dueDate: Date;
    customer: Customer;
    salesProcessId: string;
    rfq: SummaryRFQ;
    estimate: SummaryEstimate;
    quote: SummaryQuote;
    salesOrder: SummarySalesOrder;
    workOrderId: string | null;
}

export interface SummaryProduct {
    productId: string;
    partNumber: string;
    revision: string;
    reviewStatus: OrderSegmentProductReviewStatus;
    reviewNote: string;
  materialStatus: EstimatingTaskStatus;
  workflowStatus: EstimatingTaskStatus;
  hardwareStatus: EstimatingTaskStatus;
}
export interface SummaryRFQ {
    orderSegmentId: string;
    orderNumber: string;
    user: User;
    status: number;
    products: SummaryProduct[];
}

export interface SummaryEstimate {
    orderSegmentId: string;
    user: User;
    status: number;
    products: SummaryProduct[];
}
export interface SummaryQuote {
    orderSegmentId: string;
    user: User;
    status: number;
}

export interface SummarySalesOrder {
    rootWorkOrder: SummaryWorkOrder;
    childWorkOrders: SummaryWorkOrder[];
}

export enum SummaryStageProgress {
    BLOCKED = 0,
    PENDING = 1,
    WORK_STARTED = 2,
    FINISHED = 3,
}

function aggregateProgress(items: SummaryStageProgress[]): SummaryStageProgress {
    if (items.every(i => i === SummaryStageProgress.FINISHED)) return SummaryStageProgress.FINISHED;
    else if (items.some(i => i === SummaryStageProgress.FINISHED || i === SummaryStageProgress.WORK_STARTED)) return SummaryStageProgress.WORK_STARTED;
    else return SummaryStageProgress.PENDING;
}

export type SummaryWorkOrderStage = 
    'preplanning' | 'purchasing' | 'planning' | 'production' | 'inspection'
;

export function getWorkOrderStageProgress(item: SummaryWorkOrder, stage: SummaryWorkOrderStage): SummaryStageProgress {
    // This function assumes the work order exists at all
    if (item.status === OrderStatus.QC_HOLD) return SummaryStageProgress.BLOCKED;
    switch (stage) {
      case 'preplanning':
        if (isWOPastPreplanning(item)) return SummaryStageProgress.FINISHED;
        else return SummaryStageProgress.PENDING;
      case 'purchasing':
        if (!isWOPastPreplanning(item)) return SummaryStageProgress.BLOCKED;
        // TODO: return WORK_STARTED or FINISHED based on assignments
        return SummaryStageProgress.PENDING;
      case 'planning':
        if (!isWOPastPreplanning(item)) return SummaryStageProgress.BLOCKED;
        else if (isWOPastPlanning(item)) return SummaryStageProgress.FINISHED;
        else if (item.workflowSteps.some(s => s.planningStatus !== WorkflowStepPlanningStatus.UNPLANNED)) return SummaryStageProgress.WORK_STARTED;
        else return SummaryStageProgress.PENDING;
      case 'production':
        if (!isWOPastPlanning(item)) return SummaryStageProgress.BLOCKED;
        else return SummaryStageProgress.PENDING;
      case 'inspection':
        if (!isWOPastPlanning(item)) return SummaryStageProgress.BLOCKED;
        else return SummaryStageProgress.PENDING;
      default:
        assertUnreachable(stage);
    }
}

export type SummaryStage =
    'sales' |
    'estimating' |
    'quoting' |
    'contractReview' |
    SummaryWorkOrderStage |
    'shipping'
;

function getSalesProgress(item: SummaryOverview) {
    if (!item?.rfq?.products) return 0;
    const total = item.rfq.products.length;
    const done =  item.rfq.products.filter(p => {
      switch (p.reviewStatus) {
        case OrderSegmentProductReviewStatus.OnHold:
        case OrderSegmentProductReviewStatus.NotReviewed:
          return false;
        case OrderSegmentProductReviewStatus.Incomplete:
        case OrderSegmentProductReviewStatus.Corrected:
        case OrderSegmentProductReviewStatus.Complete:
          return true;
        default:
          assertUnreachable(p.reviewStatus);
      }
    }).length;
    return (done / total) * 100;
}

export function getAllSummaryWorkOrders(item: SummaryOverview) {
    return [...(item.salesOrder?.rootWorkOrder ? [item.salesOrder?.rootWorkOrder] : []), ...(item.salesOrder?.childWorkOrders ?? [])];
}

export function getSummaryStageProgress(item: SummaryOverview, stage: SummaryStage): SummaryStageProgress {
    switch (stage) {
      case 'sales':
        if (
            item.rfq?.status === OrderStatus.FULFILLED ||
            item.rfq?.status === OrderStatus.AWAIT_ISSUANCE ||
            item.rfq?.status === OrderStatus.AWAIT_ESTIMATE
        ) return SummaryStageProgress.FINISHED;
        const salesProgress = getSalesProgress(item);
        if (salesProgress === 0) return SummaryStageProgress.PENDING;
        else return SummaryStageProgress.WORK_STARTED;
      case 'estimating':
        if (
            getSummaryStageProgress(item, 'sales') !== SummaryStageProgress.FINISHED
            || !item.estimate || !item.estimate.products
        ) return SummaryStageProgress.BLOCKED;
        if (item.estimate?.status === OrderStatus.APPROVED || item.estimate?.status === OrderStatus.TENT_APPROVED) return SummaryStageProgress.FINISHED;
        const estimatingProgress = item.estimate.products.reduce((acc, x) => {
          if (EstimatingTaskStatus.countsAsDone(x.materialStatus) || EstimatingTaskStatus.countsAsInProgress(x.materialStatus)) acc += 1;
          if (EstimatingTaskStatus.countsAsDone(x.workflowStatus) || EstimatingTaskStatus.countsAsInProgress(x.workflowStatus)) acc += 1;
          if (EstimatingTaskStatus.countsAsDone(x.hardwareStatus) || EstimatingTaskStatus.countsAsInProgress(x.hardwareStatus)) acc += 1;
          return acc;
        }, 0);
        // TODO: Consider QA status
        if (estimatingProgress === 0) return SummaryStageProgress.PENDING;
        else return SummaryStageProgress.WORK_STARTED;
      case 'quoting':
        if (getSummaryStageProgress(item, 'estimating') !== SummaryStageProgress.FINISHED || !item.quote) return SummaryStageProgress.BLOCKED;
        if (item.quote?.status === OrderStatus.PO_AWARDED) return SummaryStageProgress.FINISHED;
        else if (item.quote.status === OrderStatus.AWAIT_ISSUANCE) return SummaryStageProgress.PENDING;
        else return SummaryStageProgress.WORK_STARTED;
      case 'contractReview':
        if (item.salesOrder?.rootWorkOrder?.contractReviewTicket.status === OrderStatus.APPROVED) return SummaryStageProgress.FINISHED;
        const contractReviewAnswersJson = item?.salesOrder?.rootWorkOrder?.contractReviewTicket?.contractReviewAnswers as any as string;
        if (!contractReviewAnswersJson) return SummaryStageProgress.BLOCKED;
        const contractReviewAnswers: ContractReviewAnswersMap = JSON.parse(contractReviewAnswersJson);
        const anyChecked = Object.values(contractReviewAnswers).map(o => Object.values(o).map(o => Object.values(o))).some(i => !!i);
        if (anyChecked) return SummaryStageProgress.WORK_STARTED;
        else return SummaryStageProgress.PENDING;
      case 'preplanning':
      case 'purchasing':
      case 'planning':
      case 'production':
      case 'inspection':
        if (getSummaryStageProgress(item, 'contractReview') !== SummaryStageProgress.FINISHED || !item.salesOrder?.rootWorkOrder) return SummaryStageProgress.BLOCKED;
        const wos = getAllSummaryWorkOrders(item);
        return aggregateProgress(wos.map(w => getWorkOrderStageProgress(w, stage)));
      case 'shipping':
        // TODO
        return SummaryStageProgress.BLOCKED
      default:
        assertUnreachable(stage);
    }
}


export type SummaryWorkOrder = WorkOrderOverview;

export function isWOPastPreplanning(item: SummaryWorkOrder) {
    return item.status === OrderStatus.IN_PROCESS || item.status === OrderStatus.PLANNED || item.status === OrderStatus.ON_FLOOR || item.status === OrderStatus.FULFILLED || item.status === OrderStatus.SHIPPING;
}
export function isWOPastPlanning(item: SummaryWorkOrder) {
    return item.status === OrderStatus.PLANNED || item.status === OrderStatus.ON_FLOOR || item.status === OrderStatus.FULFILLED || item.status === OrderStatus.SHIPPING;
}

export function getLatestVisibleStage(item: SummaryOverview): SummaryStage {
  const stages: SummaryStage[] = ['sales',
    'estimating',
    'quoting',
    'contractReview',
    'preplanning', 'purchasing', 'planning', 'production', 'inspection',
    'shipping']
  ;
  for (const stage of stages.reverse()) {
    const prog = getSummaryStageProgress(item, stage);
    if (prog !== SummaryStageProgress.BLOCKED) return stage;
  }
  return 'sales';
}

export function getProgressScore(item: SummaryOverview) {
  const stages: SummaryStage[] = ['sales',
    'estimating',
    'quoting',
    'contractReview',
    'preplanning', 'purchasing', 'planning', 'production', 'inspection',
    'shipping']
  ;
  let total = 0;
  for (const stage of stages) {
    const stageProgress = getSummaryStageProgress(item, stage);
    switch (stageProgress) {
      case SummaryStageProgress.BLOCKED:
        break;
      case SummaryStageProgress.PENDING:
        total += 1;
        break;
      case SummaryStageProgress.WORK_STARTED:
        total += 2;
        break;
      case SummaryStageProgress.FINISHED:
        total += 3;
        break;
    }
  }
  return total;
}