import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from '../../common/services/errorHandler.service';
import { httpService } from '../../common/services/http.service';
import { MessageService } from '../../common/services/message.service';

@Injectable({
  providedIn: 'root',
})
export class ReportService extends httpService {
  private apiBase: string = 'api/report';
  private apiUrl: string;

  constructor(errorHandler: ErrorHandlerService, private messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    super(errorHandler, messages);
    this.serviceName = "Report";

    this.apiUrl = this.baseUrl + this.apiBase;
  }

  public getOpenOrderShipmentPrices() {
    return this.http.get<Document>(this.apiUrl + '/openOrderShipmentPrices/').pipe(
      catchError(this.handleError<any>("Get Open Order Shipment Prices Report Data", null))
    );
  }

  public getActiveOutsourceData() {
    return this.http.get<Document>(this.apiUrl + '/activeOutsourceData/').pipe(
      catchError(this.handleError<any>("Get Active Outsource Report Data", null))
    );
  }

  public getBookingsData() {
    return this.http.get<Document>(this.apiUrl + '/bookingsData/').pipe(
      catchError(this.handleError<any>("Get Bookings Report Data", null))
    );
  }

  public getCustomerSalesShares() {
    return this.http.get<Document>(this.apiUrl + '/customerSalesShare/').pipe(
      catchError(this.handleError<any>("Get Customer Sales Share Report", null))
    );
  }

  public getPurchasingCategories() {
    return this.http.get<Document>(this.apiUrl + '/purchasingCategories/').pipe(
      catchError(this.handleError<any>("Get Purchasing Categories Data", null))
    );
  }

  public getOnTimeProcessingData() {
    return this.http.get<Document>(this.apiUrl + '/onTimeProcessing/').pipe(
      catchError(this.handleError<any>("Get On Time Processing Data", null))
    );
  }

  public getCostReportData(orderId: string) {
    return this.http.get<Document>(this.apiUrl + '/costReportData/' + orderId).pipe(
      catchError(this.handleError<any>("Get Cost Report Data", null))
    );
  }

  public getFinishedQuoteData() {
    return this.http.get<Document>(this.apiUrl + '/finishedQuoteReport/').pipe(
      catchError(this.handleError<any>("Get Finished Quote Data", null))
    );
  }

}
