<div class="row m-0 align-items-start"  ngForm #form="ngForm">
    <div class="col-8 row m-0">
        <div class="col-8">
            <purchased-item-search class="cursor-pointer" (click)="this.changeItem.emit(this.item)" [readonly]="true" name="purchasedItem" [ngModel]="item.purchasedItem"
                (ngModelChange)="item.purchasedItemId = $event.purchasedItemId"></purchased-item-search>
        </div>
        <div class="col-4">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Quantity</mat-label>
                <input type="number" matInput name="quantity" [ngModel]="item.quantity" [readonly]="!editing" />
            </mat-form-field>
        </div>
        <div class="col-6">
            <mat-button-toggle-group [disabled]="!editing" aria-label="Font Style" name="isNonRecurring"
                [ngModel]="item.isNonRecurring">
                <mat-button-toggle value="everytime" [value]="false">Every Time</mat-button-toggle>
                <mat-button-toggle value="amortized" [value]="true">Non-recurring</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div class="col-6">
            <mat-button-toggle-group [disabled]="!editing" aria-label="Font Style" name="isAmortized"
                [ngModel]="item.isAmortized" [class.d-none]="!item.isNonRecurring">
                <mat-button-toggle value="amortized" [value]="true">Amortized</mat-button-toggle>
                <mat-button-toggle value="everytime" [value]="false">Non-amortized</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div class="col-12">
            <div class="mat-dialog-title">Microtickets for Purchased Item</div>
        </div>
        <div class="col-12 mt-3 mb-1" *ngLet="service.postChangesOrder$ | async as record">
            <item-rfq-request-status
                [record]="record" [product]="product"
                [productPurchasedItem]="item"
                [purchasingRfqRequest]="item.purchasingRfqRequest"
                (requestCreated)="onPurchasingRfqRequestCreated($event)"
            ></item-rfq-request-status>
        </div>
        <mat-divider class="mb-4 mt-2 mx-3 w-100"></mat-divider>
        <div class="col-12 mt-1">
            <microticket-subitem-viewer *ngLet="service.postChangesOrder$ | async as record"
                [relatedTicketId]="record.salesProcessId" [relatedTicketName]="service.getNameForMicrotickets(record)"
                [relatedTicketType]="'SalesProcess'" [filterKey]="service.getMicroticketFilterKey(record)"
                [subItem]="service.generateSubItemNavigationId(product.productId, 'hardware', item.productPurchasedItemId)"
                (loadingChange)="service.loading = $event"
                [microTickets]="service.sharedMicroticketSubject"></microticket-subitem-viewer>
        </div>
    </div>
    <div class="col-4">
        <div class="mb-2" *ngIf="item.purchasedItem">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Outsource Markup</mat-label>
                <input type="number" matInput name="markupPercent" [ngModel]="item.markupPercent"
                    [readonly]="!editing" />
                <span matSuffix>
                    %
                </span>
            </mat-form-field>
        </div>
        <product-quoting *ngIf="item.purchasedItem" [order]="service.postChangesOrder$ | async"
            [purchasedItemId]="item.purchasedItem.purchasedItemId" name="selectedQuote" [ngModel]="item.selectedQuote"
            (quoteSelected)="setItemPrice(product, item, $event)" [editable]="editing"
            (quoteOpened)="this.service.openSidenav('quoteDetail', $event)"
            (quoteHistoryOpened)="this.service.openSidenav('quoteHistory', $event)"
            ></product-quoting>
    </div>
</div>