
<summary-step-data [user]="record.user" [status]="record.status"></summary-step-data>
<div class="mat-small-header mb-2 px-3">Estimating Progress per Part</div>
<mat-list class="pt-0 border-top list">
  <mat-list-item *ngFor="let product of sortedProducts" class="border-bottom item-outer">
    <div class="w-100">
      <div class="w-100 d-flex align-items-center item">
        <mat-icon class="mr-2" svgIcon="open-in-new"></mat-icon>
        <a class="text-dark flex-grow-0 font-weight-bold" [href]="'/estimating/' + record.orderSegmentId + '#' + product.productId" target="_blank"
          [innerHTML]=" (product.partNumber + ' Rev. ' + product.revision) | highlight: highlightFilter : '\<span class=\'msr-highlight\'\>' : '\</span\>' "
        ></a>
        <div class="ml-4 rounded border d-inline-flex">
          <div class="border-right indicator-column">
            <div class="font-weight-bold" matTooltip="Material">
              M
            </div>
            <div class="indicator" [ngClass]="getTaskStatusColorClass(product.materialStatus)">&nbsp;</div>
          </div>
          <div class="border-right indicator-column">
            <div class="font-weight-bold" matTooltip="Workflow">
              W
            </div>
            <div class="indicator" [ngClass]="getTaskStatusColorClass(product.workflowStatus)">&nbsp;</div>
          </div>
          <div class="border-right indicator-column">
            <div class="font-weight-bold" matTooltip="Hardware">
              H
            </div>
            <div class="indicator" [ngClass]="getTaskStatusColorClass(product.hardwareStatus)">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
  </mat-list-item>
</mat-list>
