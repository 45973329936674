<div class="d-flex buttons-holder" [class.editing]="editing" style="position: absolute;
top: 1.75em;
right: 1em;
z-index: 15;">
  <button mat-fab class="extended-fab-button bg-primary text-white mr-2" (click)="addItem()">
    <mat-icon>add</mat-icon>
    <span class="extended-fab-button__text">Add Line Item</span>
  </button>
</div>
<table mat-table #lineItemsTable cdkDropList (cdkDropListDropped)="drop($event)" [dataSource]="records" #quoteLineItemsTable="matTable" class="w-100 sticky-table position-relative" [class.editing]="editing">

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef style="width: 30%">Item</th>
    <td mat-cell *matCellDef="let item"
      style="max-width: 30%;
      text-overflow: ellipsis;
      width: 30%;
      overflow: hidden;
      padding-right: 0.5rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      position: relative;
      "
      >
        <div class="d-flex align-items-center">
          <item-type-chip [item]="item" [iconOnly]="true" class="mr-2"></item-type-chip>
          <span class="font-weight-bold">
            <ng-container *ngIf="!item.outsideProcessDescriptionId">
              {{item?.name}}
            </ng-container>
            <ng-container *ngIf="item.outsideProcessDescriptionId">
              {{opShortName(item.outsideProcessDescription)}}
            </ng-container>
          </span>
          <button mat-icon-button class="ml-2" (click)="toggleOpDisplay(item)" *ngIf="item.outsideProcessDescriptionId">
            <mat-icon svgIcon="eye" class="text-muted"
              *ngIf="!this.opSelection.isSelected(item.purchaseOrderLineItemId)"
              matTooltip="Show Full Process"
            ></mat-icon>
            <mat-icon svgIcon="eye-off" class="text-muted"
              *ngIf="this.opSelection.isSelected(item.purchaseOrderLineItemId)"
              matTooltip="Hide Full Process"
            ></mat-icon>
          </button>
          <button mat-icon-button class="ml-2" (click)="editOutsideProcess(item)" *ngIf="editing && item.outsideProcessDescriptionId">
            <mat-icon>edit</mat-icon>
          </button>
        </div>
        <div style="white-space: pre; flex-basis: 100%; max-width: 100%; text-wrap: wrap" class="font-weight-medium mt-2" *ngIf="item.outsideProcessDescriptionId && this.opSelection.isSelected(item.purchaseOrderLineItemId)">
          {{ opSteps(item.outsideProcessDescription) }}
        </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="miscName">
    <th mat-header-cell *matHeaderCellDef style="width: 30%">Item</th>
    <td mat-cell *matCellDef="let item; let i=index"
      style="max-width: 30%;
      text-overflow: ellipsis;
      width: 30%;
      overflow: hidden;
      padding-right: 0.5rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      position: relative;
      "
      >
      <mat-form-field appearance="outline" class="dense-field w-100">
        <input [readonly]="!editing" matInput autofocus type="text" [(ngModel)]="item.miscDescription" [name]="'purchaseOrderLineItems[' + i + '].miscDescription'" />
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="wo">
    <th mat-header-cell *matHeaderCellDef style="width: 10%">Work Order/Department</th>
    <td mat-cell *matCellDef="let item">
      <div class="d-flex align-items-center pl-1">
        <span class="small" *ngIf="item.workOrder">
          WO #{{ item.workOrder.workOrderNumber }}
        </span>
        <span class="small" *ngIf="!item.workOrder && item.department">
          {{ item.department }}
        </span>
        <span class="small" *ngIf="!item.workOrder && !item.department && item.legacyWorkOrderId">
          {{ item.legacyWorkOrderId.join(' / ') }}
        </span>
        <span class="small text-muted" *ngIf="!item.workOrder && !item.department && item.legacyWorkOrderId.length === 0">
          --
        </span>
        <button mat-icon-button class="ml-auto" (click)="editWoDepartment(item)" *ngIf="editing">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="duedate">
    <th mat-header-cell *matHeaderCellDef width="8%">Due Date</th>
    <td mat-cell *matCellDef="let item; let i=index" class="p-1">
      <date-picker [dense]="true" [readonly]="!editing" appearance="outline" [required]="false" [placeholder]="'Due Date'" [editable]="true" [(ngModel)]="item.dueDate" [name]="'purchaseOrderLineItems[' + i + '].dueDate'"></date-picker>
    </td>
  </ng-container>

  <ng-container matColumnDef="taxable">
    <th mat-header-cell *matHeaderCellDef style="width: 10%">&nbsp;</th>
    <td mat-cell *matCellDef="let item; let i=index" class="p-1">
      <mat-checkbox class="float-right" [class.mat-checkbox-readonly]="!editing" [(ngModel)]="item.taxable" [name]="'purchaseOrderLineItems[' + i + '].taxable'">Taxable</mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="unitPrice">
    <th mat-header-cell *matHeaderCellDef style="width: 10%;">Unit Price</th>
    <td mat-cell *matCellDef="let item; let i=index" class="p-1">
      <span *ngIf="!item.materialBid && (item.unitPriceOverride === null || item.unitPriceOverride === undefined)" class="text-muted">No Quote</span>
      <span *ngIf="item.materialBid && (item.unitPriceOverride === null || item.unitPriceOverride === undefined)">{{ getLineItemUnitPrice(item) | currency }}</span>
      <span *ngIf="item.materialBid && (item.unitPriceOverride === null || item.unitPriceOverride === undefined)" class="btn btn-sm btn-link ml-2" (click)="showQuoteDetail(item.materialBidId)">View Quote</span>
      <div *ngIf="item.unitPriceOverride !== null && item.unitPriceOverride !== undefined">
        <mat-form-field appearance="outline" class="dense-field mat-input-no-message">
          <span matPrefix>$</span>
          <input [readonly]="!editing" matInput autofocus type="number" min="0" step="0.00001" [(ngModel)]="item.unitPriceOverride" [name]="'purchaseOrderLineItems[' + i + '].unitPriceOverride'" />
        </mat-form-field>
        <button mat-icon-button class="ml-1" (click)="removePricing(item)" *ngIf="editing"><mat-icon>close</mat-icon></button>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="miscPrice">
    <td mat-cell *matCellDef="let item; let i=index" class="p-1" colspan="2">
      <div>
        <mat-form-field appearance="outline" class="dense-field mat-input-no-message">
          <span matPrefix>$</span>
          <input [readonly]="!editing" matInput autofocus type="number" min="0" step="0.00001" [(ngModel)]="item.unitPriceOverride" [name]="'purchaseOrderLineItems[' + i + '].unitPriceOverride'" />
        </mat-form-field>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="quote">
    <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
    <td mat-cell *matCellDef="let item" class="p-1">
      <button *ngIf="(item.unitPriceOverride === null || item.unitPriceOverride === undefined) && editing" mat-flat-button [class.bg-dark]="item.materialBid" [class.bg-primary]="!item.materialBid" class="text-white mat-button-text ml-1" [matMenuTriggerFor]="quoteMenu">
        <mat-icon>edit</mat-icon>
      </button>
      <mat-menu #quoteMenu="matMenu">
        <button mat-menu-item [disabled]="getExistingBidsMinusCurrent(item).length === 0" (click)="selectExistingQuote(item)">
          <mat-icon matPrefix class="text-muted">
            list_alt
          </mat-icon>
          Select Existing Quote...
        </button>
        <button mat-menu-item (click)="inputPricing(item)">
          <mat-icon matPrefix class="text-muted">
            edit
          </mat-icon>
          Input Pricing
        </button>
        <button mat-menu-item (click)="quickQuote(item)">
          <mat-icon matPrefix class="text-muted">
            add
          </mat-icon>
          New Quick Quote...
        </button>
      </mat-menu>
    </td>
  </ng-container>

  <ng-container matColumnDef="unitsweight">
    <th mat-header-cell *matHeaderCellDef style="width: 7.5%">Units/Weight</th>
    <td mat-cell *matCellDef="let item; let i = index" class="p-1">
      <mat-form-field appearance="outline" class="w-100 mat-input-no-message dense-field">
        <input [readonly]="!editing" type="number" min="0" step="1" matInput
          [(ngModel)]="item.mfgrUnitsQty" [name]="'purchaseOrderLineItems[' + i + '].unitsWeight'" [placeholder]="item.quantity ?? ''" />
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="quantity">
    <th mat-header-cell *matHeaderCellDef style="width: 5%">Quantity</th>
    <td mat-cell *matCellDef="let item; let i = index" class="p-1" style="width: 4%">
      <mat-form-field appearance="outline" class="w-100 mat-input-no-message dense-field">
        <input [readonly]="!editing" matInput type="number" step="1" min="0"
          [(ngModel)]="item.quantity" [name]="'purchaseOrderLineItems[' + i + '].quantity'" notNullValidator />
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="extprice">
    <th mat-header-cell *matHeaderCellDef>Ext. Price</th>
    <td mat-cell *matCellDef="let item" class="p-1" style="width: 6%">
      <span *ngIf="!item.materialBid && item.unitPriceOverride === null">&nbsp;</span>
      <span *ngIf="item.materialBid || (item.unitPriceOverride !== null)">{{ getLineItemExtPrice(item) | currency }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="split">
    <th mat-header-cell *matHeaderCellDef style="width: 10%">&nbsp;</th>
    <td mat-cell *matCellDef="let item" class="text-center">
      <button mat-flat-button class="bg-primary text-white mat-button-text button-square" title="Split Line Item" (click)="split(item)" *ngIf="editing">
        <mat-icon>call_split</mat-icon>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="delete">
    <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
    <td mat-cell *matCellDef="let item; let i = index" class="text-center">
      <button mat-flat-button class="bg-danger text-white mat-button-text button-square" title="Remove Line Item" (click)="remove(item, i)" *ngIf="editing">
        <mat-icon>delete</mat-icon>
      </button>
      <button mat-flat-button class="bg-danger text-white mat-button-text button-square ml-2" title="Print OSP Label" *ngIf="item.outsideProcessDescription" (click)="getOSPLabel(item)">
        <mat-icon svgIcon="receipt-text"></mat-icon>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="subtotalSpace">
    <td mat-footer-cell [attr.colspan]="displayedColumns.length - 2" *matFooterCellDef>
      &nbsp;
    </td>
  </ng-container>
  <ng-container matColumnDef="subtotalHeader">
    <td mat-footer-cell *matFooterCellDef>
      <b>Subtotal</b>
    </td>
  </ng-container>
  <ng-container matColumnDef="subtotal">
    <td mat-footer-cell *matFooterCellDef>
      {{ subtotal | currency }}
    </td>
  </ng-container>

  <ng-container matColumnDef="shippingSpace">
    <td mat-footer-cell [attr.colspan]="displayedColumns.length - 3" *matFooterCellDef>
      &nbsp;
    </td>
  </ng-container>
  <ng-container matColumnDef="shippingTaxable">
    <td mat-footer-cell *matFooterCellDef>
      <mat-checkbox class="float-right mr-1" [class.mat-checkbox-readonly]="!editing" [(ngModel)]="purchaseOrder.shippingTaxable" name="shippingTaxable">Taxable</mat-checkbox>
    </td>
  </ng-container>
  <ng-container matColumnDef="shippingHeader">
    <td mat-footer-cell *matFooterCellDef>
      <b>Shipping/Handling</b>
    </td>
  </ng-container>
  <ng-container matColumnDef="shipping">
    <td mat-footer-cell *matFooterCellDef>
      <mat-form-field class="dense-field" appearance="outline">
        <span matPrefix>$</span>
        <input [readonly]="!editing" matInput type="number" [(ngModel)]="purchaseOrder.shippingHandlingFees" name="shippingHandlingFees" min="0" step="0.1" />
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="taxRateSpace">
    <td mat-footer-cell [attr.colspan]="displayedColumns.length - 2" *matFooterCellDef>
      &nbsp;
    </td>
  </ng-container>
  <ng-container matColumnDef="taxRateHeader">
    <td mat-footer-cell *matFooterCellDef>
      <b>Tax Rate</b>
    </td>
  </ng-container>
  <ng-container matColumnDef="taxRate">
    <td mat-footer-cell *matFooterCellDef>
      <mat-form-field class="dense-field" appearance="outline">
        <input [readonly]="!editing" matInput type="number" [(ngModel)]="purchaseOrder.taxRate" name="taxRate" min="0" step="0.1" />
        <span matSuffix>%</span>
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="taxesSpace">
    <td mat-footer-cell [attr.colspan]="displayedColumns.length - 2" *matFooterCellDef>
      &nbsp;
    </td>
  </ng-container>
  <ng-container matColumnDef="taxesHeader">
    <td mat-footer-cell *matFooterCellDef>
      <b>Tax</b>
    </td>
  </ng-container>
  <ng-container matColumnDef="taxes">
    <td mat-footer-cell *matFooterCellDef>
      {{ taxes | currency }}
    </td>
  </ng-container>

  <ng-container matColumnDef="totalSpace">
    <td mat-footer-cell [attr.colspan]="displayedColumns.length - 2" *matFooterCellDef>
      &nbsp;
    </td>
  </ng-container>
  <ng-container matColumnDef="totalHeader">
    <td mat-footer-cell *matFooterCellDef>
      <b>Total</b>
    </td>
  </ng-container>
  <ng-container matColumnDef="total">
    <td mat-footer-cell *matFooterCellDef>
      {{ total | currency }}
    </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns">
  </tr>
  <tr mat-row cdkDrag [cdkDragDisabled]="!editing" *matRowDef="let item; columns: displayedColumnsMisc; when: isMisc"></tr>
  <tr mat-row cdkDrag [cdkDragDisabled]="!editing" *matRowDef="let item; columns: displayedColumns;"></tr>
  <!-- <!-- <tr mat-footer-row *matFooterRowDef="['subtotalSpace', 'subtotalHeader', 'subtotal']"></tr> -->
  <tr mat-footer-row *matFooterRowDef="['shippingSpace', 'shippingTaxable', 'shippingHeader', 'shipping']"></tr>
  <tr mat-footer-row *matFooterRowDef="['taxRateSpace', 'taxRateHeader', 'taxRate']"></tr>
  <tr mat-footer-row *matFooterRowDef="['taxesSpace', 'taxesHeader', 'taxes']"></tr>
  <tr mat-footer-row *matFooterRowDef="['totalSpace', 'totalHeader', 'total']"></tr> -->
</table>

<ng-template #newItemDialogTemplate>
  <new-line-item-select
    [(item)]="newItem" [purchaseOrder]="purchaseOrder">
  </new-line-item-select>
  <mat-dialog-actions align="end">
    <button mat-flat-button class="button-text" color="primary" matDialogClose (click)="addLineItem(newItem)" 
    [disabled]="!(newItem.outsideProcess && outsideProcessValid(newItem.outsideProcess)) && !newItem.material && !newItem.purchasedItemPartNumber && !newItem.misc"
  >Add Item</button>
    <button mat-flat-button class="button-text" matDialogClose>Cancel</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #splitItemDialogTemplate let-data>
  <h2 class="mb-0" mat-dialog-title>Split Line Item</h2>
  <p mat-card-subtitle>{{ data.item.name }}</p>
  <mat-dialog-content class="mat-typography">
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label>Amount to Split</label>
          <input min="1" [max]="data.item.quantity - 1" step="1"
        [(ngModel)]="data.splitAmount" required type="number" class="form-control form-control-sm" appStaticEdit
        [editable]="true" />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>Due Date</label>
          <date-picker [(date)]="data.dueDate" [placeholder]="'Due Date'" [editable]="true">
          </date-picker>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="btn btn-default btn-sm mr-3" [matDialogClose]="null">Cancel</button>
    <button [disabled]="!data.splitAmount || (data.splitAmount > data.item.quantity - 1) || (data.splitAmount < 1)" [matDialogClose]="data" class="btn btn-default btn-sm mr-3">Split</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #selectQuoteDialogTemplate let-data>
  <h2>Select an Existing Quote</h2>
  <p mat-card-subtitle>For Item: {{ data.item.name }} <item-type-chip *ngIf="!data.item.isMisc" [iconOnly]="true" [item]="data.item"></item-type-chip></p>
  <mat-dialog-content>
    <mat-selection-list class="border rounded quote-selection-list" style="height: 30vh; overflow-y: scroll;" #list (selectionChange)="list.selectedOptions.clear(); list.selectedOptions.select($event.option); data.selectedBid = $event.option.value">
      <mat-list-option #option *ngFor="let bid of data.bids" checkboxPosition="before" [value]="bid" [class.option-selected]="option.selected">
        <div class="d-flex justify-content-evenly">
          <div>
            <div class="mat-line small text-muted">
              Quantity
            </div>
            <div class="mat-line font-weight-bold text-right">
              {{ bid.qty }}
            </div>
          </div>
          <div class="ml-2" *ngIf="bid.perItemBid">
            <div class="mat-line small text-muted">
              Price/Unit
            </div>
            <div class="mat-line font-weight-bold">
              {{ bid.perItemBid | currency }}
            </div>
          </div>
          <div class="ml-2" *ngIf="!bid.perItemBid && bid.totalBid">
            <div class="mat-line small text-muted">
              Total Price
            </div>
            <div class="mat-line font-weight-bold">
              {{ bid.totalBid | currency }}
            </div>
          </div>
          <div class="ml-auto">
            <div class="mat-line small text-muted">
              Received On
            </div>
            <div class="mat-line font-weight-bold text-right">
              {{ bid.answered | date: 'shortDate' }}
            </div>
          </div>
        </div>
      </mat-list-option>
    </mat-selection-list>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button class="btn btn-default btn-sm mr-3" [matDialogClose]="null">Cancel</button>
    <button mat-button [disabled]="!data.selectedBid" [matDialogClose]="data.selectedBid">Select Quote</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #selectWoDepartmentDialogTemplate let-data>
  <h2>Select WO# or Department</h2>
  <p mat-card-subtitle>For Item: {{ data.item.name }} <item-type-chip *ngIf="!data.item.isMisc" [iconOnly]="true" [item]="data.item"></item-type-chip></p>
  <mat-dialog-content>
    <div>
      <mat-radio-group [(ngModel)]="data.type" [ngModelOptions]="{standalone: true}">
        <mat-radio-button value="workOrder" class="mr-2">
          Work Order #
        </mat-radio-button>
        <mat-radio-button value="legacy" class="mr-2">
          Legacy Work Order
        </mat-radio-button>
        <mat-radio-button value="department">
          Department Code
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div *ngIf="data.type === 'workOrder'">
      <work-order-search [(selectedItem)]="data.workOrder"></work-order-search>
    </div>
    <div *ngIf="data.type === 'department'">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Department Code</mat-label>
        <input matInput type="text" placeholder="Department Code" required [(ngModel)]="data.department" [ngModelOptions]="{standalone: true}">
      </mat-form-field>
    </div>
    <div *ngIf="data.type === 'legacy'">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Legacy Work Order Number</mat-label>
        <mat-chip-list #legacyChips>
          <mat-chip
            *ngFor="let legacyWorkOrder of data.item.legacyWorkOrderId"
            (removed)="removeLegacyWorkOrder(data.item, legacyWorkOrder)"
            disableRipple
          >
          {{ legacyWorkOrder }}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
          </mat-chip>
        </mat-chip-list>
        <input
          [matChipInputFor]="legacyChips"
          [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
          type="text"
          placeholder="Enter number..."
          [(ngModel)]="legacyWorkOrderInput"
          [ngModelOptions]="{standalone: true}"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="addLegacyWorkOrder(data.item, $event)"
        >
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button class="btn btn-default btn-sm mr-3" [matDialogClose]="null">Cancel</button>
    <button mat-button [disabled]="(data.type === 'workOrder' && !data.workOrder) || (data.type === 'department' && (!data.department || data.department.length === 0))" [matDialogClose]="{ type: data.type, workOrder: data.workOrder, department: data.department }">Save</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #editOutsideProcessNotesDialogTemplate let-data>
  <po-outside-process-editor [(ngModel)]="data.process" [ngModelOptions]="{standalone: true}"></po-outside-process-editor>
  <mat-dialog-actions align="end">
    <button mat-flat-button class="button-text" color="primary" [disabled]="!outsideProcessValid(data.process)" [matDialogClose]="data.process">Save Changes</button>
    <button mat-flat-button class="button-text" [matDialogClose]="null">Cancel</button>
  </mat-dialog-actions>
</ng-template>