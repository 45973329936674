<search-list-new
  matSort
  [defaultSort]="{
    id: 'dueDate',
    start: 'asc'
  }"
  [showManagerToggle]="true"
  [resultGetter]="search.bind(this)"
  [displayedColumns]="['ticketNumber', 'workOrderNumber', 'dueDate', 'dateAssigned', 'promiseDate', 'late', 'user', 'customer', 'poNumber', 'planningProgress', 'status']"
  (itemSelected)="onSelect($event)"
  [fieldSearchFields]="[
    { field: 'customer', code: 'customer' },
    { field: 'poNumber', code: 'po' },
    { field: 'poNumber', code: 'number' },
    { field: 'woNumber', code: 'wo' },
    { field: 'partNumber', code: 'pn' },
    { field: 'partNumber', code: 'part' },
    { field: 'assignedUser', code: 'user' },
    { field: 'assignedUser', code: 'assigned' },
    { field: 'ticket', code: '#' }
  ]"
  [fieldSearchNames]="{
    vendor: 'Supplier',
    poNumber: 'PO #',
    woNumber: 'Work Order Number',
    ticket: 'Ticket #',
    partNumber: 'PN',
    assignedUser: 'Assigned User'
  }"
  localStorageId="planning-queue"
  [isManager]="userIsManager.bind(this)"
  [filterParams]="filterParams"
  [defaultFilters]="defaultFilters"
>
  <ng-container matColumnDef="ticketNumber">
    <mat-header-cell *matHeaderCellDef mat-sort-header="number">Ticket #</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span class="font-weight-bold mat-light-text">
        {{ processQuoteNumber(row.quoteNumber) }}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="workOrderNumber">
    <mat-header-cell *matHeaderCellDef mat-sort-header="rootWO" style="flex-basis: 12%">WO #</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 12%">
      <span class="font-weight-bold mat-light-text">
        {{row.rootWorkOrderNumber}}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="dueDate">
    <mat-header-cell *matHeaderCellDef mat-sort-header="required">Due Date</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span *ngIf="row.requiredDate" [ngClass]="getHighlightClass(row.requiredDate)" class="p-1 rounded">
        {{row.requiredDate | date: 'shortDate' }}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="dateAssigned">
    <mat-header-cell *matHeaderCellDef mat-sort-header="dateAssigned">Assigned On</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span *ngIf="row.dateAssigned">
        {{row.dateAssigned | date: 'shortDate' }}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="promiseDate">
    <mat-header-cell *matHeaderCellDef mat-sort-header="promiseDate">Promise Date</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span *ngIf="row.planningPromiseDate" [ngClass]="getHighlightClass(row.planningPromiseDate)" class="p-1 rounded">
        {{row.planningPromiseDate | date: 'shortDate' }}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="late">
    <mat-header-cell *matHeaderCellDef>Late</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span *ngLet="getDaysLate(row) as daysLate">
        {{daysLate !== null ? daysLate : ''}}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="user">
    <mat-header-cell *matHeaderCellDef mat-sort-header="assigned">User</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span *ngIf="row.fullName" class="d-flex align-items-center text-muted font-weight-bold">
        <mat-icon class="mr-1">person</mat-icon><span>{{ row.fullName }}</span>
      </span>
      <span *ngIf="!row.fullName" class="d-flex align-items-center text-danger font-weight-bold">
        <mat-icon class="mr-1">person_off</mat-icon><span>Unassigned</span>
      </span>
    </mat-cell>
  </ng-container>  <ng-container matColumnDef="customer">
    <mat-header-cell *matHeaderCellDef mat-sort-header="customer">Customer</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span>
        {{ row.businessName }}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="poNumber">
    <mat-header-cell *matHeaderCellDef mat-sort-header="poNumber">PO #</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span class="font-weight-bold mat-light-text">
        {{row.purchaseOrderNumber}}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="partHistory">
    <mat-header-cell *matHeaderCellDef mat-sort-header="partHistory" style="flex-basis: 8%">
      Part Priority
      <mat-icon class="ml-1" [matTooltip]="'Only the highest priority among parts in the ' + (orderNumberTitle === 'RFQ' ? orderNumberTitle : (orderNumberTitle | lowercase)) + ' is shown.'" matTooltipPosition="after">info</mat-icon>
    </mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 8%">
      <mat-chip class="button-text" [ngClass]="getQuoteHistoryChipClass(row.history)">
        {{ getQuoteHistoryText(row.history) }}
      </mat-chip>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="planningProgress">
    <mat-header-cell *matHeaderCellDef style="flex-basis: 6%; flex-grow: 0;">Progress</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 6%; padding-right: 1em; flex-grow: 0;">
      <div *ngLet="(row.stepsUnplanned + row.stepsNeedCheck + row.stepsPlanned) as total" class="pl-progress-bar">
        <div *ngIf="total === 0"
            class="bg-danger"
            [ngStyle]="{
              width: '100%'
            }"
            matTooltipPosition="above"
            [matTooltip]="'No Steps In Work Order'"
          >&nbsp;
          </div>
        <ng-container *ngIf="total > 0">
          <div *ngIf="(row.stepsUnplanned / total) as proportion"
            class="bg-danger"
            [ngStyle]="{
              width: (proportion * 100) + '%'
            }"
            matTooltipPosition="above"
            [matTooltip]="row.stepsUnplanned + '/' + total + ' Items (' + (proportion * 100 | number: '1.0-1') + ')% Unplanned'"
          >&nbsp;
          </div>
         <div *ngIf="(row.stepsNeedCheck / total) as proportion"
            class="bg-warning"
            [ngStyle]="{
              width: (proportion * 100) + '%'
            }"
            matTooltipPosition="above"
            [matTooltip]="row.stepsNeedCheck + '/' + total + ' Items (' + (proportion * 100 | number: '1.0-1') + ')% Need Check'"
          >&nbsp;
          </div>
          <div *ngIf="(row.stepsPlanned / total) as proportion"
            class="bg-success"
            [ngStyle]="{
              width: (proportion * 100) + '%'
            }"
            matTooltipPosition="above"
            [matTooltip]="row.stepsPlanned + '/' + total + ' Items (' + (proportion * 100 | number: '1.0-1') + ')% Planned'"
          >&nbsp;
          </div>
        </ng-container>
      </div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <mat-chip class="status button-text" disableRipple [ngClass]="getStatusColorClass(row.status)">
        {{getStatusText(row.status)}}
      </mat-chip>
    </mat-cell>
  </ng-container>
</search-list-new>