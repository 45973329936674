<div class="m-0 mt-2 mb-3 row w-50">
  <div class="col-6">
    <mat-form-field class="w-100 dense-field mat-input-no-message data-field" floatLabel="always">
      <mat-label>Assigned User</mat-label>
      <mat-icon *ngIf="!!user?.fullName" class="text-muted" matPrefix svgIcon="account"></mat-icon>
      <mat-icon *ngIf="!user?.fullName" class="text-danger" matPrefix svgIcon="account-off"></mat-icon>
      <input readonly type="text" matInput [value]="user?.fullName ?? 'Unassigned'" [class.text-danger]="!user?.fullName">
    </mat-form-field>
  </div>
  <div class="col-6">
    <mat-form-field class="w-100 dense-field mat-input-no-message data-field">
      <mat-label>Status</mat-label>
      <mat-chip-list #chipList [tabIndex]="-1">
        <mat-chip matPrefix style="pointer-events: none;" class="mat-standard-chip button-text"
          [ngClass]="getStatusColorClass(status)" tabindex="-1">{{ getStatusText(status) }}</mat-chip>
        <input tabindex="-1" matInput [matChipInputFor]="chipList" type="text" readonly>
      </mat-chip-list>
      <!-- <button mat-icon-button matSuffix class="text-muted" *ngIf="record.statusNote" matTooltip="View Status Note"
        (click)="viewStatusNote()">
        <mat-icon>info</mat-icon>
      </button> -->
    </mat-form-field>
  </div>
</div>