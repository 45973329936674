<loading *ngIf="saving" content="Saving..."></loading>

<div class="border rounded position-relative d-flex flex-column" style="height: 100%;" *ngIf="record" [ngStyle]="{filter: !record.userId ? 'grayscale(1) blur(1px)' : '', 'pointer-events': !record.userId ? 'none' : 'all' }">
  <div class="p-3 mt-4 d-flex align-items-center">
    <div>
      <h3>WO # {{ record.workOrderNumber }}</h3>
      <div class="small text-muted" *ngIf="record.dueDate">
        Due {{ record.dueDate | date: 'yyyy/MM/dd' }}
      </div>
    </div>
    <div class="ml-auto">
      <employee-search [readonly]="!editable" label="WO Assigned To" [(selectedItem)]="reassigningUser"></employee-search>
    </div>
    <button *ngIf="editable" mat-stroked-button class="button-text ml-1 my-1 d-block" style="height: 59px;" [disabled]="!reassigningUser || reassigningUser.userId === record.userId" [class.text-primary]="reassigningUser && reassigningUser.userId !== record.userId" (click)="reassignWO()">
      Reassign
    </button>
  </div>
  <div style="    position: absolute;
  z-index: 9999;
  right: 16px;
  top: -29px;">
    <ng-container *ngIf="(tabGroup?.selectedIndex === 0 && record.status === 21) || record.status == 0">
      <div class="d-flex">
          <button mat-fab tabindex="-1" class="extended-fab-button text-white bg-primary mr-2"
            *ngIf="record.status !== 21 && !travelerPreviewWindow" (click)="startTravelerPreview()">
            <mat-icon svgIcon="file-table"></mat-icon>
            <span class="extended-fab-button__text">View Traveler</span>
          </button>
          <button mat-fab tabindex="-1" class="extended-fab-button mr-2 bg-white text-dark"
            *ngIf="record.status !== 21 && !!travelerPreviewWindow" (click)="startTravelerPreview()"
            matTooltipPosition="above"
            matTooltip="Click to focus preview. Close preview window to stop."
          >
          <ng-container *ngIf="!travelerPreviewLoading">
            <ng-container *ngTemplateOutlet="waitingDots"></ng-container>
          </ng-container>
          <mat-spinner diameter="20" color="black" class="preview-spinner" *ngIf="travelerPreviewLoading"></mat-spinner>
          <span class="extended-fab-button__text">Viewing Traveler...</span>
        </button>
        <button mat-fab color="primary" class="extended-fab-button bg-success text-white mr-3" *ngIf="!stepItems?.dirty && record.status === 0 && allPlanned" (click)="finishPlanning()">
          <mat-icon>logout</mat-icon>
          <span class="extended-fab-button__text">Finish Planning</span>
        </button>
        <button mat-fab color="primary" class="extended-fab-button bg-primary text-white mr-3" *ngIf="!stepItems?.dirty && record.status === 0" (click)="returnToPreplanning()">
          <mat-icon>undo</mat-icon>
          <span class="extended-fab-button__text">Return to Preplan</span>
        </button>
        <button mat-fab class="extended-fab-button bg-success text-white mr-3" *ngIf="stepItems?.dirty" (click)="saveChanges()">
          <mat-icon>logout</mat-icon>
          <span class="extended-fab-button__text">Save Changes</span>
        </button>
        <button mat-fab class="extended-fab-button bg-success text-white mr-3" *ngIf="preplanning?.form.valid && record.status === 21" (click)="finishPreplanning(preplanning?.preplanningData, preplanning?.form.value)">
          <mat-icon>logout</mat-icon>
          <span class="extended-fab-button__text">Finish Preplanning</span>
        </button>
        <button mat-fab color="primary" class="extended-fab-button bg-primary text-white" *ngIf="editingProduct" (click)="openWorkflow()">
          <mat-icon>edit</mat-icon>
          <span class="extended-fab-button__text">Edit Part Info</span>
        </button>
      </div>
    </ng-container>
  </div>
  <ng-container #outlet [ngTemplateOutlet]="content">
  </ng-container>
</div>

<ng-template #content>
  <mat-tab-group #tabGroup class="flex-grow-1" id="planning-tab-group" style="height: 0;">
    <mat-tab label="Preplanning" *ngIf="(record.status === 21 || record.status === 15) && stationService.stationsLoaded">
      <preplanning-detail #preplanning [parentComponent]="this" [(workOrder)]="record" [product]="editingProduct" *ngIf="editingProduct"></preplanning-detail>
      <div class="w-100 h-100 d-flex align-items-center justify-content-center" *ngIf="!editingProduct">
        <mat-spinner diameter="120" class="text-primary"></mat-spinner>
      </div>
    </mat-tab>
    <mat-tab label="Step Planning" *ngIf="!(record.status === 21 || record.status === 15)">
      <div class="w-100 h-100 d-flex align-items-center justify-content-center" *ngIf="!editingProduct">
        <mat-spinner diameter="120" class="text-primary"></mat-spinner>
      </div>
      <div class="h-100-t px-3" *ngIf="editingProduct">
        <workflow-step-items #stepItems [childProducts]="allProductsFlat" (workOrderEdit)="openWorkflow()" [product]="editingProduct"
          [steps]="editingProduct.workflow.workflowSteps" [repairPlan]="editingProduct.productRepairPlan"
          [workOrder]="record" [(machineAssignments)]="assignments" [editing]="editable" (programTab)="goToProgramming()" (productChange)="onStepEdited($event)"></workflow-step-items>
      </div>
    </mat-tab>
    <mat-tab label="Workflow Graph" *ngIf="!(record.status === 21 || record.status === 15)">
      <!-- <ng-template matTabContent> -->
        <planning-waterfall-graph *ngIf="editingProduct" [workOrder]="record" [productData$]="productData$"></planning-waterfall-graph>
      <!-- </ng-template> -->
    </mat-tab>
    <mat-tab label="Material" *ngIf="!(record.status === 21 || record.status === 15)">
      <div class="pt-4 h-100">
        <material-select [disabled]="true" [material]="editingProduct?.material" #materialSelect>
        </material-select>
      </div>
    </mat-tab>
    <mat-tab label="Drawings/Documents">
      <div class="pt-4">
        <file-display-grid [editable]="false" [files]="editingProduct?.documents"></file-display-grid>
        <p *ngIf="editingProduct?.documents?.length==0" class="text-muted text-center">
          <em>-- No Documents --</em>
        </p>
      </div>
    </mat-tab>
    <mat-tab #programmingTab label="Programming" *ngIf="!(record.status === 21 || record.status === 15)">
      <div class="">
        <div *ngIf="record.programmingTickets.length > 0; else noTickets">
          <div class="row m-0">
            <div class="col-12 p-3">
              <h4>Programming Ticket Data</h4>
            </div>
            <div class="col-12 p-0">
              <mat-list>
                <mat-list-item *ngFor="let programmingTicket of record.programmingTickets" class="border-bottom">
                  <div class="d-flex w-100 align-items-center">
                    <b style="min-width: 12%">{{ getProgrammingType(programmingTicket) }}</b>
                    <div class="mat-chip mat-standard-chip button-text ml-2" [ngClass]="getStatusColorClass(programmingTicket.status)">
                      {{ getStatusText(programmingTicket.status) }}
                    </div>
                    <div class="ml-auto mr-4">Assigned to <b>{{ programmingTicket.assignedUser.fullName }}</b></div>
                    <!-- <div>Due on {{ (programmingTicket.dueDate | date: 'shortDate') || '--' }}</div> -->
                    <a mat-flat-button color="primary" class="mat-square-button ml-2" matTooltip="Open Programming Ticket" target="_blank" [href]="'/programming/' + programmingTicket.programmingTicketId"><mat-icon>open_in_new</mat-icon></a>
                  </div>
                </mat-list-item>
              </mat-list>
            </div>
          </div>
        </div>
        <ng-template #noTickets>
          <div class="w-100 text-center text-muted font-italic">This work order has no programming tickets associated with it.</div>
        </ng-template>
      </div>
    </mat-tab>
  </mat-tab-group>
</ng-template>