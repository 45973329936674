import { Component, Input, OnInit } from '@angular/core';
import { SummaryRFQ } from '@cots/report/resources/summary-overview';
import { OrderStatus } from '@cots/order/resources/order';
import { keywordMatch } from 'util/keywordMatch';

@Component({
  selector: 'summary-sales',
  templateUrl: './summary-sales.component.html',
  styleUrls: ['./summary-sales.component.less']
})
export class SummarySalesComponent implements OnInit {

  @Input() record: SummaryRFQ;
  @Input() searchText: string;
  @Input() highlightFilter: string[];

  constructor() { }

  ngOnInit(): void {
  }
  
  public getStatusColorClass(status: number): string {
    return OrderStatus.getStatusColorClassChip(status);
  }
  
  public getStatusText(status: number): string {
    return OrderStatus.getStatusText(status);
  }

  public get sortedProducts() {
    if (!this.record?.products) return [];
    if (!this.searchText.trim()) return this.record.products;
    else return this.record.products.slice().sort((a, b) => {
      const aMatch = keywordMatch(this.searchText, `${a.partNumber} Rev. ${a.revision}`) ? 1 : 0;
      const bMatch = keywordMatch(this.searchText, `${b.partNumber} Rev. ${b.revision}`) ? 1 : 0;
      return bMatch - aMatch;
    });
  }

}
