import { Component, Input, OnInit } from '@angular/core';
import { OrderStatus } from '@cots/order/resources/order';
import { WorkflowStepPlanningStatus } from '@cots/order/resources/workflow';
import { SummarySalesOrder, SummaryStageProgress, SummaryWorkOrder, SummaryWorkOrderStage, getWorkOrderStageProgress } from '@cots/report/resources/summary-overview';
import { assertUnreachable } from 'util/assertUnreachable';

@Component({
  selector: 'summary-child-work-orders',
  templateUrl: './summary-child-work-orders.component.html',
  styleUrls: ['./summary-child-work-orders.component.less',
      '../master-summary-report.component.less'
]
})
export class SummaryChildWorkOrdersComponent implements OnInit {

  @Input() record: SummarySalesOrder;

  public get workOrders() {
    return [this.record.rootWorkOrder, ...this.record.childWorkOrders];
  }
  public displayedColumns = [
    'woNumber', 'dueDate', 'assignedUser', 'padding',
    'preplanning', 'purchasing', 'planning', 'production', 'inspection',
  ];

  constructor() { }

  ngOnInit(): void {
  }
  
  public getCheckboxColorClass(item: SummaryWorkOrder, section: SummaryWorkOrderStage) {
    const progress = getWorkOrderStageProgress(item, section);
    switch (progress) {
      case SummaryStageProgress.BLOCKED:
        return '';
      case SummaryStageProgress.PENDING:
        return 'bg-danger';
      case SummaryStageProgress.WORK_STARTED:
        return 'bg-warning';
      case SummaryStageProgress.FINISHED:
        return 'bg-success';
      default:
        assertUnreachable(progress);
    }
  }
  
  public getProgressValues(item: SummaryWorkOrder, stage: SummaryWorkOrderStage) {
    const progress = getWorkOrderStageProgress(item, stage);
    return {
      blocked: progress === SummaryStageProgress.BLOCKED,
      finished: progress === SummaryStageProgress.FINISHED,
    }
  }

}
