<div class="row m-0 h-100-t d-flex flex-column" style="overflow: hidden" *ngLet="service.selectedProduct$ | async as product">
  <ng-container *ngIf="service.postChangesOrder$ | async as record">
  <div class="d-flex border-bottom py-2" *ngIf="service.shouldShowIndicators(record)">
    <div class="ml-auto">
      <estimating-progress-status label="Workflow Status" [status]="product.workflowStatus" (statusChange)="service.setEstimatingTaskStatus(product.productId, 'workflow', $event)" [disabled]="!editing"></estimating-progress-status>
    </div>
  </div>
  </ng-container>
  <div class="row m-0 p-0 flex-grow-1" style="height: 0" *ngLet="step$ | async as selectedStep">
    <div class="col-4 h-100-t p-0 border-right">
        <div class="h-100-t" style="
            display: flex;
            flex-direction: column;">
          <div class="w-100 d-flex align-items-center mt-3 p-3 border-bottom" style="padding-bottom: 19px !important;">
            <div style="font-size: 1.15rem" class="font-weight-medium">Step Detail</div>
            <div style="font-size: 0.9em; margin-top: 4px;" class="text-muted font-weight-medium mx-2" *ngIf="selectedStep">
              {{ getStation(selectedStep)?.name }}
            </div>
            <button mat-stroked-button color="warn" class="ml-auto" *ngIf="selectedStep && editing"
              (click)="deleteStep(selectedStep)">Delete</button>
          </div>
          <div class="p-4 text-center small text-muted" *ngIf="!selectedStep">Select a step to edit.</div>
          <mat-tab-group *ngIf="selectedStep" class="flex-grow-1">
            <mat-tab label="Step Data">
              <product-workflow-step-form
                *ngFor="let i of [selectedStep.workflowStepId]"
                (fieldChange)="onStepFieldChange(selectedStep.workflowStepId, $event)"
                [editing]="editing"
                (newOPSpec)="onNewOPSpec($event)"
                (requestCreated)="onPurchasingRfqRequestCreated($event, product, selectedStep)"
                [record]="service.postChangesOrder$ | async"
                [product]="product"
                [step]="selectedStep"
                [unsavedSpecs]="service.newOPSpecifications$ | async"
                (deleteStep)="deleteStep()"
                (quoteOpened)="this.service.openSidenav('quoteDetail', $event)"
                (quoteHistoryOpened)="this.service.openSidenav('quoteHistory', $event)"
                [immutable]="true"
                class="h-100-t"
                #stepFormComponent
              ></product-workflow-step-form>
            </mat-tab>
            <mat-tab label="Microtickets" *ngIf="(service.postChangesOrder$ | async)?.salesProcessId as salesProcessId">
              <div class="pt-4 microticket-area">
                <microticket-subitem-viewer
                  *ngLet="service.postChangesOrder$ | async as record"
                  [relatedTicketId]="record.salesProcessId"
                  [relatedTicketName]="service.getNameForMicrotickets(record)"
                  [relatedTicketType]="'SalesProcess'"
                  [filterKey]="service.getMicroticketFilterKey(record)"
                  [subItem]="service.generateSubItemNavigationId(product.productId, 'workflow', selectedStep.workflowStepId)"
                  (loadingChange)="service.loading = $event"
                  [microTickets]="service.sharedMicroticketSubject">
                </microticket-subitem-viewer>
              </div>
            </mat-tab>
          </mat-tab-group>
      </div>      
    </div>
    <div class="col-4 h-100-t p-0 d-flex flex-column" [class.col-4]="editing" [class.col-8]="!editing">
      <div style="font-size: 1.25rem" class="font-weight-normal mt-3 p-3 border-bottom d-flex align-items-center">
        <div>Workflow</div>
        <mat-button-toggle-group class="ml-auto" [(ngModel)]="workflowView">
          <mat-button-toggle value="list">
            <mat-icon svgIcon="list-box"></mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="chart">
            <mat-icon svgIcon="chart-pie"></mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
      <product-workflow-workflow-list
        class="h-100-t"
        [editing]="editing" [product]="product"
        [quantityGetter]="getQuantityGetter(service.allProducts$ | async)"
        (stationDraggedIn)="addStep(product, $event.station)"
        (sort)="onWorkflowSort(product, $event)"
        [selectedStepId]="service.selectedWorkflowStepIdSubject | async" (selectedStepIdChange)="service.selectedWorkflowStepIdSubject.next($event)"
        *ngIf="workflowView === 'list'">
      </product-workflow-workflow-list>
      <workflow-graph *ngIf="workflowView === 'chart'" [data$]="service.selectedProduct$" [quantityGetter]="getQuantityGetter(service.allProducts$ | async)" [selectedStepId]="service.selectedWorkflowStepIdSubject | async" (selectedStepIdChange)="service.selectedWorkflowStepIdSubject.next($event)"></workflow-graph>
    </div>
    <div class="col-4 p-0 pt-3 border-left h-100-t" *ngIf="editing">
      <product-workflow-station-list (stationDblClick)="addStep(product, $event)"></product-workflow-station-list>
    </div>
  </div>
</div>