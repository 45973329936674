import { Component } from '@angular/core';
import { OrderStatus } from '../../../order/resources/order';
import { UserService } from '../../../common/services/user.service';
import { User } from '../../../common/resources/user';
import { FilterParam } from '../../../common/components/search-list-new/search-list-new.component';
import { MatDialog } from '@angular/material/dialog';
import { ReportOptionsDialog } from './report-options-dialog.component';
import { Product, getQuoteHistoryChipClass, getQuoteHistoryText } from '../../resources/product';
import { OrderService } from '../../services/order.service';
import { NavigationService } from '@cots/common/services/navigation.service';

@Component({
  selector: 'rfq-list',
  templateUrl: './rfq-list.component.html',
  styleUrls: ['./rfq-list.component.less']
})
export class RfqListComponent {

  

  constructor(private userSvc: UserService, private dialog: MatDialog, private service: OrderService, private navService: NavigationService) {
    navService.clearBreadCrumbs();
    navService.setPageTitle("RFQs");
  }

  public userIsManager(user: User): boolean {
    return this.userSvc.canAccess('QuoteManager', user);
  }

  public get isManager() { return this.userIsManager(this.userSvc.userData) }

  public readonly availableStatuses = [OrderStatus.IN_PROCESS, OrderStatus.AWAITING_REVIEW, OrderStatus.AWAIT_ESTIMATE, OrderStatus.REJECTED, OrderStatus.AWAIT_ISSUANCE, OrderStatus.FULFILLED, OrderStatus.CLOSED, OrderStatus.HISTORY] as const;
  public readonly filterParams: FilterParam[] = [{
    categoryName: 'status',
    categoryTitle: 'Status',
    options: this.availableStatuses.map(s => ({
      class: OrderStatus.getStatusColorClassChip(s),
      title: OrderStatus.getStatusText(s),
      value: s
    }))
  },
  // {
  //   categoryName: 'partHistory',
  //   categoryTitle: 'Part Priority',
  //   options: [0, 1, 2, 3, 4, 100].map(s => ({
  //     class: getQuoteHistoryChipClass(s),
  //     title: getQuoteHistoryText(s),
  //     value: s
  //   })),
  // }
]
  public readonly defaultStatuses = [OrderStatus.IN_PROCESS, OrderStatus.AWAITING_REVIEW, OrderStatus.AWAIT_ESTIMATE, OrderStatus.REJECTED, OrderStatus.AWAIT_ISSUANCE] as const;
  public readonly defaultFilters = {
    status: this.defaultStatuses, 
    // partHistory: [] 
  }

  public loading = false;
  public async generateReport() {

    const dialogRef = this.dialog.open<ReportOptionsDialog, any, {
      managerView: boolean,
      showBlocked: boolean,
      receivedFrom?: Date,
      receivedTo?: Date,
      requiredFrom?: Date,
      requiredTo?: Date,
      selectedParts: Product[],
      sortBy: 'customer' | 'required'
    }>(ReportOptionsDialog, {
      data: {
        managerView: true
      },
      minWidth: 500,
    });

    const opts = await dialogRef.afterClosed().toPromise();

    if (!opts) return;

    this.loading = true;
    this.service.getQuoteReport(opts, this.userSvc.userData).subscribe(doc => {
      this.loading = false;
      window.open(`/document/${doc.documentId}?inline=true`, "_blank")
    })

  }

}
