<div class="mat-elevation-z6 rounded overflow-hidden pt-2 position-relative" style="
  overflow: hidden;
  height: 90vh;
">
  <mat-progress-bar class="w-100 progress-bar" style="position: absolute; top: 0; z-index: 999;" [class.progress-bar-hidden]="!loading" mode="indeterminate"></mat-progress-bar>
  <mat-table #dataTable [dataSource]="dataSource" class="w-100 h-100-t" style="overflow-y: scroll" [class.loading]="loading" matSort>
    <mat-header-row style="border: none;" *matHeaderRowDef="['searchBar']; sticky: true"></mat-header-row>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: ['groupHeader']; when: rowIsGroupHeader"></mat-row>
    <mat-row *matRowDef="let row; columns: rowDisplayedColumns; " class="oor-row"></mat-row>
    <div class="mat-row" *matNoDataRow>
      <div class="mat-cell" [attr.colspan]="displayedColumns.length" class="text-muted small font-italic text-center"
        *ngIf="!loading">
        No open orders matching the parameters were found.
      </div>
    </div>
    <ng-container matColumnDef="searchBar">
      <mat-header-cell class="search-bar-cell" style="font-size: inherit; color: inherit;" *matHeaderCellDef [attr.colspan]="displayedColumns.length">
        <mat-form-field appearance="outline" class="w-100 mat-input-no-message dense-field" id="search-field" #searchField>
          <mat-icon matPrefix class="text-muted">search</mat-icon>
          <input matInput type="text" [(ngModel)]="searchText" (keyup.enter)="onSearchChange()" #searchInput />
          <button mat-icon-button matSuffix class="text-muted" [ngStyle]="{opacity: (loading || searchText === '') ? '0.5' : '1' }" [disabled]="loading || searchText === ''" style="
            display: inline-block;
            position: relative;
          " (click)="clearSearch()">
            <mat-icon>cancel</mat-icon>
          </button>
          <button mat-flat-button matSuffix [class.bg-primary]="!loading" [class.text-white]="!loading" class="button-text" [disabled]="loading" style="
            position: relative;
            top: -7px;
            line-height: 32px;
            margin-left: 6px;
            padding: 0 12px;
          " (click)="onSearchChange()">
            Search
          </button>
        </mat-form-field>
      </mat-header-cell>
    </ng-container>

    <ng-container matColumnDef="groupHeader">
      <mat-cell *matCellDef="let row" style="flex-basis: 100%">
        <button type="button" mat-icon-button class="mr-1" (click)="toggleGroup(row.groupId)">
          <mat-icon class="mat-icon-rtl-mirror">
          {{!groupSelectionModel.isSelected(row.groupId) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <b [innerHTML]=" row.groupName | highlight: highlightFilter : '\<span class=\'msr-highlight\'\>' : '\</span\>' "></b>
      </mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="number">
      <mat-header-cell *matHeaderCellDef style="flex-grow: 0; flex-basis: 15%" mat-sort-header="number"> # </mat-header-cell>
      <mat-cell *matCellDef="let row" style="flex-grow: 0; flex-basis: 15%">
        <b [innerHTML]=" getItemNumber(row) | highlight: highlightFilter : '\<span class=\'msr-highlight\'\>' : '\</span\>' "></b>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="dueDate">
      <mat-header-cell *matHeaderCellDef style="flex-grow: 0; flex-basis: 7.5%; padding-left: 1em;" mat-sort-header="dueDate"> Due Date </mat-header-cell>
      <mat-cell *matCellDef="let row" style="flex-grow: 0; flex-basis: 7.5%; padding-left: 1em;">
        {{ row.dueDate | date: 'MM/dd/yyyy' }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="currentUser">
      <mat-header-cell *matHeaderCellDef style="flex-grow: 0; flex-basis: 12.5%; padding-left: 1em;" mat-sort-header="user"> Current User </mat-header-cell>
      <mat-cell *matCellDef="let row" style="flex-grow: 0; flex-basis: 12.5%; padding-left: 1em;">
        <div class="d-flex align-items-center" *ngLet="getCurrentUser(row) as userName">
          <mat-icon *ngIf="!!userName" class="text-muted" matPrefix svgIcon="account"></mat-icon>
          <mat-icon *ngIf="!userName" class="text-danger" matPrefix svgIcon="account-off"></mat-icon>
          <div class="ml-2 font-weight-medium" [class.text-danger]="!userName" [class.text-muted]="!!userName"
            [innerHTML]=" userName ? (userName | highlight: highlightFilter : '\<span class=\'msr-highlight\'\>' : '\</span\>') : 'Unassigned' "
          ></div>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="customer">
      <mat-header-cell *matHeaderCellDef style="flex-basis: 7.5%" mat-sort-header="customer"> Customer </mat-header-cell>
      <mat-cell *matCellDef="let row" style="flex-basis: 7.5%">
        {{ row.customer?.businessName }}
      </mat-cell>
    </ng-container>
    <!--  -->
    <ng-container matColumnDef="sales">
      <mat-header-cell *matHeaderCellDef class="oor-checkbox-header" matTooltipPosition="above"
        mat-sort-header="progress"
        [matTooltip]="'Sales'"> SL </mat-header-cell>
      <mat-cell *matCellDef="let row" class="oor-checkbox-cell" [ngClass]="getCheckboxColorClass(row, 'sales')">
        <div class="triangle" [@triangleInOutAnimation] *ngIf="this.expansionModel.isSelected(row.workOrderId ?? row.salesProcessId)" [ngStyle]="{
          left: 'calc(-10px + ' + ((triangleOffsetMap[getKey(row)] * 80) + (triangleOffsetMap[getKey(row)] === 6 ? 9 : 0)) + 'px)'
        }"></div>
        <div class="oor-checkbox" matRipple (click)="selectSection(row, 'sales')" [class.cursor-pointer]="true"
          matRippleColor="rgba(255,255,255,0.15)">
          <mat-checkbox class="mat-checkbox-readonly" [checked]="getProgress(row, 'sales') === 3"></mat-checkbox>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="estimating">
      <mat-header-cell *matHeaderCellDef class="oor-checkbox-header" matTooltipPosition="above"
        mat-sort-header="progress"
        [matTooltip]="'Estimating'"> EST </mat-header-cell>
      <mat-cell *matCellDef="let row" class="oor-checkbox-cell estimating" [ngClass]="getCheckboxColorClass(row, 'estimating')">
        <div class="oor-checkbox" matRipple (click)="selectSection(row, 'estimating')" *ngLet="getProgressValues(row, 'estimating') as progress" [class.cursor-pointer]="!progress.blocked"
          matRippleColor="rgba(255,255,255,0.15)">
          <mat-icon class="text-white" svgIcon="dots-horizontal" *ngIf="progress.blocked"></mat-icon>
          <mat-checkbox class="mat-checkbox-readonly" [checked]="progress.finished" *ngIf="!progress.blocked"></mat-checkbox>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="quoting">
      <mat-header-cell *matHeaderCellDef class="oor-checkbox-header" matTooltipPosition="above"
        mat-sort-header="progress"
        [matTooltip]="'Quoting'"> QT </mat-header-cell>
      <mat-cell *matCellDef="let row" class="oor-checkbox-cell quoting" [ngClass]="getCheckboxColorClass(row, 'quoting')">
        <div class="oor-checkbox" matRipple (click)="selectSection(row, 'quoting')" *ngLet="getProgressValues(row, 'quoting') as progress" [class.cursor-pointer]="!progress.blocked"
          matRippleColor="rgba(255,255,255,0.15)">
          <mat-icon class="text-white" svgIcon="dots-horizontal" *ngIf="progress.blocked"></mat-icon>
          <mat-checkbox class="mat-checkbox-readonly" [checked]="progress.finished" *ngIf="!progress.blocked"></mat-checkbox>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="contractReview">
      <mat-header-cell *matHeaderCellDef class="oor-checkbox-header" matTooltipPosition="above"
        mat-sort-header="progress"
        [matTooltip]="'Contract Review'"> CR </mat-header-cell>
      <mat-cell *matCellDef="let row" class="oor-checkbox-cell contractReview" [ngClass]="getCheckboxColorClass(row, 'contractReview')">
        <div class="oor-checkbox" matRipple (click)="selectSection(row, 'contractReview')" *ngLet="getProgressValues(row, 'contractReview') as progress" [class.cursor-pointer]="!progress.blocked"
          matRippleColor="rgba(255,255,255,0.15)">
          <mat-icon class="text-white" svgIcon="dots-horizontal" *ngIf="progress.blocked"></mat-icon>
          <mat-checkbox class="mat-checkbox-readonly" [checked]="progress.finished" *ngIf="!progress.blocked"></mat-checkbox>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="purchasing">
      <mat-header-cell *matHeaderCellDef class="oor-checkbox-header" matTooltipPosition="above"
        mat-sort-header="progress"
        [matTooltip]="'Purchasing'"> PC </mat-header-cell>
      <mat-cell *matCellDef="let row" class="oor-checkbox-cell purchasing" [ngClass]="getCheckboxColorClass(row, 'purchasing')">
        <div class="oor-checkbox" matRipple (click)="selectSection(row, 'purchasing')" *ngLet="getProgressValues(row, 'purchasing') as progress" [class.cursor-pointer]="!progress.blocked"
          matRippleColor="rgba(255,255,255,0.15)">
          <mat-icon class="text-white" svgIcon="dots-horizontal" *ngIf="progress.blocked"></mat-icon>
          <mat-checkbox class="mat-checkbox-readonly" [checked]="progress.finished" *ngIf="!progress.blocked"></mat-checkbox>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="preplanning">
      <mat-header-cell *matHeaderCellDef class="oor-checkbox-header" matTooltipPosition="above"
        mat-sort-header="progress"
        [matTooltip]="'Preplanning'"> PP </mat-header-cell>
      <mat-cell *matCellDef="let row" class="oor-checkbox-cell preplanning" [ngClass]="getCheckboxColorClass(row, 'preplanning')">
        <div class="oor-checkbox" matRipple (click)="selectSection(row, 'preplanning')" *ngLet="getProgressValues(row, 'preplanning') as progress" [class.cursor-pointer]="!progress.blocked"
          matRippleColor="rgba(255,255,255,0.15)">
          <mat-icon class="text-white" svgIcon="dots-horizontal" *ngIf="progress.blocked"></mat-icon>
          <mat-checkbox class="mat-checkbox-readonly" [checked]="progress.finished" *ngIf="!progress.blocked"></mat-checkbox>
        </div>
      </mat-cell>

    </ng-container>
    <ng-container matColumnDef="planning">
      <mat-header-cell *matHeaderCellDef class="oor-checkbox-header" matTooltipPosition="above" [matTooltip]="'Planning'">
        PL </mat-header-cell>
      <mat-cell *matCellDef="let row" class="oor-checkbox-cell planning" [ngClass]="getCheckboxColorClass(row, 'planning')">
        <div class="oor-checkbox" matRipple (click)="selectSection(row, 'planning')" *ngLet="getProgressValues(row, 'planning') as progress" [class.cursor-pointer]="!progress.blocked"
          matRippleColor="rgba(255,255,255,0.15)">
          <mat-icon class="text-white" svgIcon="dots-horizontal" *ngIf="progress.blocked"></mat-icon>
          <mat-checkbox class="mat-checkbox-readonly" [checked]="progress.finished" *ngIf="!progress.blocked"></mat-checkbox>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="production">
      <mat-header-cell *matHeaderCellDef class="oor-checkbox-header" matTooltipPosition="above"
        mat-sort-header="progress"
        [matTooltip]="'Production'"> PR </mat-header-cell>
      <mat-cell *matCellDef="let row" class="oor-checkbox-cell production" [ngClass]="getCheckboxColorClass(row, 'production')">
        <div class="oor-checkbox" matRipple (click)="selectSection(row, 'production')" *ngLet="getProgressValues(row, 'production') as progress" [class.cursor-pointer]="!progress.blocked"
          matRippleColor="rgba(255,255,255,0.15)">
          <mat-icon class="text-white" svgIcon="dots-horizontal" *ngIf="progress.blocked"></mat-icon>
          <mat-checkbox class="mat-checkbox-readonly" [checked]="progress.finished" *ngIf="!progress.blocked"></mat-checkbox>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="inspection">
      <mat-header-cell *matHeaderCellDef class="oor-checkbox-header" matTooltipPosition="above"
        mat-sort-header="progress"
        [matTooltip]="'Inspection'"> IN </mat-header-cell>
      <mat-cell *matCellDef="let row" class="oor-checkbox-cell inspection" [ngClass]="getCheckboxColorClass(row, 'inspection')">
        <div class="oor-checkbox" matRipple (click)="selectSection(row, 'inspection')" *ngLet="getProgressValues(row, 'inspection') as progress" [class.cursor-pointer]="!progress.blocked"
          matRippleColor="rgba(255,255,255,0.15)">
          <mat-icon class="text-white" svgIcon="dots-horizontal" *ngIf="progress.blocked"></mat-icon>
          <mat-checkbox class="mat-checkbox-readonly" [checked]="progress.finished" *ngIf="!progress.blocked"></mat-checkbox>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="shipping">
      <mat-header-cell *matHeaderCellDef class="oor-checkbox-header" matTooltipPosition="above" [matTooltip]="'Shipping'" style="padding-right: 0 !important;"
        mat-sort-header="progress"
      >
        SH </mat-header-cell>
      <mat-cell *matCellDef="let row" class="oor-checkbox-cell shipping" [ngClass]="getCheckboxColorClass(row, 'shipping')">
        <div class="oor-checkbox" matRipple (click)="selectSection(row, 'shipping')" *ngLet="getProgressValues(row, 'shipping') as progress" [class.cursor-pointer]="!progress.blocked"
          matRippleColor="rgba(255,255,255,0.15)">
          <mat-icon class="text-white" svgIcon="dots-horizontal" *ngIf="progress.blocked"></mat-icon>
          <mat-checkbox class="mat-checkbox-readonly" [checked]="progress.finished" *ngIf="!progress.blocked"></mat-checkbox>
        </div>
      </mat-cell>
    </ng-container>
    <!--  -->
    <ng-container matColumnDef="expansionPanel">
      <mat-cell *matCellDef="let row" class="oor-expansion-panel" (click)="$event.stopPropagation()">
        <mat-tab-group [(selectedIndex)]="selectedIndexMap[getKey(row)]" class="oor-tab-group"
          *ngIf="this.expansionModel.isSelected(row.workOrderId ?? row.salesProcessId)" >
        <mat-tab label="SL">
            <div class="oor-tab sales" [ngClass]="getCheckboxColorClass(row, 'sales')">
                <div class="overview-container rounded mat-elevation-z4 bg-white h-100 d-flex flex-column">
                    <div class="mb-1 sales oor-color text-white mb-2 px-3 py-2 mt-2 d-flex align-items-center">
                      <h3 class="m-0">
                        Sales
                      </h3>
                      <a mat-stroked-button class="ml-3 button-text border-white"
                      [routerLink]="'/rfqs/' + row.rfq.orderSegmentId"
                      target="_blank"
                      >
                        <mat-icon svgIcon="open-in-new" matPrefix></mat-icon>
                        Go to RFQ
                    </a>
                    </div>
                    <div class="flex-grow-1" style="max-height: 500px;">
                        <summary-sales *ngIf="row.rfq?.products" [record]="row.rfq" [searchText]="this.searchText" [highlightFilter]="highlightFilter"></summary-sales>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="EST">
            <div class="oor-tab estimating" [ngClass]="getCheckboxColorClass(row, 'estimating')">
                <div class="overview-container rounded mat-elevation-z4 bg-white h-100 d-flex flex-column"
                 *ngIf="row.estimate"
                 >
                    <div class="mb-1 estimating oor-color text-white mb-2 px-3 py-2 mt-2 d-flex align-items-center">
                      <h3 class="m-0">
                        Estimating
                      </h3>
                      <a mat-stroked-button class="ml-3 button-text border-white"
                      [routerLink]="'/estimating/' + row.estimate.orderSegmentId"
                      target="_blank"
                      >
                        <mat-icon svgIcon="open-in-new" matPrefix></mat-icon>
                        Go to Estimate
                    </a>
                    </div>
                    <div class="flex-grow-1" style="max-height: 500px;">
                        <summary-estimating  [record]="row.estimate" [searchText]="this.searchText" [highlightFilter]="highlightFilter"></summary-estimating>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="QT">
            <div class="oor-tab quoting" [ngClass]="getCheckboxColorClass(row, 'quoting')">
                <div class="overview-container rounded mat-elevation-z4 bg-white h-100 d-flex flex-column"
                 *ngIf="row.quote"
                 >
                    <div class="mb-1 quoting oor-color text-white mb-2 px-3 py-2 mt-2 d-flex align-items-center">
                      <h3 class="m-0">
                        Quoting
                      </h3>
                      <a mat-stroked-button class="ml-3 button-text border-white"
                      [routerLink]="'/quoting/' + row.quote.orderSegmentId"
                      target="_blank"
                      >
                        <mat-icon svgIcon="open-in-new" matPrefix></mat-icon>
                        Go to Quote
                    </a>
                    </div>
                    <div class="flex-grow-1" style="max-height: 500px;">
                        <summary-quoting  [record]="row.quote"></summary-quoting>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="CR">
            <div class="oor-tab contractReview" [ngClass]="getCheckboxColorClass(row, 'contractReview')">
                <div class="overview-container rounded mat-elevation-z4 bg-white h-100 d-flex flex-column"
                 *ngIf="row.salesOrder?.rootWorkOrder?.contractReviewTicket as crTicket"
                 >
                    <div class="mb-1 contractReview oor-color text-white mb-2 px-3 py-2 mt-2 d-flex align-items-center">
                      <h3 class="m-0">
                        Contract Review
                      </h3>
                      <a mat-stroked-button class="ml-3 button-text border-white"
                      [routerLink]="'/quality/contract/' + crTicket.qualityTicketId"
                      target="_blank"
                      >
                        <mat-icon svgIcon="open-in-new" matPrefix></mat-icon>
                        Go to Ticket
                    </a>
                    </div>
                    <div class="flex-grow-1" style="max-height: 500px;">
                        <summary-contract-review [data]="row"></summary-contract-review>
                    </div>
                </div>
            </div>
        </mat-tab>
          <mat-tab label="childWorkOrders">
            <div class="oor-tab preplanning">
                <div class="overview-container rounded mat-elevation-z4 bg-white h-100 d-flex flex-column"
                 *ngIf="row.salesOrder?.rootWorkOrder?.planningTicket"
                 >
                    <div class="mb-2 px-3 py-2 mt-2 d-flex align-items-center">
                      <a mat-stroked-button class="ml-3 button-text"
                      [routerLink]="'/planning/' + row.salesOrder?.rootWorkOrder?.planningTicket.planningTicketId"
                      target="_blank"
                      >
                        <mat-icon svgIcon="open-in-new" matPrefix></mat-icon>
                        Go to Planning
                    </a>
                  </div>
                    <div class="flex-grow-1" style="max-height: 500px;">
                      <summary-child-work-orders [record]="row.salesOrder"></summary-child-work-orders>
                    </div>
                </div>
            </div>
          </mat-tab>
          <mat-tab label="SH">
            <div class="oor-tab shipping">
              <div class="overview-container rounded mat-elevation-z4 bg-white h-100 d-flex flex-column">
                <h3 class="mb-1 shipping oor-color text-white mb-2 px-3 py-2 mt-2">
                  Shipping
                </h3>
                <div class="flex-grow-1" style="max-height: 500px;">
                  <!-- <oor-shipping [record]="row"></oor-shipping> -->
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-cell>
    </ng-container>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[10]"
                 showFirstLastButtons
                 aria-label="Select page of periodic elements"
  style="
  position: sticky;
  bottom: -1px;
  "               
  >
  </mat-paginator>
</div>