<summary-step-data [user]="record.user" [status]="record.status"></summary-step-data>
<!-- <div class="mat-small-header mb-2 px-3">Line Items</div> -->
<!-- <mat-list class="pt-0 border-top list pb-2">
  <mat-list-item *ngFor="let lineItem of record.lineItems" class="border-bottom item-outer">
    <div class="w-100">
      <div class="w-100 d-flex align-items-center item">
      <mat-icon svgIcon="hand-front-right" class="text-primary mr-2" *ngIf="product.reviewStatus === -1"></mat-icon>
      <mat-icon svgIcon="help-box-outline" class="text-muted mr-2" *ngIf="product.reviewStatus === 0"></mat-icon>
      <mat-icon svgIcon="alert-box" class="text-warning mr-2" *ngIf="product.reviewStatus === 1"></mat-icon>
      <mat-icon svgIcon="checkbox-marked" class="text-success mr-2" *ngIf="product.reviewStatus === 2 || product.reviewStatus === 3"></mat-icon>
        <b>{{ product.partNumber }} Rev. {{product.revision}}</b>
      </div>
    </div>
  </mat-list-item>
  <mat-list-item *ngIf="record.products.length === 0" class="item text-center text-muted">
        This RFQ has no parts yet.
  </mat-list-item>
</mat-list> -->
