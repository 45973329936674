<div class="container-fluid main">
    <div class="row">
      <div class="col-6">
        <a class="m-2 clickable" routerLink="/reports/active-outsource">
          <h3>Active Outsource Report</h3>
        </a>
      </div>
      <div class="col-6">
        <a class="m-2 clickable" routerLink="/reports/bookings">
          <h3>Bookings</h3>
        </a>
      </div>
      <div class="col-6">
        <a class="m-2 clickable" routerLink="/reports/bookings-monthly">
          <h3>Bookings Monthly Report</h3>
        </a>
      </div>
      <div class="col-6">
        <a class="m-2 clickable" routerLink="/reports/customer-sales-share">
          <h3>Customer Sales Share</h3>
        </a>
      </div>
      <div class="col-6">
        <a class="m-2 clickable" routerLink="/reports/purchasing-categories">
          <h3>Purchasing Categories</h3>
        </a>
      </div>
      <div class="col-6">
        <a class="m-2 clickable" routerLink="/reports/on-time-processing">
          <h3>On-Time Processing</h3>
        </a>
      </div>
      <div class="col-6">
        <a class="m-2 clickable" routerLink="/reports/cost-report">
          <h3>Cost Report</h3>
        </a>
      </div>
      <div class="col-6">
        <a class="m-2 clickable" routerLink="/reports/finished-quotes">
          <h3>Finished Quotes</h3>
        </a>
      </div>
    </div>
  </div>