<a class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon mr-6" title="Cancel Edit"
  (click)="router.navigate(['/quality'])"><i class="fas fa-times"></i></a>
<a class="position-fixed fixed-bottom btn btn-badge bg-success has-icon" title="Save Record" (click)="finalizeReview()"
  *ngIf="ticket && ticket.status === 0"><i class="fas fa-save"></i></a>


<loading *ngIf="loading"></loading>
<loading *ngIf="saving" content="Saving..."></loading>


<mat-sidenav-container>
  <mat-sidenav-content *ngIf="record && !loading">
    <div class="row border border-bottom">
      <div class="col-12 text-center rounded p-4" [ngClass]="{'bg-info text-light': !ticket.assignedUser}">
        <div *ngIf="!ticket.assignedUser">
          <h3>Needs Assignment</h3>
          <p>This ticket needs to be assigned to an employee before it can be fulfilled.</p>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col-2 text-right" *ngIf="ticket.assignedUser">
            <span>Assigned To:</span>
          </div>
          <div class="col-6">
            <employee-select [editable]="true" (employeeChange)="assigningEmployee = $event"
              [employee]="ticket.assignedUser"></employee-select>
          </div>
          <div class="col-2"><button [disabled]="!assigningEmployee" class="btn btn-success"
              (click)="saveAssignment()"><span *ngIf="ticket.assignedUser">Re-</span>Assign</button></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 pr-0 mb-2">
        <div class="w-100 m-0 p-3 bg-light text-dark rounded border">
          <div class="row w-50">
            <div class="col-12">
              <h5 class="text-muted">Ticket Details</h5>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Customer</label>
                <div class="ticket-info">{{ record.customer?.businessName }}</div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>Customer PO #</label>
                <div class="ticket-info">{{ record.orderPurchaseOrder.purchaseOrderNumber }}</div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>Internal Order #</label>
                <div class="ticket-info">{{ record.orderPurchaseOrder.quote.orderNumber }}</div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>Owner</label>
                <div class="ticket-info" *ngIf="record.assignedUser; else none">{{ record.assignedUser?.fullName }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>Due Date</label>
                <div class="ticket-info">{{ record.requiredDate | date: 'longDate' }}</div>
              </div>
            </div>
            <div class="col-12">
              <button mat-flat-button color="primary" class="button-text"
                (click)="openPODocument()"
              >
                View Purchase Order
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3 pl-n3">
        <h6 class="bg-dark text-white px-4 py-2 rounded">
          Parts and Assemblies
        </h6>
        <ng-template #recursiveProductList let-productlist let-isRoot="isRoot">
          <div *ngFor="let product of productlist" [class.ml-3]="!isRoot">
            <div class="part-list-part d-flex align-items-center"
              [class.part-selected]="selectedProductId === product.productId" (click)="selectProduct(product)">
              <div class="fas fa-level-up-alt fa-rotate-90 small mr-2"></div>
              <div>{{product.partNumber}} Rev. {{ product.revision }} </div>
              <span class="d-inline-flex rounded bg-dark text-white p-1 ml-auto">
                {{ countProductAnswers(product).answered }}/{{ countProductAnswers(product).total }}
              </span>
            </div>
            <ng-container
              *ngTemplateOutlet="recursiveProductList; context: { $implicit: product.childAssemblies, isRoot: false }"></ng-container>
          </div>
        </ng-template>
        <ng-container
          *ngTemplateOutlet="recursiveProductList; context:{ $implicit: products, isRoot: true }"></ng-container>

      </div>
      <div class="col-9" *ngIf="selectedProductId">
        <mat-tab-group mat-stretch-tabs color="primary" backgroundColor="primary"
          class="h-100 product-tab-group border border-dark rounded">
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon class="mr-1">feed</mat-icon>
              <span class="tab-title">Details</span>
            </ng-template>
            <div class="w-100 m-0 p-3 bg-light text-dark rounded border">
              <div class="row">
                <div class="col-4">
                  <div class="form-group">
                    <label>Part Number</label>
                    <div class="ticket-info">{{ selectedProduct.partNumber }}</div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label>Revision</label>
                    <div class="ticket-info">{{ selectedProduct.revision }}</div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Due Date</label>
                    <div class="ticket-info" *ngIf="selectedProduct.dateRequired; else none">{{
                      selectedProduct.dateRequired | date:'MM/dd/yyyy' }}</div>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label>{{ selectedProduct.parentAssemblyId ? 'Quantity' : 'Ordered Quantity' }}</label>
                    <div class="ticket-info" *ngIf="getProductQuantity(selectedProduct); else none">{{
                      getProductQuantity(selectedProduct) }}</div>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label>Cost</label>
                    <div class="ticket-info" *ngIf="pricingIsLoaded(selectedProduct); else loadingSpinner">
                      {{ getProductCost(selectedProduct) | currency }}
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label>Markup</label>
                    <div class="ticket-info" *ngIf="pricingIsLoaded(selectedProduct); else loadingSpinner">
                      {{ getProductMarkup(selectedProduct) }}%
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label>Price</label>
                    <div class="ticket-info" *ngIf="pricingIsLoaded(selectedProduct); else loadingSpinner" [ngClass]="{
                      'text-info': priceIsOverriden(selectedProduct),
                      'font-weight-bold': priceIsOverriden(selectedProduct)
                    }">
                      {{ getProductPrice(selectedProduct) | currency }}
                    </div>
                  </div>
                </div>
                <div class="col-12" *ngIf="!selectedProduct.parentAssemblyId">
                  <div class="form-group">
                    <label>Scheduled Delivery Times</label>
                    <div class="border rounded w-100">
                      <table class="bg-white mb-0 small table table-fixed table-hover table-sm table-striped w-100">
                        <thead>
                          <tr>
                            <th>Quantity</th>
                            <th>Ship Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let assignment of selectedProductDeliveryTimes, index as i">
                            <td align="start">
                              {{assignment.quantity}} of {{ selectedProduct.orderQuantity }} items
                            </td>
                            <td>
                              {{ assignment.shipDate | date: 'longDate' }}
                            </td>
                          </tr>
                          <tr class="bg-light text-muted font-weight-bold"
                            *ngIf="unassignedAmount(selectedProduct) > 0">
                            <td align="start">
                              {{ unassignedAmount(selectedProduct) }} of {{ selectedProduct.orderQuantity }} items
                            </td>
                            <td>
                              -- Not Assigned --
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon class="mr-1">feed</mat-icon>
              <span class="tab-title">Drawings</span>
            </ng-template>
      <div class="pt-4">
        <file-display-grid [editable]="false" [files]="selectedProduct?.documents"></file-display-grid>
        <p *ngIf="selectedProduct?.documents?.length==0" class="text-muted text-center">
          <em>-- No Documents --</em>
        </p>
      </div>
          </mat-tab>
          <mat-tab [disabled]="!selectedProduct.material">
            <ng-template mat-tab-label>
              <mat-icon class="mr-1">view_in_ar</mat-icon>
              <span class="tab-title" [class.strikethrough]="!selectedProduct.material">Material</span>
            </ng-template>
            <div class="w-100 m-0 p-3 bg-light text-dark rounded border">
              <div class="row" *ngIf="selectedProduct.material">
                <div class="col-12">
                  <div class="form-group">
                    <label>Material</label>
                    <div class="ticket-info">{{ materialName(selectedProduct.material) }}</div>
                  </div>
                </div>
                <div class="col-6" *ngIf="!selectedProduct.materialCostIsLotBased">
                  <div class="form-group">
                    <label>Raw Weight</label>
                    <div class="ticket-info">{{getRawWeight(selectedProduct) | number:'1.2-2'}}
                      {{selectedProduct.useMetric?'kg':'lb'}}</div>
                  </div>
                </div>
                <div class="col-6" *ngIf="!selectedProduct.materialCostIsLotBased">
                  <div class="form-group">
                    <label>Finished Weight</label>
                    <div class="ticket-info">{{ selectedProduct.finishedWeight }}{{selectedProduct.useMetric?'kg':'lb'}}
                    </div>
                  </div>
                </div>
                <div class="col-6" *ngIf="selectedProduct.selectedMaterialQuote">
                  <div class="form-group">
                    <label>Cost</label>
                    <div *ngIf="materialQuotes[selectedProductId]; else loadingSpinner">
                      {{ getTotalMaterialCost(selectedProduct) }}
                    </div>
                  </div>
                </div>
                <div class="col-6" *ngIf="selectedProduct.selectedMaterialQuote">
                  <div class="form-group">
                    <label>Lead Time</label>
                    <div *ngIf="materialQuotes[selectedProductId]; else loadingSpinner">
                      {{ materialQuotes[selectedProductId].leadTimeDays }} days
                    </div>
                  </div>
                </div>
                <div class="col-6"
                  *ngIf="!selectedProduct.selectedMaterialQuote && selectedProduct.material.defaultLeadTimeDays">
                  <div class="form-group">
                    <label>Lead Time</label>
                    <div>
                      {{ selectedProduct.material.defaultLeadTimeDays }} days
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Material Markup</label>
                    <div>
                      {{ selectedProduct.materialMarkup || 0 }}%
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label># Test Pieces</label>
                    <div>
                      {{ selectedProduct.numberTestPeices || 0 }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-100 h-100 d-flex align-items-center justify-content-center text-muted font-italic small"
                *ngIf="!selectedProduct.material">
                This product has no material.
              </div>
            </div>
          </mat-tab>
          <mat-tab [disabled]="!selectedProduct.workflow || selectedProduct.workflow.workflowSteps.length === 0">
            <ng-template mat-tab-label>
              <mat-icon class="mr-1">engineering</mat-icon>
              <span class="tab-title"
                [class.strikethrough]="!selectedProduct.workflow || selectedProduct.workflow.workflowSteps.length === 0">Workflow</span>
            </ng-template>
            <div class="row w-100 m-0" *ngIf="selectedProduct.workflow">
              <div class="col-4">
                <div class="border rounded h-100-t">
                  <div class="h-100-t" style="max-height: 400px; overflow-y: scroll"
                    *ngIf="workflowList && workflowList.selectedItem" class="p-2">
                    <product-workflow-step-form [editing]="false" [record]="ticket.orderSegment"
                      [product]="selectedProduct" [step]="workflowList.selectedItem" class="h-100-t"
                      #stepFormComponent></product-workflow-step-form>
                  </div>
                  <div *ngIf="!workflowList || !workflowList.selectedItem" class="w-100 h-100 bg-light">

                  </div>
                </div>
              </div>
              <div class="col-8">
                <workflow-list [sortable]="false" #workflowList [product]="selectedProduct"
                  [(steps)]="selectedProduct.workflow.workflowSteps">
                </workflow-list>
              </div>
            </div>
            <div
              class="bg-light rounded border w-100 h-100 d-flex align-items-center justify-content-center text-muted font-italic small"
              *ngIf="!selectedProduct.workflow || selectedProduct.workflow.workflowSteps.length === 0">
              This product has no workflow steps.
            </div>
          </mat-tab>
          <mat-tab [disabled]="selectedProduct.purchasedItems.length === 0">
            <ng-template mat-tab-label>
              <mat-icon class="mr-1">settings</mat-icon>
              <span class="tab-title" [class.strikethrough]="selectedProduct.purchasedItems.length === 0">Purchased
                Items</span>
            </ng-template>
            <div class="border rounded" *ngIf="selectedProduct.purchasedItems.length > 0">
              <table class="table-sm table-borderless table-striped table-hover w-100">
                <thead class="border-bottom bg-white">
                  <tr>
                    <th>Item</th>
                    <th>Quantity</th>
                    <th>Type</th>
                    <th>Total Cost</th>
                    <th>Lead Time</th>
                  </tr>
                </thead>
                <tbody class="small">
                  <tr *ngFor="let ppi of selectedProduct.purchasedItems">
                    <td class="font-weight-bold">
                      {{ ppi.purchasedItem.description }}
                    </td>
                    <td>
                      {{ ppi.quantity }}
                    </td>
                    <td>
                      <span *ngIf="ppi.isNonRecurring">
                        Non-recurring, {{ ppi.isAmortized ? 'amortized' : 'non-amortized' }}
                      </span>
                      <span *ngIf="!ppi.isNonRecurring">
                        Per part
                      </span>
                    </td>
                    <td>
                      <div *ngIf="ppi.selectedQuote; else none">
                        <span *ngIf="purchasedItemQuotes[ppi.productPurchasedItemId]; else loadingSpinner">
                          {{ getTotalPurchasedItemCost(selectedProduct, ppi) | currency }}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div *ngIf="ppi.selectedQuote; else none">
                        <span *ngIf="purchasedItemQuotes[ppi.productPurchasedItemId]; else loadingSpinner">
                          {{ purchasedItemQuotes[ppi.productPurchasedItemId].leadTimeDays }} days
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="bg-light rounded border w-100 h-100 d-flex align-items-center justify-content-center text-muted font-italic small"
              *ngIf="selectedProduct.purchasedItems.length === 0">
              This product has no purchased items.
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon class="mr-1">checklist</mat-icon>
              <span class="tab-title">Checklist</span>
              <span class="d-inline-flex rounded bg-white text-dark p-1 ml-1 small font-weight-bold">
                {{ countProductAnswers(selectedProduct).answered }}/{{ countProductAnswers(selectedProduct).total }}
              </span>
            </ng-template>
            <div>
              <div class="d-flex justify-content-between w-100 mb-2">
                <div class="" *ngFor="let category of questionCategories">
                  <button class="btn btn-block btn-sm h-100"
                    [class.btn-outline-primary]="selectedCategoryId !== category.contractReviewCategoryId"
                    [class.btn-primary]="selectedCategoryId === category.contractReviewCategoryId"
                    (click)="selectedCategoryId = category.contractReviewCategoryId">
                    {{ category.name }}
                    <span class="d-inline-flex small rounded bg-dark text-white py-1 px-2 ml-2">
                      {{ countProductAnswers(selectedProduct, category.contractReviewCategoryId).answered }}/{{
                      countProductAnswers(selectedProduct, category.contractReviewCategoryId).total }}
                    </span>
                  </button>
                </div>
              </div>
              <div class="checklist-table-outer" *ngIf="selectedCategory">
                <table class="table table-sm table-striped table-borderless table-hover thead-light mb-0">
                  <tr class="large">
                    <th>Item</th>
                    <th *ngFor="let value of selectedCategory.answers" class="text-center">{{ value }}</th>
                    <th>Examples/Notes</th>
                    <th>Comments</th>
                  </tr>
                  <tr *ngFor="let question of selectedCategory.questions">
                    <td class="font-weight-bold question-text">{{question.text}}</td>
                    <td *ngFor="let value of selectedCategory.answers" class="text-center">
                      <input type="radio" [name]="question.contractReviewQuestionId"
                        [(ngModel)]="ticket.contractReviewAnswers[selectedProductId][selectedCategory.contractReviewCategoryId][question.contractReviewQuestionId]"
                        [value]="value">
                    </td>
                    <td class="text-muted font-italic small">
                      {{ question.examples ? question.examples : '—' }}
                    </td>
                    <td>
                      <input type="text" class="form-control form-control-sm">
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
      <div
        class="col-9 bg-light text-muted font-italic small border rounded d-flex align-items-center justify-content-center"
        *ngIf="!selectedProductId">
        Please select a product to review on the left.
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #doneDialog>
  <h2 matDialogTitle>Are you done?</h2>
  <mat-dialog-content>
    <p>Are you ready to pass or fail this contract review, or does it need more work?</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose="done">Done</button>
    <button mat-button matDialogClose="save">Needs more work</button>
    <button mat-button matDialogClose="cancel">Cancel</button>
  </mat-dialog-actions>
</ng-template>


<ng-template #finalizeReviewDialog>
  <h2 matDialogTitle>Finalize Contract Review</h2>
  <mat-dialog-content>
    <h6 class="text-center">Will you pass or fail this contract review?</h6>
    <div class="d-flex mt-3 justify-content-around">
      <button class="btn" [class.btn-outline-success]="willApprove !== true" [class.btn-success]="willApprove === true"
        (click)="willApprove = true">
        <i class="fas fa-check mr-2"></i> Pass
      </button>
      <button class="btn" [class.btn-outline-danger]="willApprove !== false" [class.btn-danger]="willApprove === false"
        (click)="willApprove = false">
        <i class="fas fa-times mr-2"></i> Fail
      </button>
    </div>
    <div class="mt-2">
      <div *ngIf="willApprove === true && uncheckedItems > 0" class="text-center">
        <div class="text-danger font-weight-bold font-italic">Cannot approve</div>
        <div>There are <b>{{uncheckedItems}}</b> items that have not been reviewed yet.</div>
      </div>
      <div *ngIf="willApprove === false">
        <div class="mb-1 small font-weight-bold font-italic text-muted">Please provide a reasoning for failing this
          contract review.</div>
        <textarea class="form-control" rows="3" [(ngModel)]="rejectNote"></textarea>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button [matDialogClose]="false" [disabled]="uncheckedItems > 0">Finalize</button>
    <button mat-button [matDialogClose]="true">Cancel</button>
  </mat-dialog-actions>
</ng-template>


<ng-template #loadingSpinner>
  <div>
    <i class="fas fa-circle-notch fa-spin text-primary"></i>
  </div>
</ng-template>

<ng-template #none>
  <div class="text-muted">
    --
  </div>
</ng-template>