<mat-form-field appearance="outline" [ngClass]="fieldClass" [class.dense-field]="dense" class="w-100 specifications-input" [class.nowrap]="nowrap" floatLabel="always"
*ngLet="filteredSpecs | async as specs"
>
  <mat-label>Specifications</mat-label>
  <mat-chip-list #chipList [class.chip-list-nowrap]="nowrap"
      [disabled]="disabled"
  >
    <mat-chip
      *ngFor="let id of specifications; index as i"
      [removable]="editable"
      (removed)="removeSpec(i)">
      {{getSpecName(id, i)}}
      <button matChipRemove *ngIf="editable">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
    <input
      [readonly]="!editable"
      [disabled]="disabled"
      [placeholder]="specifications.length === 0 ? 'No Specifications' : undefined"
      #specInput
      [matAutocomplete]="auto"
      [formControl]="searchCtrl"
      [matChipInputFor]="chipList">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addSpec($event.option.value)">
    <mat-option *ngFor="let spec of specs" [value]="{ type: 'existing', value: spec.outsideProcessSpecificationId }">
      {{spec.name}}
    </mat-option>
    <mat-option [value]="{ type: 'new', value: searchCtrl.value }" *ngIf="searchCtrl.value?.trim()" class="font-weight-bold text-muted"
      >
      <mat-icon matPrefix class="mr-2">add</mat-icon>Add "{{searchCtrl.value}}"
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
