import { Component, OnInit, ViewChild, Input, ViewContainerRef, TemplateRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { WorkOrder } from '../../resources/work-order';
import { ActivatedRoute, Router } from '@angular/router';
import { PreplanningData, WorkOrderService, WorkOrderShippingAssignment } from '../../services/work-order.service';
import { MachineAssignment } from '../../resources/machine-assignment';
import { PlanningService } from '../../services/planning.service';
import { OrderStatus } from '../../../order/resources/order';
import { NavigationService } from '../../../common/services/navigation.service';
import { MatSidenav } from '@angular/material/sidenav';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { Product } from '../../../order/resources/product';
import { OrderService } from '../../../order/services/order.service';
import { UserService } from '../../../common/services/user.service';
import { UtilityService } from '../../../common/services/utility.service';
import { User } from '../../../common/resources/user';
import { StationService } from '../../../order/services/station.service';
import { Station } from '../../../order/resources/station';
import { ProgrammingTicket, ProgrammingType } from '../../resources/programming-ticket';
import { ProductHierarchySortComponent, SortedProductHierarchy } from '../product-hierarchy-sort/product-hierarchy-sort.component';
import { AfterViewInit } from '@angular/core';
import { WorkflowStepItemsComponent } from '../workflow-step-items/workflow-step-items.component';
import { WorkflowStepPlanningStatus } from '../../../order/resources/workflow';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { MaterialBidService } from '../../../purchasing/services/material-bid.service';
import { PlanningDetailService } from '../planning-detail.service';
import { MaterialBid } from '../../../purchasing/resources/materialBid';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'planning-wo-detail',
  templateUrl: './planning-wo-detail.component.html',
  styleUrls: ['./planning-wo-detail.component.less']
})
export class PlanningWoDetailComponent implements OnInit, AfterViewInit {
  private id: string;
  @Input() record: WorkOrder;
  @Input() allProductsFlat: Product[];
  public editing: boolean = false;
  public saving: boolean = false;
  public showEditor: string = null;
  public assignments: MachineAssignment[];
  public editingProduct: Product;
  public assigningEmployee: User = null;
  public selectedProgrammingTicket: ProgrammingTicket = null;
  @ViewChild("sidenav") sidenav: MatSidenav;
  private stationList: Station[] = null;
  public productData$ = new ReplaySubject<Product>(1);

  constructor(private route: ActivatedRoute,
    private woService: WorkOrderService,
    public stationService: StationService,
    private planningSvc: PlanningService,
    private navService: NavigationService,
    private orderSvc: OrderService,
    private userSvc: UserService,
    private utilitySvc: UtilityService,
    private quoteService: MaterialBidService,
    private detailService: PlanningDetailService,
    private router: Router) {
    this.id = this.route.snapshot.paramMap.get('id');
  }

  @Output() editWorkflow = new EventEmitter<Product>();
  public openWorkflow(): void {
    this.detailService.openSidenav('editWorkflow', this.editingProduct);
  }

  public getStatusText(disposition: number): string {
    return OrderStatus.getStatusText(disposition);
  }

  public userIsManager(): boolean {
    return (
      this.userSvc.canAccess("PlanningManager") || this.userSvc.canAccess("Developer")
    );
  }

  public get emptyGuid(): string {
    return UtilityService.emptyGuid;
  }

  public saveAssignment(): void {
    if (!this.record) return;

    this.saving = true;
    this.woService
      .assign(this.record.workOrderId, this.assigningEmployee)
      .subscribe((_) => {
        this.saving = false;
        if (this.assigningEmployee.userId === this.userSvc.userData.userId) this.getDetail();
        else {
          // redirect to list if assigning to someone else
          this.router.navigate(this.route.snapshot.url.slice(0, -1).map(x => x.path));
        }
      });
  }

  public selectProgrammingTicket(ticket: ProgrammingTicket): void {
    this.selectedProgrammingTicket = ticket;

    this.showEditor = 'programming';
    this.sidenav.toggle();
  }

  public async getDetail(inputWorkOrder?: WorkOrder) {
    this.editingProduct = null;
    
    const workOrder = inputWorkOrder || this.record;
    this.reassigningUser = workOrder.assignedTo;

    const machineAssignments = await this.woService.getAssignmentsForWorkOrder(workOrder).toPromise();

    this.record = workOrder;

    this.assignments = machineAssignments;

    const [allQuotes, product] = await Promise.all([
      this.quoteService.getAllQuotesForOrder(<any>{ orderSegmentId: this.record.orderSegmentId }).toPromise(),
      await this.orderSvc.getProduct(this.record.productId).toPromise()
    ]);
    // TODO clean this up
    console.log('have product');
    this.detailService.autosaver.transformDomain('productsMap', (map) => { map[product.productId] = product; return map });
    this.editingProduct = product;
    this.detailService.resetQuotes(allQuotes);
    this.productData$.next(this.editingProduct);
  }

  @Output() savePlanning = new EventEmitter<{ product: Product }>();
  public async saveChanges() {

    // const dialogRef = this.utilitySvc.dialogService.open(
    //   WoPlanningConfirmationDialog,
    //   { disableClose: true }
    // );

    // const result = await dialogRef.afterClosed().toPromise()
    this.saving = true;

    // if (!result.ready) {
    //   this.record.status = OrderStatus.IN_PROCESS;
    // }
    // else {
    //   //TODO: will be either fulfilled or waiting on programming...
    //   this.record.status = OrderStatus.PLANNED;
    //   this.record.datePlanningFinished = new Date();
    // }

    this.savePlanning.emit({ product: this.editingProduct });

  }

  @Output() preplanningFinished = new EventEmitter<PreplanningData>();
  public async finishPreplanning(preplanningData: PreplanningData, formData: any) {
    console.log(preplanningData);
    console.log(formData);
    const r = await this.utilitySvc.showConfirmationPromise('Approve this preplanning ticket?', 'It will move to full planning and programming.');
    if (!r) return;
    this.preplanning.dirty = false;
    this.preplanningFinished.emit(preplanningData);
  }

  @ViewChild('productHierarchySort') productHierarchySortComponent: ProductHierarchySortComponent; 
  public async reorderHierarchy(data: SortedProductHierarchy[]) {
    this.saving = true;
    await this.orderSvc.reorderHierarchy(data).toPromise();
    this.productData$.next(this.editingProduct);
    this.saving = false;
    this.productHierarchySortComponent.getDetail();
  }

  public closeSideNav(): void {
    this.showEditor = null;
    this.navService.popBreadCrumb();

    this.getDetail();
  }

  public toggleEditing(): void {
    this.editing = !this.editing;
  }

  public getStation(stationId: string): Station {
    return this.stationList.find(s => s.stationId == stationId);
  }
  public getStationName(stationId: string): string {
    return this.stationList.find(s => s.stationId == stationId).name;
  }

  getStatusColorClass(status: number): string {
    return OrderStatus.getStatusColorClassChip(status);
  }

  ngOnInit(): void {
    if (this.stationService.loaded) {
      this.stationList = this.stationService.stationList;
    }
    else {
      this.stationService.stationsLoaded.subscribe(
        _ => this.stationList = this.stationService.stationList
      );
    }

    this.getDetail();
  }

  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  @ViewChild('stepItems') programmingTab: MatTab;
  @ViewChild('stepItems') stepItems: WorkflowStepItemsComponent;
  @ViewChild('preplanning') preplanning: any;
  ngAfterViewInit(): void {
    console.log('tabGroup', this.tabGroup);
    console.log('preplanning', this.preplanning);
  }

  @ViewChild('outlet', { read: ViewContainerRef }) outletRef: ViewContainerRef;
  @ViewChild('content', { read: TemplateRef, static: true }) contentRef: TemplateRef<any>;
  public rerender() {
    this.outletRef.clear();
    this.outletRef.createEmbeddedView(this.contentRef);
  }

  public get dirty() {
    return (this.stepItems && this.stepItems.dirty) || (this.preplanning && this.preplanning.dirty);
  }

  public reassigningUser: User;

  @Output() reassign = new EventEmitter<User>();
  public reassignWO() {
    this.reassign.emit(this.reassigningUser);
  }

  public programmingTypeToString(type: ProgrammingType): string {
    if (type === ProgrammingType.CMMProgramming) return 'CMM'
    else if (type === ProgrammingType.NCProgramming) return 'NC'
    else if (type === ProgrammingType.CNCProgramming) return 'CNC'
  }

  public goToProgramming() {
    const i = this.tabGroup._tabs.toArray().findIndex(t => t.textLabel === 'Programming');
    console.log('goToProgramming', i)
    this.tabGroup.selectedIndex = i;
  }

  public newProgrammerAssign: User;
  public newProgrammerDueDate: Date;

  public stepsToProgram: string[] = [];
  public get programmingSetupDone() {
    return this.newProgrammerAssign;
  }

  public get programStepsList() {
    return this.record.product.workflow.workflowSteps.sort((s1, s2) => {
      if (this.stepsToProgram.includes(s1.workflowStepId)) return -1;
      else return 0;
    })
  }

  // This function handles getting updated product data from the sidenav workflow edit function without overwriting user changes on this screen
  public productUpdated(product: Product, quotesMap?: {[key:string]: MaterialBid[]}) {
    product.workflow.workflowSteps = product.workflow.workflowSteps.map(step => {
      if (!step.planningStatus) step.planningStatus = WorkflowStepPlanningStatus.UNPLANNED;
      if (!step.workflowStepInventoryItems) step.workflowStepInventoryItems = [];
      return step;
    });
    // Deep clone and replace editing product
    this.editingProduct = JSON.parse(JSON.stringify(product));
    if (quotesMap) this.detailService.resetQuotes(quotesMap);
    this.productData$.next(this.editingProduct);
    if (this.preplanning) this.preplanning.getDetail();
  }

  @Output() revertPreplanning = new EventEmitter<void>();
  public async returnToPreplanning() {
    const r = await this.utilitySvc.showConfirmationPromise('Are you sure?', 'Really return to preplanning?');
    if (!r) return;
    this.stepItems.dirty = false;
    this.revertPreplanning.emit();
  }

  @Output() donePlanning = new EventEmitter<void>();
  public async finishPlanning() {
    if (this.record.programmingTickets?.length > 0 && this.record.programmingTickets.some(t => t.status !== 11)) {
      this.tabGroup.selectedIndex = 4;
      return this.utilitySvc.showMessage('Programming Tickets Not Complete', 'Some programming tickets for this work order have not been completed yet. Planning can not be marked as done until they are.')
    }
    const r = await this.utilitySvc.showConfirmationPromise('Are you sure?', 'Really finish planning and release part to floor?');
    if (!r) return;
    this.donePlanning.emit();
  }

  public get allPlanned() {
    return this.editingProduct?.workflow.workflowSteps.every(s => s.planningStatus === WorkflowStepPlanningStatus.PLANNED);
  }

  public get editable() {
    return this.record?.status === 0 || this.record?.status === 21;
  }

  public getProgrammingType(ticket: ProgrammingTicket) {
    switch (ticket.type) {
      case ProgrammingType.CNCProgramming: return 'CNC Programming';
      case ProgrammingType.CMMProgramming: return 'CMM Programming';
      case ProgrammingType.NCProgramming: return 'NC Programming';
    }
  }

  public onStepEdited(product: Product) {
    this.editingProduct = product;
    this.productData$.next(this.editingProduct);
  }

  // Code for live updating traveler PDF view
  public travelerPreviewWindow: Window | null;
  public travelerPreviewLoading = false;
  private travelerPreviewSubscription: Subscription;
  private travelerPreviewWindowInterval: any = null;

  public async updateTravelerPreview() {
    this.travelerPreviewLoading = true;
    const res = await fetch(`/api/orderSegment/previewTraveler/${this.record.workOrderId}`);
    const buffer = await res.arrayBuffer();
    const file = new Blob([buffer], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    const w = this.travelerPreviewWindow;
    this.travelerPreviewLoading = false;
    w.open(fileURL, '_self');
    setTimeout(() => w.document.title = `Traveler - ${this.record.workOrderNumber}`, 50);
  }

  private onPreviewWindowUnload() {
    if (this.travelerPreviewWindow.closed) {
      this.travelerPreviewWindow = null;
      this.travelerPreviewLoading = false;
      clearInterval(this.travelerPreviewWindowInterval);
      this.travelerPreviewWindowInterval = null;
      if (this.travelerPreviewSubscription) this.travelerPreviewSubscription.unsubscribe();
    }
  }

  public closeQuotePreviewWindow() {
    if (this.travelerPreviewWindow) {
      this.travelerPreviewWindow.close();
    }
  }

  public startTravelerPreview() {
    if (this.travelerPreviewWindow) {
      this.travelerPreviewWindow.focus();
      return;
    };
    this.travelerPreviewWindow = window.open('about:blank', '_blank', 'menubar=no,status=no,toolbar=no');
    // only reliable way to check if window was closed seems to be constant interval
    this.travelerPreviewWindowInterval = setInterval(() => {
      if (this.travelerPreviewWindow.closed) this.onPreviewWindowUnload()
    }, 500)
    // this.quotePreviewWindow.addEventListener('beforeunload', this.onPreviewWindowUnload.bind(this));
    this.updateTravelerPreview();
    this.travelerPreviewSubscription = this.productData$
      .pipe(
        debounceTime(500)
      )
      .subscribe(() => {
        this.updateTravelerPreview();
      });
    window.addEventListener('beforeunload', this.closeQuotePreviewWindow.bind(this));
    return this.travelerPreviewWindow;
  }
}


@Component({
  selector: "wo-planning-confirmation-dialog",
  templateUrl: "wo-planning-confirmation.dialog.html",
})
export class WoPlanningConfirmationDialog { }
