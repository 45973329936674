import { Component, Input, OnInit } from '@angular/core';
import { ContractReviewAnswersMap } from '@cots/quality/resources/quality-ticket';
import { SummaryOverview } from '@cots/report/resources/summary-overview';

@Component({
  selector: 'summary-contract-review',
  templateUrl: './summary-contract-review.component.html',
  styleUrls: ['./summary-contract-review.component.less']
})
export class SummaryContractReviewComponent implements OnInit {

  @Input() data: SummaryOverview;

  constructor() { }

  ngOnInit(): void {
  }

  public get allWorkOrders() {
    if (!this.data) return [];
    return [this.data.salesOrder.rootWorkOrder, ...this.data.salesOrder.childWorkOrders];
  }

  public get answers(): ContractReviewAnswersMap {
    return JSON.parse( this.data?.salesOrder?.rootWorkOrder?.contractReviewTicket?.contractReviewAnswers as any as string);
  }

  public getAnsweredForProduct(productId: string): number {
    return Object.values(this.answers[productId]).flatMap(o => Object.values(o)).filter(a => !!a).length;
  }
  
  public getTotalForProduct(productId: string): number {
    return Object.values(this.answers[productId]).flatMap(o => Object.values(o)).length;
  }

}
