import { Component, Input, OnInit } from '@angular/core';
import { OrderStatus } from '@cots/order/resources/order';
import { SummaryRFQ } from '@cots/report/resources/summary-overview';

@Component({
  selector: 'summary-quoting',
  templateUrl: './summary-quoting.component.html',
  styleUrls: ['./summary-quoting.component.less']
})
export class SummaryQuotingComponent implements OnInit {
  @Input() record: SummaryRFQ;

  constructor() { }

  ngOnInit(): void {
  }
  
  public getStatusColorClass(status: number): string {
    return OrderStatus.getStatusColorClassChip(status);
  }
  
  public getStatusText(status: number): string {
    return OrderStatus.getStatusText(status);
  }
}
