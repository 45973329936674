import { Component, OnInit, ViewChild } from '@angular/core';
import { DueTime, PurchasingService, dueTimeOrder } from '../../services/purchasing.service';
import { PurchasingSheetItem } from '../../resources/purchasingSheet';
import { WorkOrder } from '../../../planning/resources/work-order';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ItemType } from '../../../inventory/components/item-type-chip/item-type-chip.component';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NavigationService } from '@cots/common/services/navigation.service';

type PrerequisiteListKey = { dueTime?: DueTime, workOrder?: WorkOrder, mainItemId?: string, mainItem?: PurchasingSheetItem, quantity?: number }
type PrerequisiteListData = PrerequisiteListComponent['items'][0]['items'] & { key: string }
type PrerequisiteListItem = PrerequisiteListKey | PrerequisiteListData
function isKey(x: PrerequisiteListItem): x is PrerequisiteListKey {
  return x.hasOwnProperty('workOrder') || x.hasOwnProperty('dueTime') || x.hasOwnProperty('mainItemId')
}

const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'})

@Component({
  selector: 'prerequisite-list',
  templateUrl: './prerequisite-list.component.html',
  styleUrls: ['./prerequisite-list.component.less']
})
export class PrerequisiteListComponent implements OnInit {

  public loading = false;

  public rowIsKey(_: number, x: any) {
    return isKey(x);
  }

  public sortType: 'dueDate' | 'workOrder' | 'item' = 'dueDate';
  // set after loading. used to run conditionals only after we've received new date
  public sortedType: 'dueDate' | 'workOrder' | 'item' = null;
  public items: { dueTime?: DueTime, workOrder?: WorkOrder, items: PurchasingSheetItem[] }[]
  public dataSource = new MatTableDataSource<PrerequisiteListItem>()
  public expansionModel = new SelectionModel<string>(true);
  public get displayedColumns(): string[] {
    if (this.sortedType === 'item') return ['workOrder', 'qty', 'dueDate', 'validity']
    else return ['name', 'qty', 'workOrder', 'dueDate', 'validity']
  }
  public searchInput = '';

  @ViewChild('dataTable') dataTable: MatTable<PrerequisiteListItem>;

  constructor(private service: PurchasingService, private navService: NavigationService) {
    navService.clearBreadCrumbs();
    navService.setPageTitle("Prerequisites");
  }

  public getItemType(item: PurchasingSheetItem): ItemType {
    if (item.stationId) return 'PROCESS';
    else if (item.materialNeedId) return 'MATERIAL';
    else if (item.purchasedItemId) return 'PURCHASED';
    else if (item.paintId) return 'PAINT';
  }

  async getDetail() {
    this.loading = true;
    const sortType = this.sortType;
    const items = await this.service.listPurchasingSheetItems(this.sortType, this.searchInput, this.itemFilters, this.validityFilters).toPromise();
    this.sortedType = sortType;
    const itemsSorted = items.sort((a, b) => {
      if (a.dueTime && b.dueTime) return dueTimeOrder.indexOf(a.dueTime) - dueTimeOrder.indexOf(b.dueTime);
      else if (a.workOrder && b.workOrder) return collator.compare(a.workOrder.workOrderNumber, b.workOrder.workOrderNumber);
      else if (a.mainItem && b.mainItem) return collator.compare(this.getItemType(a.mainItem), this.getItemType(b.mainItem));
    }).map(x => ({
      ...x,
      items: x.dueTime === DueTime.OVERDUE ? x.items.sort((a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime()) : x.items
    }));
    this.dataSource.data = itemsSorted.flatMap((i: any) => {
      if (i.dueTime) return [{ dueTime: i.dueTime, count: i.items.length }, ...i.items.map(y => ({ ...y, key: i.dueTime }))];
      if (i.workOrder) return [{ workOrder: i.workOrder, count: i.items.length }, ...i.items.map(y => ({ ...y, key: i.workOrder.workOrderId }))];
      if (i.mainItemId) return [{ mainItem: i.mainItem, mainItemId: i.mainItemId, quantity: i.quantity }, ...i.items.map(y => ({ ...y, key: i.mainItemId }))];
    })
    this.expansionModel.clear();
    this.expansionModel.select(...items.map(i => this.getKey(i)).filter(x => x !== 'later' && x !== 'unknown'));
    this.dataSource.filterPredicate = (data) => {
      if (isKey(data)) return true;
      else return this.expansionModel.isSelected(data.key);
    }
    this.dataTable?.renderRows();
    this.dataSource.filter = new Date().getTime().toString();
    this.loading = false;
  }

  public doSearch = new Subject();

  ngOnInit(): void {
    this.doSearch.pipe(debounceTime(250)).subscribe(() => {
      this.getDetail();
    });
    this.doSearch.next();
  }

  public getKey(item: PrerequisiteListKey) {
    if (item.dueTime) return item.dueTime;
    if (item.workOrder) return item.workOrder.workOrderId;
    if (item.mainItemId) return item.mainItemId;
  }

  public getKeyName(item: PrerequisiteListKey) {
    if (item.dueTime) {
      switch (item.dueTime) {
        case DueTime.OVERDUE:
          return 'Overdue';
        case DueTime.THREE_DAYS:
          return 'Due Within 3 Days';
        case DueTime.SEVEN_DAYS:
          return 'Due Within 7 Days';
        case DueTime.FOURTEEN_DAYS:
          return 'Due Within 14 Days';
        case DueTime.LATER:
          return 'Due Later';
        case DueTime.UNKNOWN:
          return 'Due Date Unknown';
      }
    }
    if (item.workOrder) {
      return `Work Order #${item.workOrder.workOrderNumber}`;
    }
    if (item.mainItemId) {
      return item.mainItem?.name;
    }
  }

  public toggleKey(key: string) {
    this.expansionModel.toggle(key);
    this.dataTable?.renderRows();
    this.dataSource.filter = new Date().getTime().toString();
  }

  public isSelected(i: number, key: string) {
    return this.expansionModel?.isSelected(key);
  }

  public possibleItemFilters: ItemType[] = ['MATERIAL', 'PAINT', 'PROCESS', 'PURCHASED'];
  public itemFilters: ItemType[] = this.possibleItemFilters.slice();

  public itemFilterEnabled(type: ItemType) {
    return this.itemFilters.includes(type);
  }

  public toggleItemFilter(type: ItemType) {
    if (this.itemFilterEnabled(type)) this.itemFilters = this.itemFilters.filter(t => t !== type);
    else this.itemFilters = [...this.itemFilters, type];
    this.doSearch.next();
  }

  public getValidityClass(validity: number) {
    switch (validity) {
      case 0:
        // ESTIMATED
        return 'bg-danger text-white';
      case 1:
        // PREPLANNED
        return 'bg-warning text-dark';
      case 2:
        // FULLY_PLANNED
        return 'bg-success text-white';
      case 3:
        // ALL_SCHEDULED
        return 'bg-info text-white';
      default:
        break;
    }
  }

  public getValidityName(validity: number) {
    switch (validity) {
      case 0:
        // ESTIMATED
        return 'Estimated';
      case 1:
        // PREPLANNED
        return 'Preplanned';
      case 2:
        // FULLY_PLANNED
        return 'Fully Planned';
      case 3:
        // ALL_SCHEDULED
        return 'Fully Scheduled';
      default:
        break;
    }
  }

  public getValidityTooltip(validity: number) {
    switch (validity) {
      case 0:
        // ESTIMATED
        return 'Prerequisite comes from an estimate. Information should be taken with a grain of salt, especially for raw material.';
      case 1:
        // PREPLANNED
        return 'Prerequisite comes from preplanning. The required item should be accurate, but the due date may change for better or worse.';
      case 2:
        // FULLY_PLANNED
        return 'Prerequisite comes from a fully-planned workflow. All information should be accurate barring administrative decisions.';
      case 3:
        // ALL_SCHEDULED
        return 'Prerequisite comes from a fully-scheduled workflow. All information should be accurate.';
      default:
        break;
    }
  }

  public possibleValidityFilters: number[] = [0, 1, 2, 3];
  public validityFilters: number[] = this.possibleValidityFilters.slice();

  public validityFilterEnabled(validity: number) {
    return this.validityFilters.includes(validity);
  }

  public toggleValidityFilter(validity: number) {
    if (this.validityFilterEnabled(validity)) this.validityFilters = this.validityFilters.filter(t => t !== validity);
    else this.validityFilters = [...this.validityFilters, validity];
    this.doSearch.next();
  }

  public isOverdue(date: string) {
    if (!date) return false;
    return new Date(date).getTime() <= new Date().getTime();
  }

  public calculateMaterialAmount(i: PurchasingSheetItem) {
    if (!i.materialNeed) return 0;
    return ((i.materialNeed.partsPerBlank == 0 || i.materialNeed.blanksPerMaterialUnit == 0) ? 0 : (i.qty / (i.materialNeed.partsPerBlank * i.materialNeed.blanksPerMaterialUnit)))
  }

}
