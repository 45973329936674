import { Component, Input, Output, EventEmitter, OnInit, ElementRef, ViewChild, OnChanges, SimpleChanges, Optional, forwardRef, AfterViewInit } from '@angular/core';
import { MessageType } from '../../../common/resources/message';
import { MessageService } from '../../../common/services/message.service';
import { ProductQuantity } from '../../../order/resources/product';
import { UtilityService } from '../../../common/services/utility.service';
import { OutsideProcessSpecification, Station } from '../../resources/station';
import { StationService } from '../../services/station.service';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, combineLatest } from 'rxjs';
import { startWith, map, filter } from 'rxjs/operators';
import { OrderDetailService } from '../order-detail-new/order-detail.service';
import { MatAutocomplete } from '@angular/material/autocomplete';

@Component({
  selector: 'specifications-input',
  templateUrl: './specifications-input.component.html',
  styleUrls: ['./specifications-input.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SpecificationsInputComponent),
      multi: true
    }
  ]
})
export class SpecificationsInputComponent implements AfterViewInit, ControlValueAccessor, OnChanges {
  @Input() workflowStepId: string;
  @Input() stationId: string;
  @Input() specifications: string[] = [];
  @Output() specificationsChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Input() editable: boolean = false;
  @Input() nowrap: boolean = false;
  @Input() dense: boolean = false;
  @Input() disabled: boolean = false;
  @Input() fieldClass: string;
  @Input() unsavedSpecs: OutsideProcessSpecification[] = [];
  @ViewChild('specInput') specInput: ElementRef;

  filteredSpecs: Observable<OutsideProcessSpecification[]>;
  constructor(private messageSvc: MessageService, private stationSvc: StationService) {
  }

  addSpec(spec: { type: 'existing' | 'new', value: 'string' }): void {
    if (spec === null) return;
    const { type, value } = spec;
    if (type === 'existing') {
      this.specifications = [...this.specifications, value]
      this.specInput.nativeElement.value = null;
      this.searchCtrl.setValue('');
      this.notifyChanged();
      this.onChange && this.onChange(this.specifications);
    } else {
      this.onNewSpec(value);
    }
  }

  notifyChanged(): void {
    this.specificationsChange.emit(this.specifications);
    console.log(this.specifications);
  }

  writeValue(item: string[]): void {
    this.specifications = item ? item : [];
  }

  private onChange: (value: string[]) => void;
  private onTouched: () => void;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public get station(): Station {
    if (!this.stationSvc.loaded) return null;
    return this.stationSvc.stationList.find(s => s.stationId == this.stationId);
  }

  public get specList() {
    let out = ((this.station && this.station.outsideProcessSpecifications) ?? []).filter(x => !x.isDeleted && !this.specifications.includes(x.outsideProcessSpecificationId));
    return [...out, ...this.unsavedSpecs];
  }

  searchCtrl = new FormControl('');

  @Input() names: string[];
  @Output() namesChange = new EventEmitter<string[]>();
  public getSpecName(specId: string, index: number) {
    if (this.names) return this.names[index]; 
    const list = [...(this.station?.outsideProcessSpecifications ?? []), ...this.unsavedSpecs];
    return list.find(s => s.outsideProcessSpecificationId === specId)?.name ?? '';
  }

  removeSpec(index: number): void {
    this.specifications.splice(index, 1);
    if (this.names) this.namesChange.emit(this.names.filter((_, i) => i !== index));
    this.notifyChanged();
    this.onChange && this.onChange(this.specifications);
  }

  private setupObservable() {
    this.filteredSpecs = combineLatest([
      this.stationSvc.stations,
      this.searchCtrl.valueChanges.pipe(startWith("")),
      this.specificationsChange.asObservable().pipe(startWith(this.specifications)),
      this.createSpec.asObservable().pipe(startWith(null))
    ]).pipe(
      filter(([stations, search, currentSpecs, _]) => search !== null),
      map(([stations, search, currentSpecs]) => {
        const station = stations.find(s => s.stationId == this.stationId);
        let speclist = [...((station && station.outsideProcessSpecifications) ?? []), ...this.unsavedSpecs].filter(x => !x.isDeleted && !currentSpecs.includes(x.outsideProcessSpecificationId));
        if (typeof search !== 'string') return speclist.slice();
        return (search ?
          (speclist.filter(s => s.name.toLowerCase().includes(search.toLowerCase())))  
          : speclist.slice())
      }),
    );
  }

  ngAfterViewInit(): void {
    this.setupObservable();
  }

  @Output() createSpec = new EventEmitter<string>();
  onNewSpec(value: string) {
    console.log(value);
    this.createSpec.emit(value);
    this.specInput.nativeElement.value = null;
    this.searchCtrl.setValue('');
  }


  log(e) {
    console.log(e);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.stationId) this.setupObservable();
  }

}
