import { Injectable } from "@angular/core";
import { Product } from "../resources/product";
import { MaterialBid } from "../../purchasing/resources/materialBid";
import { StationService } from "./station.service";
import { Station } from "../resources/station";
import { WorkflowStep } from "../resources/workflow";

interface TimingDataComponents {
    purchasedItemDays: number;
    materialQuoteDays: number;
    outsourcedDays: number;
    laborDays: number;
    subassemblyDays: number;
    bufferDays: number;
};

type TimingDataWorkflowSegmentTag = "STANDARD" | "WARNING";
type TimingDataPurchasedItemTag = "STANDARD" | "WARNING";
type TimingDataMaterialTag = "STANDARD" | "WARNING";

export interface TimingDataWorkflowSegment {
    tag: TimingDataWorkflowSegmentTag;
    qtyProcessed: number;
    startAtHour: number;
    endAtHour: number;
    setupHours: number;
    runHours: number;
}

export interface TimingDataWorkflow {
    stepId: string;
    order: number;
    isOutsourced: boolean;
    setupHours: number;
    runHours: number;
    perQtyHours: number;
    segments: TimingDataWorkflowSegment[];
}

export interface TimingDataPurchasedItemSegment {
    tag: TimingDataPurchasedItemTag;
    startAtHour: number;
    endAtHour: number;
}

export interface TimingDataPurchasedItem {
    itemId: string;
    days: number;
    error: string;
    segments: TimingDataPurchasedItemSegment[];
}

export interface TimingDataMaterialSegment {
    tag: TimingDataMaterialTag;
    startAtHour: number;
    endAtHour: number;
}

interface TimingDataMaterial {
    materialId: string;
    days: number;
    error: string;
    segments: TimingDataMaterialSegment[];
}

interface TimingDataSubassembly {
    productId: string;
    days: number;
    startAtHour: number;
    endAtHour: number;
}

export interface TimingData {
    // Identifying location within the tree
    productId: string;
    topParentId: string;
    parentId: string;
    depth: number;
    quantity: number;
    topParentQuantity: number;
    generatedAt: number;

    // Combined timing
    totalDays: number;

    // Collective component totals
    components: TimingDataComponents;

    // Individual component details
    material: TimingDataMaterial;
    workflow: TimingDataWorkflow[];
    purchasedItems: TimingDataPurchasedItem[];
    subassemblies: TimingDataSubassembly[];

}

declare type GetQuoteCallback = (itemId: string, quoteId: string) => Promise<MaterialBid>;

@Injectable()
export class ProductTimingService {
    private timeData: TimingData[];
    private getQuoteCallback: GetQuoteCallback;

    constructor(
        private stationService: StationService,
    ) {
        this.resetData();
    }

    // Without arrow functions, "this" is undefined inside these methods
    // https://stackoverflow.com/a/69620907
    resetData = () => {
        this.timeData = [];
        this.getQuoteCallback = null;
    }

    resetProduct = (product: Product) => {
        this.timeData = this.timeData.filter(d => d.topParentId !== product.topParentAssemblyId);
    }

    setCallback = (callback: GetQuoteCallback) => {
        this.getQuoteCallback = callback;
    }

    getAllDataForProduct = (product: Product) => {
        return this.timeData.filter(d => d.topParentId === product.productId);
    }

    getDataForProduct = (product: Product, quantity: number): TimingData => {

        // Match the product id and quantity exactly, or any quantity if null
        // This is called with null in cases where subassemblies are being queried without parents
        // In those cases, we want whatever we calculated before
        const results = this.timeData.filter(d => d.productId === product.productId && (quantity === null || d.quantity === quantity));

        const latest = results.map(d => d.generatedAt).reduce((latest, d) => Math.max(latest, d), 0);

        return results.find(d => d.generatedAt === latest);
    }

    updateDataForProduct = async (product: Product, quantity: number) => {

        // Must be a parent to update; children do not have enough info to do this accurately
        if (product.parentAssemblyId || product.parentAssembly)
            throw("Only call updateDataForProduct() on top-level parents");

        // Update the data for all of the subassemblies first
        await this.updateDataForSubassemblies(product.childAssemblies, quantity, quantity);

        // Calculate the timings. Must go after subassemblies, since component data uses subassembly time
        const { materialData, purchasedItemData, workflowData, subassemblyData, componentData } = await this.buildDetailData(product, quantity);

        const totalDays: number = this.getTotalDays(componentData, workflowData);

        // Construct the return object from those timings
        this.timeData = this.timeData.filter(d => !(d.productId === product.productId && d.topParentQuantity === quantity));
        this.timeData.push({
            productId: product.productId,
            topParentId: product.productId, // we're in the top-level parent now
            parentId: product.parentAssemblyId, // always null here
            depth: 0,
            quantity: quantity,
            topParentQuantity: quantity,
            components: componentData,
            workflow: workflowData,
            purchasedItems: purchasedItemData,
            material: materialData,
            subassemblies: subassemblyData,
            totalDays,
            generatedAt: Date.now(),
        });
    }

    private updateDataForSubassemblies = async (products: Product[], parentQuantity: number, topParentQuantity: number, depth: number = 1, topParentAssemblyId = null): Promise<void> => {
        for (const product of products) {
            const childQuantity = (product.quantityAsChild ?? 1) * parentQuantity;

            // Update subassemblies recursively. Must go before component data, to include subassembly time
            await this.updateDataForSubassemblies(product.childAssemblies, childQuantity, topParentQuantity, depth + 1, topParentAssemblyId ?? product.parentAssemblyId);

            // Calculate the timing data. If there are descendents, all info is calculated for it already
            const { materialData, purchasedItemData, workflowData, subassemblyData, componentData } = await this.buildDetailData(product, childQuantity);

            const totalDays: number = this.getTotalDays(componentData, workflowData);

            this.timeData = this.timeData.filter(d => !(d.productId === product.productId && d.topParentQuantity === topParentQuantity));
            this.timeData.push({
                productId: product.productId,
                topParentId: topParentAssemblyId ?? product.parentAssemblyId,
                parentId: product.parentAssemblyId,
                depth,
                quantity: childQuantity,
                topParentQuantity,
                components: componentData,
                workflow: workflowData,
                purchasedItems: purchasedItemData,
                material: materialData,
                subassemblies: subassemblyData,
                totalDays,
                generatedAt: Date.now(),
            });
        }
    }

    private buildDetailData = async (product: Product, quantity: number) => {

        const materialData: TimingDataMaterial = await this.getMaterialData(product);

        const purchasedItemData: TimingDataPurchasedItem[] = await this.getPurchasedItemData(product);

        const workflowData: TimingDataWorkflow[] = await this.getWorkflowData(product, quantity, materialData.segments[1].endAtHour);

        const subassemblyData: TimingDataSubassembly[] = this.getSubassemblyData(product)

        this.setPrerequisiteTimelines(product, materialData, purchasedItemData, subassemblyData, workflowData);

        const componentData: TimingDataComponents = await this.getComponentData(product, workflowData, purchasedItemData, materialData, subassemblyData);

        return {
            materialData,
            purchasedItemData,
            workflowData,
            subassemblyData,
            componentData
        };
    }

    /*
     * Component calculations
     */

    private getPurchasedItemDays = async (purchasedItemData: TimingDataPurchasedItem[]): Promise<number> => {
        return purchasedItemData
            .filter(d => d.error === null)
            .reduce((sum, item) => sum += item.days, 0);
    }

    private getMaterialDays = async (materialData: TimingDataMaterial): Promise<number> => {
        return materialData.days;
    }

    private getBufferDays = (product: Product): number => {
        return product.leadTimeBuffer ?? 0;
    }

    private getSubassemblyDays = (subassemblyData: TimingDataSubassembly[]): number => {
        return subassemblyData.reduce((max, data) => Math.max(max, data.days), 0);
    }

    private getComponentData = async (product: Product, workflowData: TimingDataWorkflow[], purchasedItemData: TimingDataPurchasedItem[], materialData: TimingDataMaterial, subassemblyData: TimingDataSubassembly[]): Promise<TimingDataComponents> => {
        return {
            purchasedItemDays: await this.getPurchasedItemDays(purchasedItemData),
            materialQuoteDays: await this.getMaterialDays(materialData),
            outsourcedDays: this.getOutsourcedDays(workflowData),
            laborDays: this.getLaborDays(workflowData),
            subassemblyDays: this.getSubassemblyDays(subassemblyData),
            bufferDays: this.getBufferDays(product),
        }
    }

    private getMaterialData = async (product: Product, startAtHour: number = 0): Promise<TimingDataMaterial> => {
        const data: TimingDataMaterial = {
            materialId: product.materialId,
            days: 0,
            error: null,
            segments: [{
                tag: "WARNING",
                startAtHour: 0,
                endAtHour: 168,
            }, {
                tag: "STANDARD",
                startAtHour: 168,
                endAtHour: 168,
            }]
        };

        if (!product.material && !product.materialId) {
            data.error = "NO_MATERIAL";
        } else if (!product.selectedMaterialQuote) {
            data.error = "UNQUOTED";
        } else {
            const quote = await this.getQuoteCallback(product.materialId, product.selectedMaterialQuote);

            if (!quote) {
                data.error = "NOT_FOUND";
            } else {
                data.days = quote.leadTimeDays ?? 0;
                data.segments[1].endAtHour = data.segments[1].startAtHour + data.days * 24;
            }
        }

        return data;
    }

    private getPurchasedItemData = async (product: Product): Promise<TimingDataPurchasedItem[]> => {
        const purchasedItemData: TimingDataPurchasedItem[] = [];

        for (const item of product.purchasedItems) {
            let data: TimingDataPurchasedItem = {
                itemId: item.purchasedItemId,
                days: 0,
                segments: [{
                    tag: "WARNING",
                    startAtHour: 0,
                    endAtHour: 168,
                },
                {
                    tag: "STANDARD",
                    startAtHour: 168,
                    endAtHour: 168,
                }],
                error: null,
            };

            if (item.selectedQuote) {
                const quote = await this.getQuoteCallback(item.purchasedItemId, item.selectedQuote)

                if (quote) {
                    data.days = quote.leadTimeDays ?? 0;
                    // Defaults, probably overriding later in setPrerequisiteTimelines
                    data.segments[1].endAtHour = 168 + (data.days * 24);
                } else {
                    data.error = "NOT_FOUND";
                }

            } else {
                data.error = "UNQUOTED";
            }

            purchasedItemData.push(data);
        }

        return purchasedItemData;
    }

    private getSubassemblyData = (product: Product): TimingDataSubassembly[] => {
        // We only want direct descendents, since their total times include every product beneath them
        const childData = this.timeData.filter(p => p.parentId === product.productId);
        const subassemblyData: TimingDataSubassembly[] = [];

        for (const child of childData) {
            subassemblyData.push({
                productId: child.productId,
                days: child.totalDays,
                startAtHour: 0,
                endAtHour: child.totalDays * 24,
            });
        }

        return subassemblyData;
    }

    private getWorkflowData = async (product: Product, quantity: number, phaseStartHour: number = 0): Promise<TimingDataWorkflow[]> => {
        const steps = (product.workflow?.workflowSteps ?? []);
        const workflowData: TimingDataWorkflow[] = [];

        // Start/end times are determined by step order, so the array must be in order
        steps.sort((a, b) => a.stepOrder - b.stepOrder);

        let prevSegments: TimingDataWorkflowSegment[] = null;

        let currentStepIndex = steps.length > 0 ? 0 : -1;

        while (currentStepIndex >= 0 && currentStepIndex < steps.length) {
            let perQtyHours = 0;

            const step = steps[currentStepIndex];
            const station = this.getStation(step);

            const segments: TimingDataWorkflowSegment[] = [{
                qtyProcessed: quantity,
                setupHours: 0,
                runHours: 0,
                startAtHour: 0,
                endAtHour: 0,
                tag: "STANDARD",
            }];

            // Outsource steps don't need any extra processing, just get the time
            if (station.isOutsourceStep && step.selectedQuote) {
                segments[0].runHours = ((await this.getQuoteCallback(step.stationId, step.selectedQuote))?.leadTimeDays ?? 0) * 24;
            }

            // Standard labor step
            else if (!station.isOutsourceStep) {
                // Values for the whole step
                segments[0].setupHours = step.hasSetup ? (step.setupTime ?? 0) : 0;
                segments[0].qtyProcessed = quantity;

                // Per-part steps
                if (step.runIsPerPart) {
                    // Times are in minutes, convert to hours
                    perQtyHours = (step.runTime ?? 0) / 60;
                    perQtyHours += (step.hasSetup && step.perPieceSetupTime) ? (step.perPieceSetupTime / 60) : 0;

                    // That amount of time for each part
                    segments[0].runHours = perQtyHours * segments[0].qtyProcessed;
                }

                // Single run for all parts
                else {
                    // Times are in hours already
                    segments[0].runHours = step.runTime ?? 0;
                }

                segments[0].runHours += (step.firstPartInspectionTime ?? 0) / 60;
            }

            // We now have the setup hours, run hours, per quantity time, and quantity processed for all steps
            // Next, use this data to position the timing in relation to the rest of the data

            if (step.isAdministrative) {
                segments[0].endAtHour = phaseStartHour;
                segments[0].startAtHour = segments[0].endAtHour - segments[0].runHours - segments[0].setupHours;
            }
            else {
                if (prevSegments && prevSegments.length > 0)
                    segments[0].startAtHour = prevSegments[prevSegments.length-1].endAtHour;
                else
                    segments[0].startAtHour = phaseStartHour;

                segments[0].startAtHour += step.startOffsetHours;
                segments[0].endAtHour = segments[0].startAtHour + segments[0].setupHours + segments[0].runHours;
            }

            // In outsource steps, add a warning before the actual step
            if (station.isOutsourceStep) {
                segments[1] = segments[0];
                segments[0] = {
                    tag: "WARNING",
                    startAtHour: segments[1].startAtHour - 168,
                    endAtHour: segments[1].startAtHour,
                    qtyProcessed: 0,
                    setupHours: 0,
                    runHours: 0,
                }
            }

            // Add the segment, or the whole step
            const totalSetup = segments.reduce((sum, seg) => sum + seg.setupHours, 0);
            const totalRuntime = segments.reduce((sum, seg) => sum + seg.runHours, 0);
            const existingData = workflowData.find(d => d.stepId === step.workflowStepId);

            if (existingData) {
                existingData.segments = existingData.segments.concat(segments);
                existingData.setupHours += totalSetup;
                existingData.runHours += totalRuntime;
            } else {
                workflowData.push({
                    stepId: step.workflowStepId,
                    order: step.stepOrder,
                    isOutsourced: station.isOutsourceStep,
                    setupHours: totalSetup,
                    runHours: totalRuntime,
                    perQtyHours,
                    segments,
                });
            }

            // Increment the next step
            currentStepIndex += 1;

            // Don't change the segments if it's administrative; those should not move the timeline
            if (!step.isAdministrative)
                prevSegments = segments;
        }

        return workflowData;
    }

    private getOutsourcedDays = (data: TimingDataWorkflow[]) => {
        const outsourceHours = data
            .filter(step => step.isOutsourced)
            .reduce(
                (sum, step) => sum + step.runHours,
                0
            );

        // Likely going to be a multiple of 24, but don't add a day if it's slightly over
        return Math.round(outsourceHours / 24);
    }

    private getLaborDays = (data: TimingDataWorkflow[]) => {
        let laborHours = 0;

        if (data && data.length > 0) {
            const finalStepData = data[data.length-1];
            const finalSegment = finalStepData.segments[finalStepData.segments.length-1];
            const workflowHours = finalSegment.endAtHour - data[0].segments[0].startAtHour;
            laborHours = workflowHours - (24 * this.getOutsourcedDays(data));
        }

        // Minimum number of shifts to complete
        return Math.ceil(laborHours / 8);
    }

    private setPrerequisiteTimelines = (product: Product, materialData: TimingDataMaterial, purchasedItemData: TimingDataPurchasedItem[], subassemblyData: TimingDataSubassembly[], workflowData: TimingDataWorkflow[]) => {
        if (
            product.workflow === null ||
            product.workflow === undefined ||
            product.workflow.workflowSteps === null ||
            product.workflow.workflowSteps === undefined ||
            product.workflow.workflowSteps.length === 0
        ) {
            // If there's no workflow just keep the defaults from 0
            return;
        }

        const steps = product.workflow.workflowSteps;
        steps.sort((a, b) => a.stepOrder - b.stepOrder);

        const defaultFirstStep = steps.find(s => s.isAssembly) || steps[0];
        const defaultData = workflowData.find(d => d.stepId === defaultFirstStep.workflowStepId);

        // Set the purchased items
        for (const item of purchasedItemData) {
            let matchedData = defaultData;

            // Override default data with WSII, if any are present
            for (const step of steps) {
                for (const wsii of step.workflowStepInventoryItems ?? []) {
                    if (wsii.inventoryItem.purchasedItemId == item.itemId) {
                        matchedData = workflowData.find(d => d.stepId === step.workflowStepId);
                        break;
                    }
                }

                if (matchedData !== defaultData)
                    break;
            }

            // Set the timeline according to this step
            // Actual item time
            item.segments[1].endAtHour = matchedData.segments[0].startAtHour;
            item.segments[1].startAtHour = item.segments[1].endAtHour - (item.days * 24);
            // Warning time
            item.segments[0].endAtHour = item.segments[1].startAtHour;
            item.segments[0].startAtHour = item.segments[0].endAtHour - 168; //= 24 * 7
        }

        // Set the subassemblies. No overrides, all go to the default
        for (const child of subassemblyData) {
            child.endAtHour = defaultData.segments[0].startAtHour;
            child.startAtHour = child.endAtHour - (child.days * 24);
        }

        // Find the earliest start time of any step
        const earliestStart = Math.min(
            purchasedItemData.reduce((min, data) => Math.min(min, data.segments[0].startAtHour), 0),
            subassemblyData.reduce((min, data) => Math.min(min, data.startAtHour), 0)
        );

        // If it's negative, offset every step by that amount
        if (earliestStart < 0) {
            const offset = Math.abs(earliestStart);

            for (const segment of materialData.segments) {
                segment.startAtHour += offset;
                segment.endAtHour += offset;
            }

            for (const data of purchasedItemData) {
                for (const segment of data.segments) {
                    segment.startAtHour += offset;
                    segment.endAtHour += offset;
                }
            }

            for (const data of subassemblyData) {
                data.startAtHour += offset;
                data.endAtHour += offset;
            }

            for (const data of workflowData) {
                for (const segment of data.segments) {
                    segment.startAtHour += offset;
                    segment.endAtHour += offset;
                }
            }

        }
    }

    /*
     * Internal utility functions
     */

      private getStation = (step: WorkflowStep): Station => {
        if (!this.stationService.stationList || !step?.stationId)
            return null;
      
          return this.stationService.stationList.find(r => r.stationId == step.stationId);
      }

      private getTotalDays = (components: TimingDataComponents, workflowData: TimingDataWorkflow[]) => {
        const finalHour = workflowData.reduce(
            (maxStep, dataStep) => Math.max(maxStep, dataStep.segments.reduce(
                (maxSegment, dataSegment) => Math.max(maxSegment, dataSegment.endAtHour), 0
            )), 0
        )

        return Math.ceil((finalHour / 24) + components.bufferDays);
      }

}