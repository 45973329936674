<mat-table #dataTable [dataSource]="workOrders" class="w-100 h-100-t mt-2 border-top" style="overflow-y: scroll" matSort>
    <mat-row *matRowDef="let row; columns: displayedColumns; " class="oor-row"></mat-row>

    <ng-container matColumnDef="woNumber">
      <mat-cell *matCellDef="let row" style="flex-grow: 0; flex-basis: 15%; padding-left: 16px;" class="wo-number">
        <b>{{row.workOrderNumber}}</b>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="dueDate">
      <mat-header-cell *matHeaderCellDef style="flex-grow: 0; flex-basis: 7.5%; padding-left: 1em;"> Due Date </mat-header-cell>
      <mat-cell *matCellDef="let row" style="flex-grow: 0; flex-basis: 7.5%; padding-left: 1em;">
        {{ row.dueDate | date: 'MM/dd/yyyy' }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="assignedUser">
      <mat-header-cell *matHeaderCellDef style="flex-grow: 0; flex-basis: 12.5%; padding-left: 1em;"> Current User </mat-header-cell>
      <mat-cell *matCellDef="let row" style="flex-grow: 0; flex-basis: 12.5%; padding-left: 1em;">
        <div class="d-flex align-items-center" *ngLet="row.assignedUser_Name as userName">
          <mat-icon *ngIf="!!userName" class="text-muted" matPrefix svgIcon="account"></mat-icon>
          <mat-icon *ngIf="!userName" class="text-danger" matPrefix svgIcon="account-off"></mat-icon>
          <div class="ml-2 font-weight-medium" [class.text-danger]="!userName" [class.text-muted]="!!userName">{{userName ?? 'Unassigned'}}</div>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="padding">
        <mat-cell *matCellDef class="padding-cell">&nbsp;</mat-cell>
    </ng-container>
    <!--  -->
    <ng-container matColumnDef="preplanning">
      <mat-cell *matCellDef="let row" class="oor-checkbox-cell preplanning" [ngClass]="getCheckboxColorClass(row, 'preplanning')">
        <div class="oor-checkbox" matRipple matRippleColor="rgba(255,255,255,0.15)" *ngLet="getProgressValues(row, 'preplanning') as progress">
          <mat-icon class="text-white" svgIcon="dots-horizontal" *ngIf="progress.blocked"></mat-icon>
          <mat-checkbox class="mat-checkbox-readonly" [checked]="progress.finished" *ngIf="!progress.blocked"></mat-checkbox>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="purchasing">
      <mat-cell *matCellDef="let row" class="oor-checkbox-cell purchasing" [ngClass]="getCheckboxColorClass(row, 'purchasing')">
        <div class="oor-checkbox" matRipple matRippleColor="rgba(255,255,255,0.15)" *ngLet="getProgressValues(row, 'purchasing') as progress">
          <mat-icon class="text-white" svgIcon="dots-horizontal" *ngIf="progress.blocked"></mat-icon>
          <mat-checkbox class="mat-checkbox-readonly" [checked]="progress.finished" *ngIf="!progress.blocked"></mat-checkbox>
        </div>
      </mat-cell>
    </ng-container>
>
    <ng-container matColumnDef="planning">
      <mat-cell *matCellDef="let row" class="oor-checkbox-cell preplanning" [ngClass]="getCheckboxColorClass(row, 'planning')">
        <div class="oor-checkbox" matRipple matRippleColor="rgba(255,255,255,0.15)" *ngLet="getProgressValues(row, 'planning') as progress">
          <mat-icon class="text-white" svgIcon="dots-horizontal" *ngIf="progress.blocked"></mat-icon>
          <mat-checkbox class="mat-checkbox-readonly" [checked]="progress.finished" *ngIf="!progress.blocked"></mat-checkbox>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="production">
      <mat-cell *matCellDef="let row" class="oor-checkbox-cell preplanning" [ngClass]="getCheckboxColorClass(row, 'production')">
        <div class="oor-checkbox" matRipple matRippleColor="rgba(255,255,255,0.15)" *ngLet="getProgressValues(row, 'production') as progress"> <mat-icon class="text-white" svgIcon="dots-horizontal" *ngIf="progress.blocked"></mat-icon>
          <mat-checkbox class="mat-checkbox-readonly" [checked]="progress.finished" *ngIf="!progress.blocked"></mat-checkbox>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="inspection">
      <mat-header-cell *matHeaderCellDef class="oor-checkbox-header" matTooltipPosition="above"
        [matTooltip]="'Inspection'"> IN </mat-header-cell>
      <mat-cell *matCellDef="let row" class="oor-checkbox-cell preplanning" [ngClass]="getCheckboxColorClass(row, 'inspection')">
        <div class="oor-checkbox" matRipple matRippleColor="rgba(255,255,255,0.15)" *ngLet="getProgressValues(row, 'inspection') as progress"> <mat-icon class="text-white" svgIcon="dots-horizontal" *ngIf="progress.blocked"></mat-icon>
          <mat-checkbox class="mat-checkbox-readonly" [checked]="progress.finished" *ngIf="!progress.blocked"></mat-checkbox>
        </div>
      </mat-cell>
    </ng-container>
  </mat-table>