import { Injectable, Inject } from '@angular/core';
import { httpService } from '../../common/services/http.service';
import { ErrorHandlerService } from '../../common/services/errorHandler.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MaterialBid, MaterialBidDocument, BulkBid } from '../resources/materialBid';
import { catchError, tap, concatMap } from 'rxjs/operators';
import { MessageService } from '../../common/services/message.service';
import { MessageType } from '../../common/resources/message';
import { UtilityService } from '../../common/services/utility.service';
import { VirtualDocument } from '../../common/resources/virtual-document';
import { CounterService } from '../../common/services/counter.service';
import { SearchResult } from '../../common/resources/search-result';
import { Order } from '../../order/resources/order';

@Injectable({
  providedIn: 'root',
})
export class MaterialBidService extends httpService {
  private apiBase: string = 'api/MaterialBid';
  private apiUrl: string;

  constructor(errorHandler: ErrorHandlerService, private messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private counterService: CounterService) {
    super(errorHandler, messages);
    this.serviceName = "Material Bid";

    this.apiUrl = this.baseUrl + this.apiBase;
  }

  public comparePricing(materialId: string, stationId: string, purchasedItemId: string): Observable<any[]> {
    return this.http.get<MaterialBid>(this.apiUrl + `/comparePricing?materialId=${materialId || ''}&stationId=${stationId || ''}&purchasedItemId=${purchasedItemId || ''}`).pipe(
      catchError(this.handleError<any>("Get Material Price History", null))
    );
  }

  public search(orderOrReqId: string, filterId: string): Observable<SearchResult<MaterialBid>> {
    return this.http.get<SearchResult<MaterialBid>>(this.apiUrl + `/filteredSearch/${orderOrReqId}/${filterId}`, { params: { searchText: null, pageIndex: '0', orderBy: "answered", direction: "desc" } }).pipe(
      catchError(this.handleError<any>("Get Material Bid Search Results", null))
    );
  }

  public searchItem(filterId: string, partNumber: string | null = null,
    searchText: string = null, orderBy: string = null,
    direction: string = null, pageIndex: number = null, pageSize: number = null,
  ): Observable<SearchResult<MaterialBid>> {
    const params: Record<string, string> = { searchText: searchText ?? '', pageIndex: pageIndex?.toString() ?? '0', orderBy: orderBy ?? "answered", direction: direction ?? "desc", pageSize: pageSize?.toString() ?? '1000' };
    if (partNumber) params.partNumber = partNumber;
    return this.http.get<SearchResult<MaterialBid>>(this.apiUrl + `/filteredSearch/${filterId}`, { params }).pipe(
      catchError(this.handleError<any>("Get Material Bid Search Results", null))
    );
  }

  public getQuoteSummary(data: {
    orderIds: string[],
    reqIds: string[],
  }): Observable<any[]> {
    return this.http.post<any[]>(this.apiUrl + '/getQuoteSummary', data).pipe(
      tap(_ => this.messages.add("Material Bid Service: Get Quote Summary", MessageType.SUCCESS, true)),
      catchError(this.handleError<any[]>("Get Quote Summary", null))
    );
  }

  public get(quoteId: string): Observable<MaterialBid> {
    return this.http.get<MaterialBid>(this.apiUrl + '/' + quoteId).pipe(
      catchError(this.handleError<any>("Get Material Bid Detail", null))
    );
  }

  public save(quote: MaterialBid): Observable<MaterialBid> {
    //Clean up - we don't want to insert these again, so clear out the objects and leave the ID's
    quote.material = null;
    quote.station = null;
    quote.purchasedItem = null;
    quote.vendorId = quote.vendorId || quote.vendor.vendorId;
    quote.vendor = null;
    quote.contact = null;
    quote.workOrder = null;

    if (quote.materialBidId == UtilityService.emptyGuid) {
      //New Item
      return this.http.post<MaterialBid>(this.apiUrl + '/new', quote).pipe(
        tap(_ => this.messages.add("Material Bid Service: Quote Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<MaterialBid>("Save New Quote", quote))
      );
    }
    else {
      //Existing Item
      return this.http.post<MaterialBid>(this.apiUrl, quote).pipe(
        tap(_ => this.messages.add("Material Bid Service: Quote Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<MaterialBid>("Update Quote", quote))
      );
    }
  }

  public async saveAllBids(bids: MaterialBid[]) {
    for (var bid of bids) {
      await this.save(bid).toPromise();
    }
  }

  public saveBulkBid(bid: BulkBid): Observable<BulkBid> {
    //Clean up - we don't want to insert these again, so clear out the objects and leave the ID's
    bid = JSON.parse(JSON.stringify(bid));
    bid.vendor = null;
    bid.bids = [];

    if (bid.bulkBidId == UtilityService.emptyGuid) {

      //Needs a number first...
      return this.counterService.next("ABQ").pipe(
        concatMap(nextPrefix => {
          bid.bidNumber = nextPrefix;

          //NOW return the new order
          return this.http.post<BulkBid>(this.baseUrl + 'api/BulkBid/new', bid).pipe(
            tap(_ => this.messages.add("Material Bid Service: Order Saved Successfully", MessageType.SUCCESS, true)),
            catchError(this.handleError<BulkBid>("Save New Bulk Bid", bid))
          );
        })
      );
    }
    else {
      //Existing Item - we can just do a simple update here
      return this.http.post<BulkBid>(this.baseUrl + 'api/BulkBid/', bid).pipe(
        tap(_ => this.messages.add("Material Bid Service: Bulk Bid Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<BulkBid>("Update Order", bid))
      );
    }
  }

  public getBulkBid(bulkBidId: string): Observable<BulkBid> {
    return this.http.get<BulkBid>(this.baseUrl + 'api/BulkBid/' + bulkBidId).pipe(
      tap(_ => this.messages.add("Material Bid Service: Get Bulk Bid Detail", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Get Bulk Bid Detail", null))
    );
  }

  public addBulkBidDocuments(bid: BulkBid, documents: VirtualDocument[]): Observable<MaterialBidDocument[]> {
    return this.http.post<MaterialBidDocument[]>(this.baseUrl + 'api/Purchasing/AddDocumentsToBulkBid/' + bid.bulkBidId, documents.map(d => d.documentId)).pipe(
      tap(_ => this.messages.add("Material Bid Service: Documents Updated", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Add Documents to Bulk Quote", null))
    );
  }

  public accept(quote: MaterialBid): Observable<any> {
    return this.http.get<MaterialBid>(this.apiUrl + '/approve/' + quote.materialBidId).pipe(
      catchError(this.handleError<any>("Accept Bid", null))
    );
  }

  public reject(quote: MaterialBid): Observable<any> {
    return this.http.get<MaterialBid>(this.apiUrl + '/reject/' + quote.materialBidId).pipe(
      catchError(this.handleError<any>("Reject Bid", null))
    );
  }

  public addDocuments(quote: MaterialBid, documents: VirtualDocument[]): Observable<MaterialBidDocument[]> {
    return this.http.post<MaterialBidDocument[]>(this.apiUrl + '/adddocuments?materialBidId=' + quote.materialBidId, documents.map(d => d.documentId)).pipe(
      tap(_ => this.messages.add("Material Bid Service: Documents Updated", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Add Documents to Quote", null))
    );
  }

  public removeDocument(order: MaterialBid, document: VirtualDocument): Observable<any> {
    return this.http.get<MaterialBid[]>(this.apiUrl + '/removedocument?materialBidId=' + order.materialBidId + '&documentId=' + document.documentId).pipe(
      tap(_ => this.messages.add("Material Bid Service: Document Removed Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Remove Document from Quote", null))
    );
  }

  public removeBulkQuoteDocument(bid: BulkBid, document: VirtualDocument): Observable<any> {
    return this.http.get<MaterialBid[]>(this.baseUrl + 'api/Purchasing/removebulkbiddocument?bulkBidId=' + bid.bulkBidId + '&documentId=' + document.documentId).pipe(
      tap(_ => this.messages.add("Material Bid Service: Document Removed Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Remove Document from Bulk Quote", null))
    );
  }

  public delete(materialBid: MaterialBid): Observable<any> {
    return this.http.delete<MaterialBid>(this.apiUrl + '/' + materialBid.materialBidId).pipe(
      tap(_ => this.messages.add("Material Bid Service: Quote Removed Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<MaterialBid>("Remove Quote", materialBid))
    );
  }

  public getAllQuotesForItem(filterId: string): Observable<MaterialBid[]> {
    return this.http.get<MaterialBid[]>(this.apiUrl + '/getAllQuotesForItem/' + filterId).pipe(
      catchError(this.handleError<any>("Get All Quotes For Item", null))
    );
  }

  public getAllQuotesForOrder(order: Order): Observable<{ [key: string]: MaterialBid[] }> {
    return this.http.get<MaterialBid[]>(this.apiUrl + '/getAllQuotesForOrder/' + order.orderSegmentId).pipe(
      catchError(this.handleError<any>("Get All Quotes For Order", null))
    );
  }

  public newQuoteForRfqRequestDetail(
      purchasingRfqRequestId: string,
      vendorId: string,
      purchasedItemId: string,
      stationId: string,
      materialId: string,
      dueDate?: Date,
    ): Observable<MaterialBid> {
    return this.http.post<MaterialBidDocument[]>(this.apiUrl + '/new', <MaterialBid>{
      materialBidId: UtilityService.emptyGuid,
      asked: new Date(),
      purchasingRfqRequestId,
      vendorId,
      purchasedItemId,
      stationId,
      materialId,
      requiredBy: dueDate,
      
    }).pipe(
      tap(_ => this.messages.add("Material Bid Service: Documents Updated", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Add Documents to Quote", null))
    );
  }


}
