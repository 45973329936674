<loading *ngIf="loading" content="Loading..."></loading>

<mat-sidenav-container class="step-items-container">
  <mat-sidenav #sidenav  mode="over" position="end" style="width: 50%">
    <product-workflow-station-list (stationDblClick)="finishAddingStep($event)"></product-workflow-station-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="row h-100-t">
  <div class="col-4 h-100-t p-0">
    <div class="h-100-t d-flex flex-column border-right">
      <div class="py-3 px-4 d-flex align-items-center">
        <h4 class="m-0">
          Workflow
        </h4>
        <button mat-stroked-button class="ml-auto" (click)="startAddingStep()" *ngIf="editing && !reordering">
          <mat-icon matPrefix>add</mat-icon>
          Add Step
        </button>
        <button mat-stroked-button class="ml-2" (click)="this.startSorting()" *ngIf="editing && !reordering && steps.length > 0">
          <mat-icon matPrefix>swap_vert</mat-icon>
          Reorder
        </button>
        <button mat-stroked-button class="icon-stroked-button ml-auto" *ngIf="reordering" (click)="cancelSorting()">
          <mat-icon class="text-danger">close</mat-icon>
        </button>
        <button mat-stroked-button class="icon-stroked-button ml-2" *ngIf="reordering" (click)="commitSort()">
          <mat-icon class="text-success">save</mat-icon>
        </button>
      </div>
      <div [matMenuTriggerFor]="contextMenu" #menuTrigger="matMenuTrigger" style="position: fixed;" [style]="{
    left: menuPosition.x + 'px',
    top: menuPosition.y + 'px'
  }">
  </div>
  <mat-menu #contextMenu>
    <button mat-menu-item *ngIf="menuItem && editing" (click)="deleteStep(menuItem)">
      <mat-icon class="text-danger">delete</mat-icon>
      Delete <b>{{getStation(menuStep)?.name}}</b>
    </button>
  </mat-menu>
      <mat-selection-list #selectionList class="border-top pt-0 flex-grow-1" style="height: 0; overflow-y: scroll;"
        (selectionChange)="selectionList.deselectAll(); $event.source.selectedOptions.select($event.option)" cdkDropList (cdkDropListDropped)="doSort($event)">
        <mat-list-option disabled class="text-muted font-italic text-center bg-white" *ngIf="steps.length === 0">Workflow has no steps.</mat-list-option>
        <ng-container *ngFor="let step of steps">
          <mat-list-option #option class="border-bottom w-100"
            (click)="reordering && selectionList.deselectAll(); reordering || selectStep(step)"
            (contextMenu)="openMenu($event, step)"
            matRipple
            [matRippleDisabled]="reordering"
            [class.item-unclickable]="reordering"
            cdkDrag [cdkDragDisabled]="!reordering"
            >
            <div class="d-flex align-items-center w-100">
              <mat-icon class="text-muted drag-handle mr-2" cdkDragHandle [class.d-none]="!reordering">
                drag_handle
              </mat-icon>
              <mat-icon svgIcon="format-list-group" class="text-muted flex-shrink-0" [class.invisible]="!step.isStandalone"></mat-icon>
              <mat-icon svgIcon="cog" class="text-muted flex-shrink-0" [class.invisible]="step.runIsPerPart"></mat-icon>
              <mat-icon svgIcon="logout-variant" class="text-muted flex-shrink-0" [class.invisible]="!getStation(step).isOutsourceStep"></mat-icon>
              <div class="d-flex align-items-center w-100 ml-1" [class.font-weight-bold]="selectedStep?.workflowStepId === step.workflowStepId">
                {{ getStation(step)?.name }}
              </div>
              <div class="ml-auto rounded text-white" [ngClass]="{
                'bg-danger': step.planningStatus == 0,
                'bg-warning': step.planningStatus == 1,
                'bg-success': step.planningStatus == 2
              }"
                style="padding: 1px;
                display: flex;
                justify-content: center;
                align-items: center;
                user-select: none;
                "
              >
                <mat-icon>
                  {{ getStatusIcon(step.planningStatus) }}
                </mat-icon>
              </div>
            </div>
            <div class="selected-border" [class.invisible]="!option.selected || reordering">&nbsp;</div>
            <div *cdkDragPreview>
              <div style="width: 360px; height: 48px; cursor: move; position: relative; left: -25px; top: -25px;" class="bg-white rounded px-3 py-2 mat-elevation-z4 d-flex align-items-center">
                {{ getStation(step)?.name }}
              </div>
            </div>
          </mat-list-option>
        </ng-container>
        <!-- <ng-container *ngIf="repairPlan">
          <mat-list-option dense *ngFor="let step of repairPlan.workflow.workflowSteps">
            {{ getStation(step).name }}
          </mat-list-option>
        </ng-container> -->
      </mat-selection-list>
    </div>
  </div>
  <div class="col-8 d-flex flex-column">
    <div class="py-3 d-flex align-items-center">
      <h4 class="m-0">{{getStation(selectedStep)?.name}}</h4>
      <button mat-stroked-button color="warn" class="ml-2 button-text" (click)="deleteStep(selectedStep)" *ngIf="editing && selectedStep">
        <mat-icon matPrefix>delete</mat-icon>
        Delete
      </button>
      <mat-form-field appearance="outline" class="ml-auto mat-input-no-message dense-field" style="min-width: 250px; margin: -12px 0px;" *ngIf="selectedStep">
        <mat-label>Step Status</mat-label>
        <mat-select [(ngModel)]="selectedStep.planningStatus" [ngModelOptions]="{ standalone: true }" (ngModelChange)="onStatusChange($event)" [disabled]="!editing">
          <mat-select-trigger>
            <div style="pointer-events: none; padding: 2px 8px; height: 32px;" class="rounded" class="ml-auto rounded text-white d-flex align-items-center" [ngClass]="{
              'bg-danger': selectedStep.planningStatus == 0,
              'bg-warning': selectedStep.planningStatus == 1,
              'bg-success': selectedStep.planningStatus == 2
            }">
              <mat-icon class="mr-1">{{ getStatusIcon(selectedStep.planningStatus) }}</mat-icon>
              <span class="button-text small">
                {{ getStepStatusText(selectedStep.planningStatus) }}
              </span>
            </div>
          </mat-select-trigger>
          <mat-option *ngFor="let num of [0,1,2]" [value]="num">
            <div style="pointer-events: none; padding: 2px 8px; height: 32px;" class="rounded" class="ml-auto rounded text-white d-flex align-items-center" [ngClass]="{
              'bg-danger': num == 0,
              'bg-warning': num == 1,
              'bg-success': num == 2
            }">
              <mat-icon class="mr-1">{{ getStatusIcon(num) }}</mat-icon>
              <span class="button-text small">
                {{ getStepStatusText(num) }}
              </span>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-tab-group class="step-tabs flex-grow-1" style="height: 0;" *ngIf="selectedStep">
      <mat-tab label="Step Planning">
        <div class="p-3">
          <product-workflow-step-form
          [editing]="editing"
          (fieldChange)="onStepFieldChange(selectedStep.workflowStepId, $event)"
          (newOPSpec)="onNewOPSpec($event)"
          (addQuote)="onOpQuote($event)"
          (quoteOpened)="this.detailService.openSidenav('quoteDetail', $event)"
          (quoteHistoryOpened)="this.detailService.openSidenav('quoteHistory', $event)"
          [record]="workOrder.order" [product]="product" [step]="selectedStep" class="h-100-t" #stepFormComponent
          ></product-workflow-step-form>
        </div>
      </mat-tab>
      <mat-tab label="Assignment">
        <div class="p-3">
          <h5 class="d-inline">Assigned to Department:</h5>
          <span class="pl-2">
            <department-dropdown [editable]="editing" [selectedDepartment]="getAssignment(selectedStep)?.department"
              (selectedDepartmentChange)="saveAssignment(selectedStep, $event)"></department-dropdown>
          </span>
        </div>
      </mat-tab>
      <mat-tab label="Tooling">
        <div class="row mx-0 h-100" *ngIf="selectedStep">
          <div class="col-12">
            <h4 class="my-2 input-output-header d-flex align-items-center">
              <mat-icon class="mr-1">checklist</mat-icon>
              Inspection Tooling
              <button *ngIf="editing" class="ml-auto add-item-button" color="primary" mat-flat-button
                (click)="addItem('INSPECTION_TOOLING')">
                <mat-icon matPrefix>add</mat-icon>
                Add Tooling
              </button>
            </h4>
            <mat-list class="border-top pt-0" style="margin: 0 -15px">
              <mat-list-item *ngIf="stepInspectionToolingItems.length === 0"
                class="bg-light text-muted font-italic border-bottom text-center">
                No items
              </mat-list-item>
              <ng-container *ngFor="let item of stepInspectionToolingItems">
                <ng-container *ngTemplateOutlet="stepItemTemplate; context: {item: item}"></ng-container>
              </ng-container>
            </mat-list>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>


  </mat-sidenav-content>
</mat-sidenav-container>
<ng-template #stepItemTemplate let-item="item">
  <mat-list-item class="border-bottom">
    <div class="d-flex align-items-center w-100">
      <item-type-chip [override]="item.type === 2 ? 'TOOLING' : null" [iconOnly]="true" [item]="item.inventoryItem"
        class="mr-2"></item-type-chip>
      <div class="flex-grow-1 overflow-hidden small">
        <div mat-line [matTooltip]="item.inventoryItem.name" matTooltipPosition="before"
          matTooltipClass="item-name-tooltip" style="white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          ">{{ item.inventoryItem.name }}</div>
        <div mat-line class="small"><b>{{ item.quantity }}x</b> {{ item.type === 3 ? 'total' : 'per operation' }}</div>
      </div>
      <button *ngIf="editing" class="ml-auto" mat-icon-button color="warn"
        aria-label="Example icon button with a heart icon" (click)="deleteItem(item)">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </mat-list-item>
</ng-template>

<ng-template #newStepDialogTemplate let-data>
  <h3 mat-dialog-title>Add Item</h3>
  <mat-dialog-content>
    <div>
      <h6 *ngIf="data.direction !== 'INSPECTION_TOOLING'">What type of item would you like to add?</h6>
      <mat-button-toggle-group [(ngModel)]="data.type" (ngModelChange)="data.purchasedItem = null" *ngIf="data.direction !== 'INSPECTION_TOOLING'">
        <mat-button-toggle value="PURCHASED">Purchased Item</mat-button-toggle>
        <mat-button-toggle value="MATERIAL">Raw Material</mat-button-toggle>
        <mat-button-toggle value="PRODUCT">Part</mat-button-toggle>
        <mat-button-toggle value="TOOLING">Tooling</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <h4 class="text-primary">Choose Item</h4>
    <mat-select *ngIf="data.type === 'PURCHASED'" [(ngModel)]="data.purchasedItem">
      <mat-option *ngFor="let pi of product.purchasedItems" [value]="pi.purchasedItem">{{ pi.purchasedItem.description
        }}</mat-option>
      <mat-option disabled *ngIf="product.purchasedItems.length === 0">No purchased items for product.</mat-option>
    </mat-select>
    <mat-select *ngIf="data.type === 'MATERIAL'" [(ngModel)]="data.material">
      <mat-option *ngIf="product.material" [value]="product.material">{{ product.material.materialName }}</mat-option>
      <mat-option disabled *ngIf="!product.material">Product has no material.</mat-option>
    </mat-select>
    <mat-select *ngIf="data.type === 'PRODUCT'" [(ngModel)]="data.product">
      <mat-option disabled class="small text-muted font-italic text-center header-option">Main Product</mat-option>
      <mat-option [value]="product">{{ product.partNumber }} Rev. {{ product.revision }}</mat-option>
      <mat-option disabled class="small text-muted font-italic text-center header-option"
        *ngIf="this.childProducts && this.childProducts.length > 0">Subassemblies</mat-option>
      <mat-option *ngFor="let child of childProducts" [value]="child">{{ child.partNumber }} Rev. {{ child.revision }}</mat-option>
    </mat-select>
    <purchased-item-select *ngIf="data.type === 'TOOLING'" [(ngModel)]="data.purchasedItem"></purchased-item-select>
    <mat-form-field class="w-100 mt-2" appearance="outline">
      <mat-label>Quantity</mat-label>
      <input type="number" autocomplete="off" matInput [(ngModel)]="data.quantity" min="1" step="1">
      <div matSuffix class="unit-suffix" *ngIf="data.direction !== 'INSPECTION_TOOLING'">per operation</div>
      <div matSuffix class="unit-suffix" *ngIf="data.direction === 'INSPECTION_TOOLING'">total</div>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="null">Cancel</button>
    <button mat-button [mat-dialog-close]="data" [disabled]="
            (((data.type === 'PURCHASED' || data.type === 'TOOLING') && !data.purchasedItem ) || (data.type==='MATERIAL' && !data.material) || (data.type==='PRODUCT' && !data.product))
            ||
            (!isInteger(data.quantity) || data.quantity < 1)
    ">Add</button>
  </mat-dialog-actions>
</ng-template>